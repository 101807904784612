<template>
    <div>
        <Toast></Toast>
        <Card>
            <template #content>
                <form @submit.prevent="saveForm">
                    <div class="p-card p-3" style="backgroundcolor: var(--indigo-50)">
                        <div class="grid">
                            <div class="col-12 md:col-6 lg:col-4">
                                <h2 class="p-card-title"></h2>
                                <div class="p-fluid">
                                    <div class="p-card p-2">
                                        <div class="p-field pb-2">
                                            <h2 class="p-card-title">Schnellstart beim nächsten Start</h2>
                                            <SelectButton v-model="formData.fastStart" :options="options1"
                                                optionLabel="label" unselectable optionValue="value" aria-labelledby="basic"
                                                @change="handleSelectionFastStart" />
                                        </div>
                                    </div>
                                    <p></p>
                                    <div class="p-card p-2">
                                        <div class="p-field pb-2">
                                            <h2 class="p-card-title">Tips in den Bereichen</h2>
                                            <Button outlined v-model="formData.fastStart"
                                                label="Zurücksetzen und Alle Anzeigen" aria-labelledby="basic"
                                                @click="resetTipsList" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 lg:col-4">
                                <h2 class="p-card-title"></h2>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </Card>
    </div>
    <CsvImport :ep="'/company/items'" :dataSendKind="'row'" :preview=true :dbColumns="dbColumns"></CsvImport>
</template>
  
<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import CsvImport from "@/components/CsvImport.vue";
import * as utils from "../utils/calculationUtils";

const store = userStore();
const tipEnabledList = store.tipEnabledList;
const toast = useToast();
const dbColumns = ref([
  { key: 'itemName', label: 'Artikelname', required: true, allowDefaultValue: true },
  { key: 'itemAlias', label: 'Alias' },
  { key: 'showAlias', label: 'Verwende Alias', allowDefaultValue: true },
  { key: 'itemInPrice', label: 'Einkaufspreis' },
  { key: 'itemOutPrice', label: 'Verkaufspreis', required: true }
]);

// Formulardaten
let formData = reactive({
    fastStart: true,
    tips: true,
});

const options1 = [
    { label: 'Anzeigen', value: true },
    { label: 'Nicht Anzeigen', value: false }
];
const saveForm = async () => {
    try {
        await validationSchema.validate(formData, { abortEarly: false });
        store.sendToEp("/company/fullfill", formData, "patch");
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Einstellungen erfolgreich gespeichert",
            life: 3000,
        });
    } catch (error) {
        toast.add({
            severity: "error",
            summary: "Eingabefehler",
            detail: error.message,
            life: 3000,
        });
    }
};

async function werteLaden() {
    const faststart = "fastStartVisible";
    formData.fastStart = tipEnabledList?.tipsList?.hasOwnProperty(faststart) && tipEnabledList?.tipsList?.[faststart];
}

const handleSelectionFastStart = () => {
    if (!tipEnabledList.tipsList) {
        tipEnabledList.tipsList = {};
    }
    tipEnabledList.tipsList["fastStartVisible"] = formData.fastStart;
    store.setCompanyTips(tipEnabledList);
    let status=formData.fastStart?"nicht angezeigt":"angezeigt";
    toast.add({
            severity: "success",
            summary: "Schnellstart wird beim nächsten Start",
            detail: status,
            life: 3000,
        });
};

const resetTipsList = () => {
    const oldValueFastStart = tipEnabledList?.tipsList?.["fastStartVisible"];
    const tipsList = tipEnabledList.tipsList;
    for (const key in tipsList) {
        if (Object.hasOwnProperty.call(tipsList, key)) {
            tipsList[key] = true;
        }
    }
    tipEnabledList.tipsList["fastStartVisible"] = oldValueFastStart;
    toast.add({
            severity: "success",
            summary: "Zurücksetzen erfolgreich",
            detail: "Alle Tips werden wieder angezeigt",
            life: 3000,
        });
};

onMounted(() => {
    werteLaden();
});
</script>
  
<style scoped>
.p-card {
    text-align: left !important;
}

.p-card-title {
    font-size: large !important;
}

.form-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.form-column {
    display: flex;
    flex-direction: column;
}

.form-field {
    margin-bottom: 10px;
}

button {
    margin-top: 20px;
}
</style>
  