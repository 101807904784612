import * as yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';

export const PatientValidator = yup.object({
  firstName: yup.string().required('validation.isARequiredField'),
  surName: yup.string().required('validation.isARequiredField'),
  street: yup.string().required('validation.isARequiredField'),
  houseNumber: yup.string().required('validation.isARequiredField'),
  postCode: yup.string().min(5, 'validation.isTooShort').max(5, 'validation.IsTooLong').matches(/^\d+$/, 'validation.invalidCharacters').required('validation.isARequiredField'),
  city: yup.string().required('validation.isARequiredField'),
  birthDate: yup.string().required('validation.isARequiredField'),
  insuranceName: yup.string().when('insuranceKind', { is: 'qLegalInsurance', then: () => yup.string().min(3, 'validation.isTooShort').required('validation.isARequiredField'), }),
  insurancePolicyNumber: yup.string().when('insuranceKind', { is: 'qLegalInsurance', then: () => yup.string().matches(/^[A-Za-z]\d{9}$/, 'validation.invalidInput').required('validation.isARequiredField'), }),
  emailAddress: yup.string().required('validation.isARequiredField').email('validation.invalidInput').test("is-valid", "validation.invalidInput", (value) => (value ? isEmailValidator(value) : true)),
  /* confirmEmail: yup.string().required('validation.isARequiredField').trim().oneOf([yup.ref('emailAddress'), null], 'validation.notSame'), */

  careLevel: yup.string().required('validation.isARequiredField'),
  whereToSendBox: yup.string().required('validation.isARequiredField'),
  insuranceKind: yup.string().required('validation.isARequiredField'),
  careType: yup.string().required('validation.isARequiredField'),
  /*     qTerminateContract: yup.string().required('validation.isARequiredField'),
   */
  packstationPostNumber: yup.string().when('whereToSendBox', { is: 'qDeliverPackstation', then: () => yup.string().min(6, 'validation.isTooShort').max(10, 'validation.IsTooLong').matches(/^\d+$/, 'validation.invalidCharacters').required('validation.isARequiredField') }),
  packstationNumber: yup.string().when('whereToSendBox', { is: 'qDeliverPackstation', then: () => yup.string().min(3, 'validation.isTooShort').max(3, 'validation.isTooLong').matches(/^\d+$/, 'validation.invalidCharacters').required('validation.isARequiredField') }),
  packstationPostCode: yup.string().when('whereToSendBox', { is: 'qDeliverPackstation', then: () => yup.string().min(5, 'validation.isTooShort').max(5, 'validation.IsTooLong').matches(/^\d+$/, 'validation.invalidCharacters').required('validation.isARequiredField') }),
  packstationCity: yup.string().when('whereToSendBox', { is: 'qDeliverPackstation', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverFirstName: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverSurName: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverStreet: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverHouseNumber: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverPostCode: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverCity: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
  caregiverPhone: yup.string().when('careType', { is: 'qCarePerson', then: () => yup.string().required('validation.isARequiredField') }),
/*     caregiverEmailAddress: yup.string().when('careType', {is: 'qCarePerson',then: () =>yup.string().required('validation.isARequiredField').email('validation.invalidInput').test("is-valid","validation.invalidInput",(value) => (value ? isEmailValidator(value) : true))}),
 */    toTerminateCompanyName: yup.string().when('qTerminateContract', { is: 'qTerminateYes', then: () => yup.string().required('validation.isARequiredField') }),
  toTerminateStreet: yup.string().when('qTerminateContract', { is: 'qTerminateYes', then: () => yup.string().required('validation.isARequiredField') }),
  toTerminateHouseNumber: yup.string().when('qTerminateContract', { is: 'qTerminateYes', then: () => yup.string().required('validation.isARequiredField') }),
  toTerminatePostCode: yup.string().when('qTerminateContract', { is: 'qTerminateYes', then: () => yup.string().required('validation.isARequiredField') }),
  toTerminateCity: yup.string().when('qTerminateContract', { is: 'qTerminateYes', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServiceCompany: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServiceStreet: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServiceHouseNumber: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServicePostCode: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServiceCity: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  nursingServicePhone: yup.string().when('careType', { is: 'qNursingService', then: () => yup.string().required('validation.isARequiredField') }),
  patbox_setup: yup.object().shape({
    boxtype: yup.string().required('validation.noCareBoxSelected'),
  }),
});
