<template>
    <div class="overlay">
        <div class="flex justify-content-center">
            <div class="spinner-container">
                <ProgressSpinner
                    style="width: 240px; height: 240px"
                    strokeWidth="15"
                    animationDuration="1s"
                    aria-label="Daten werden geladen"
                    strokeColor="#ffffff"
                    
                />
                <img src="@/assets/sanitybox2_icon.svg" alt="loading"  class="spinner-logo" />
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .spinner-container {
        position: relative;
    }

    .spinner-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px; /* Anpassen der Breite und Höhe des Logos nach Bedarf */
        height: 250px;
    }
</style>
