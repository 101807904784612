import { userStore } from "@/store/userStore";
import { reactive } from "vue";

export const PatientenService = {
  async getData() {
    const store = userStore();
    const pat = await store.sendToEp("/company/patients");
    console.log("🚀 ~ file: PatientenService.js:7 ~ getData ~ pat:", pat)
    
    const data = await reactive(pat.data.patients);
    return data;
  },

  getPatientensSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  async getPatientensXLarge() {
    return Promise.resolve(this.getData());
  },

};
