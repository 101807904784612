import { ref } from "vue";

export const steuersatzList = ref([
  { optionLabel: "7%", optionValue: 0.07 },
  { optionLabel: "19%", optionValue: 0.19 },
]);
// Utility zum nutzen mit
// import * as calcUtils from "@/utils/calculationUtils.js";
// <Dropdown v-model="selectedItem.steuersatz" optionLabel="optionLabel" optionValue="optionValue" :options="calcUtils.steuersatzList.value" class="w-min" />

/** Datumsfunktion für die umwandlung nach dd-mm-yyyy */
export const formatDate = (date) => {
  var year = date.getFullYear().toString();
  var month = (date.getMonth() + 101).toString().substring(1);
  var day = (date.getDate() + 100).toString().substring(1);
  return year + "-" + month + "-" + day;
};


export const parcelServices = ref([
  { name: 'DHL', code: 'dhl' },
  { name: 'Hermes', code: 'hermes' },
  { name: 'UPS', code: 'ups' },
  { name: 'GLS', code: 'gls' },
  { name: 'Deutsche Post', code: 'deutschePost' },
  { name: 'FedEx', code: 'fedex' },
  { name: 'DPD', code: 'dpd' },
  { name: 'Amazon Logistics', code: 'amazonLogistics' },
  { name: 'TNT', code: 'tnt' },
  { name: 'GO!', code: 'go' },
  { name: 'iloxx', code: 'iloox' },
  { name: 'Trans-o-flex', code: 'transoFlex' },
  { name: 'UPS Express Saver', code: 'upsExpressSaver' },
  { name: 'Der Kurier', code: 'derKurier' },
  { name: 'Night Star Express', code: 'nightStarExpress' },
  { name: 'Cargo International', code: 'cargoIntl' },
  { name: 'Eurodis', code: 'eurodis' },
  { name: 'General Logistics Systems (GLS)', code: 'generalLS' },
  { name: 'Parcel One', code: 'parcelOne' },
  { name: 'Gebrüder Weiss', code: 'gebruederWeiss' },
  { name: "PinAG", code: 'pinag'}
]);
