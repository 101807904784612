<template>
    <div class="dashboard">
        <div class="p-component surface-ground px-4 py-5 md:px-6 lg:px-8">
            <div class="grid">
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-800 shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-400 font-medium mb-3">Umsatz</span>
                                <div class="text-900 text-white font-medium text-xl">{{ gesamtUmsatz.toFixed(2) }} €</div>
                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-chart-line text-blue-500 text-xl"></i>
                            </div>
                        </div>
                        <small class="text-400">Zeitraum: </small><small class="text-400 text-white">{{ zeitraum }}</small>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">Offene Rechnungen</span>
                                <div class="text-900 font-medium text-xl">{{ sumOfOpenInvoices.toFixed(2) }} €</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-euro text-blue-800 text-xl"></i>
                            </div>
                        </div>
                        <small class="text-500">{{ openInvoices }} Rechnungen</small>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">Bezahlte Rechnungen</span>
                                <div class="text-900 font-medium text-xl">{{ sumOfPaidInvoices.toFixed(2) }} €</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-euro text-green-800 text-xl"></i>
                            </div>
                        </div>
                        <small class="text-500">{{ paidInvoices }} Rechnungen</small>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-card shadow-2 p-3 border-round" style="background-color: #2d323b !important;">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-400 font-medium mb-3">Patienten</span>
                                <div class="text-white font-medium text-xl">{{ allRegisteredPatients
                                }}</div>

                            </div>
                            <div class="flex align-items-center justify-content-center border-round"
                                style="width:2.5rem;height:2.5rem">

                                <i class="pi pi-inbox text-blue-500 text-xl"></i>
                            </div>
                        </div>
                        <div>
                            <small class="text-400">davon </small>
                            <small class="text-red-400 font-medium">{{ unqualifiedPatients }}</small>
                            <small class="text-400"> unqualifizierte</small>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">Noch nicht Verschickt</span>
                                <div class="text-900 font-medium text-xl">{{ allPackagesToSend }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-truck text-blue-800 text-xl"></i>
                            </div>
                        </div>
                        <small class="text-500">Sendungen noch zu verschicken.</small>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <div class="surface-card shadow-2 p-3 border-round">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">Verschickte Sendungen</span>
                                <div class="text-900 font-medium text-xl">{{ allSentPackages }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-truck text-green-800 text-xl"></i>
                            </div>
                        </div>
                        <small class="text-500">Sendungen die bereits verschickt wurden.</small>
                    </div>
                </div>
            </div>
            <p></p>
            <div class="p-component surface-ground flex justify-content-end">
                <Calendar size="small" v-model="selectedDateRange" :maxDate="today" selectionMode="range" showIcon />
                <Button size="small" class="buttonChangeDateRange" @click="setThisWeek">Diese Woche</Button>
                <Button size="small" class="buttonChangeDateRange" @click="setLastWeek">Letzte Woche</Button>
                <Button size="small" class="buttonChangeDateRange" @click="setThisMonth">Dieser Monat</Button>
                <Button size="small" class="buttonChangeDateRange" @click="setLastMonth">Letzter Monat</Button>
            </div>

            <div class="charts">
                <Card class="chart">
                    <template #title>
                        <div class="flex" style="margin-bottom:50px!important">
                            <div class=""><span>Dieser Monat(nicht ganz fertig)</span></div>
                        </div>
                    </template>
                    <template #content>
                        <Chart type="line" :options="chartOptionsDays" :data="chartDataDays"></Chart>
                    </template>
                </Card>


                <Card class="chart">
                    <template #title>
                        <div class="flex" style="margin-bottom:50px!important">
                            <div class=""><span>Letzte {{ monate }} Monate</span></div>
                        </div>
                    </template>
                    <template #content>
                        <Chart type="bar" :options="chartOptionsMonthValues" :data="chartDataMonthValues"></Chart>
                    </template>
                </Card>

                <Card class="chart">
                    <template #title style="margin-bottom:0!important">
                        <div class="flex">
                            <div class=""><span>Top 5 Artikel</span></div>
                            <div class="flex-grow-1"></div>
                            <div class="flex-none" style="font-size:10px;font-weight: 400;">
                                <div v-for="(item) in legendItems" style="line-height: 0.9rem">
                                    <span class="flex-none px-2 py-0 "
                                        :style="{ 'height': '10px', 'width': '10px', 'border': '1px solid ' + item.borderColor, 'background-color': item.backgroundColor }">
                                    </span>
                                    <span style="font-size: 10px !important;">
                                        <span style="margin-left: 2px">{{ item.label }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <Chart type="bar" :options="chartOptions" :data="topSellingItemsData"></Chart>
                    </template>
                </Card>

                <Card class="chart">
                    <template #title style="margin-bottom:0!important">
                        <div class="flex">
                            <div class=""><span>Top 5 Produktgruppen</span></div>
                            <div class="flex-grow-1"></div>
                            <div class="flex-none" style="font-size:10px;font-weight: 400;">
                                <div v-for="(item) in legendItemsTopBoxes" style="line-height: 0.9rem">
                                    <span class="flex-none px-2 py-0 "
                                        :style="{ 'border': '1px solid ' + item.borderColor, 'background-color': item.backgroundColor }">
                                    </span>
                                    <span style="font-size: 10px !important;">
                                        <span style="margin-left: 2px">{{ item.label }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <Chart type="bar" :options="chartOptions" :data="topSellingCategoriesData"></Chart>
                    </template>
                </Card>
            </div>

        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, watch } from 'vue';

import Chart from 'primevue/chart';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';

import { userStore } from "@/store/userStore";
import iAxios from "@/store/axiosInterface";
import * as utils from "../utils/calculationUtils";
import { ShippingService } from '@/service/ShippingService';

const store = userStore();

const today = new Date();
let selectedDateRange = ref([today, today]);


const chartOptions = ref({});
const totalRevenueData = ref({});
const customerActivityData = ref({});
const topSellingItemsData = ref({});

const topSellingCategoriesData = ref({});
const orderCombinationsData = ref({});
const pieChartData = ref({});

const allRegisteredPatients = ref({});
const gesamtUmsatz = ref(0);

const openInvoices = ref(0);
const paidInvoices = ref(0);
const sumOfPaidInvoices = ref(0);
const sumOfOpenInvoices = ref(0);

const numberOfOrderedBoxes = ref({});
const numberOfOrderedItems = ref({});
const qualifiedPatients = ref({});
const unqualifiedPatients = ref({});
const allPackagesToSend = ref({});
const allSentPackages = ref({});
const legendItems = ref([]);
const legendItemsTopBoxes = ref([]);
const zeitraum = ref("Gesamter Zeitraum");
const zeitraumButtonUsed = ref(false);

const monate = ref(6);
const chartOptionsMonthValues = ref();
const chartDataMonthValues = ref();

const chartDataDays = ref();
const chartOptionsDays = ref();

const op = ref();
const toggle = (event) => {
    op.value.toggle(event);
}

const createChartDataObject = () => {
    return {
        labels: [],
        datasets: [
            {
                label: 'Sales',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 1
            }
        ]
    }
}

const getBackgroundColors = () => {
    return ['rgba(255, 159, 64, 0.2)', 'rgba(255, 59, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)']
}

const getBorderColors = () => {
    return ['rgb(255, 159, 64)', 'rgb(255, 59, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'];
}

onMounted(async () => {
    store.headerTitel = "DASHBOARD"
    console.log("Test");
    try {
        let response = await iAxios.get("/company/dash-stats");
        showData(response.data);
        const shippings = await ShippingService.getFilterAndGroupShippingsByTimePeriod();
        allPackagesToSend.value = shippings.filter((o) => o.deliverStatus == "BOX_IN PROGRESS").length;
        allSentPackages.value = shippings.filter((o) => o.deliverStatus == "BOX_SENT").length;


        const FromTo = getLastMonths(monate.value);
        const from = (new Date(FromTo.from)).getMonth();
        const to = (new Date(FromTo.to)).getMonth();
        const data = await prepareData(from, to);
        const labels = getMonthsBetween(from, to);
        chartOptionsMonthValues.value = setChartOptionsMonthValues();
        chartDataMonthValues.value = setChartDataMonthValues(data.monthsDatas, labels);

        chartDataDays.value = setChartDataDays(data.dayDatas);
        chartOptionsDays.value = setChartOptionsDayValues();

    } catch (err) { };
});

const showData = (data) => {
    allRegisteredPatients.value = data.allRegisteredPatients ? data.allRegisteredPatients : 0;
    gesamtUmsatz.value = data.gesamtUmsatz ? data.gesamtUmsatz : 0;
    numberOfOrderedBoxes.value = data.numberOfOrderedBoxes ? data.numberOfOrderedBoxes : 0
    numberOfOrderedItems.value = data.numberOfOrderedItems ? data.numberOfOrderedItems : 0;
    qualifiedPatients.value = data.qualifiedPatients ? data.qualifiedPatients : 0;
    unqualifiedPatients.value = data.unqualifiedPatients ? data.unqualifiedPatients : 0;
    openInvoices.value = data.openInvoices ? data.openInvoices : 0;
    paidInvoices.value = data.paidInvoices ? data.paidInvoices : 0;
    sumOfPaidInvoices.value = data.sumOfPaidInvoices ? data.sumOfPaidInvoices : 0;
    sumOfOpenInvoices.value = data.sumOfOpenInvoices ? data.sumOfOpenInvoices : 0;
    let backgroundColors = getBackgroundColors();
    let borderColors = getBorderColors();

    let chartDataBoxes = createChartDataObject();

    legendItemsTopBoxes.value = [];
    for (const [key, value] of Object.entries(numberOfOrderedBoxes.value)) {
        chartDataBoxes.datasets[0].data.push(value);
        chartDataBoxes.labels.push(key);

        let backgroundColor = backgroundColors.pop();
        chartDataBoxes.datasets[0].backgroundColor.push(backgroundColor);

        let borderColor = borderColors.pop();
        chartDataBoxes.datasets[0].borderColor.push(borderColor);
        legendItemsTopBoxes.value.push({ label: key, backgroundColor: backgroundColor, borderColor: borderColor });
    }

    topSellingCategoriesData.value = chartDataBoxes;

    backgroundColors = getBackgroundColors();
    borderColors = getBorderColors();

    let chartDataItems = createChartDataObject();

    // sortieren
    const sortedData = Object.entries(numberOfOrderedItems.value).sort((a, b) => b[1] - a[1]);

    // die 5 besten nehmen
    const top5Data = sortedData.slice(0, 5);

    legendItems.value = [];
    for (const [key, value] of top5Data) {
        chartDataItems.datasets[0].data.push(value);
        chartDataItems.labels.push(key);
        let backgroundColor = backgroundColors.pop();
        chartDataItems.datasets[0].backgroundColor.push(backgroundColor);
        let borderColor = borderColors.pop();
        chartDataItems.datasets[0].borderColor.push(borderColor);
        legendItems.value.push({ label: key, backgroundColor: backgroundColor, borderColor: borderColor });
    }

    topSellingItemsData.value = chartDataItems;
};

watch([selectedDateRange], async () => {
    if (selectedDateRange.value[1] == null) {
        return;
    }

    let from = new Date(selectedDateRange.value[0]);
    let to = new Date(selectedDateRange.value[1]);
    to.setDate(to.getDate() + 1);

    let body = {
        "from": utils.formatDate(from),
        "to": utils.formatDate(to)
    }

    if (!zeitraumButtonUsed.value) {
        zeitraum.value = 'von ' + body.from + ' bis ' + body.to;
    }
    zeitraumButtonUsed.value = false;

    try {
        let response = await iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/dash-stats", body);
        showData(response.data);
    } catch (exception) {
        console.log(exception);
    }
});

const setThisWeek = async () => {
    zeitraum.value = "Diese Woche";
    const todayDayOfWeek = today.getDay();
    const startDate = new Date(today.getTime() - 7 * 86400000);
    const endDate = new Date(today.getTime());
    selectedDateRange.value = [startDate, endDate];
    zeitraumButtonUsed.value = true;
};

const setLastWeek = async () => {
    zeitraum.value = "Letzte Woche";
    const todayDayOfWeek = today.getDay();
    const startDate = new Date(today.getTime() - (todayDayOfWeek + 7) * 86400000);
    const endDate = new Date(startDate.getTime() + 6 * 86400000);
    selectedDateRange.value = [startDate, endDate];
    zeitraumButtonUsed.value = true;
}

const setThisMonth = async () => {
    zeitraum.value = "Dieser Monat";
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    selectedDateRange.value = [startDate, endDate];
    zeitraumButtonUsed.value = true;
};

const setLastMonth = async () => {
    zeitraum.value = "Letzter Monat";

    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDay());
    selectedDateRange.value = [startDate, endDate];
    zeitraumButtonUsed.value = true;
};

/* letzte Monate */
const getLastMonths = (months, startdate) => {
    const startdatum = startdate ?? new Date();
    const letzteMonate = {};
    const endMonat = new Date(startdatum.getFullYear(), startdatum.getMonth(), 1); // Erster Tag des aktuellen Monats
    const startMonat = new Date(startdatum.getFullYear(), (startdatum.getMonth() - (months - 1)), 1);
    letzteMonate.from = utils.formatDate(startMonat);
    letzteMonate.to = utils.formatDate(endMonat);
    return {
        "from": letzteMonate.from,
        "to": letzteMonate.to
    }
};

const getMonthsBetween = (startMonth, endMonth) => {
    const monthNames = [
        "Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    if (startMonth >= 1 && startMonth <= 12 && endMonth >= 1 && endMonth <= 12) {
        const result = [];
        for (let i = startMonth; i <= endMonth; i++) {
            result.push(monthNames[i - 1]);
        }
        return result;
    } else {
        return "Ungültige Monatszahl(en)";
    }
};
let numberOfDataPoints=0;
const prepareData = async (from, to) => {
    const months = monate.value;
    const { data } = await getMonthData(months);
    const monthsDatas = { paid: [], NotPaid: [], values: [] }
    const dayDatas = { paid: [], NotPaid: [], values: [],labels:[] }
    const valueDatas = { monthsDatas, dayDatas }
    console.log("🚀 ~ file: Dashboard.vue:441 ~ prepareData ~ data:", data)
    for (let i = from; i <= to; i++) {
        const paid = (data.monthlyPaidMap[i]?.invoiceValueBrutto) ?? 0;
        const unPaid = (data.monthlyNotpaidMap[i]?.invoiceValueBrutto) ?? 0
        const values = paid + unPaid;
        monthsDatas.paid.push(paid);
        monthsDatas.NotPaid.push(unPaid);
        monthsDatas.values.push(values);
    }
    try {
        const dummyData = generateRandomData().data; //DEBUG
        const dayData = dummyData; //data;


        console.log("🚀 ~ file: Dashboard.vue:464 ~ prepareData ~ selectedDateRange:", selectedDateRange)
        const startDate = new Date('2023-11-01'); //selectedDateRange.value[0]; //Debug zu testzwecke
        const endDate = selectedDateRange.value[1];

        for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = currentDate.toISOString().split('T')[0];

            const paidValue = dayData.paidMap[formattedDate]?.invoiceValueBrutto || 0;
            const notPaidValue = dayData.notpaidMap[formattedDate]?.invoiceValueBrutto || 0;

            dayDatas.labels.push(utils.formatDate(currentDate));
            dayDatas.paid.push(paidValue);
            dayDatas.NotPaid.push(notPaidValue);
            dayDatas.values.push(parseFloat((paidValue + notPaidValue).toFixed(2)));
        }
        numberOfDataPoints=dayDatas.labels.length;
        console.log("🚀 ~ file: Dashboard.vue:477 ~ prepareData ~ dayDatas:", dayDatas)

        //TODO: dayDatas füllen, und zu setChartDataDays data zuweisen
    } catch (err) { console.log(err) };
    return valueDatas;
}

//TODO tagesfeine darstellen mit primevue->Line Styles 3 dataset, umsatz, paid, Notpaid

const getMonthData = async (months) => {
    try {
        return await store.sendToEp("/company/dash-stats", getLastMonths(months), "post");
    } catch (err) {
        console.log(err);
    }
}

const setChartDataMonthValues = (data, labels) => {
    return {
        labels: labels,
        datasets: [
            {
                label: 'Umsatz',
                backgroundColor: getBackgroundColors()[2],
                borderColor: getBorderColors()[2],
                data: data.values,
            },
            {
                label: 'Offene Rechnung',
                backgroundColor: getBackgroundColors()[1],
                borderColor: getBorderColors()[1],
                data: data.NotPaid,
            }
        ]
    };
};

const setChartOptionsMonthValues = () => {
    return {
        scales: {
            x: {
                display: false, // x-achse labels ausblenden
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
        }
    };
}

const setChartOptionsDayValues = () => {
    return {
        scales: {
            x: {
                ticks: {
                    callback: function (val, index, values) {
                        const totalLabels = values.length;

                        if (numberOfDataPoints <= totalLabels) {
                            // Wenn die Anzahl der Datenpunkte kleiner oder gleich der Anzahl der Labels ist, zeige alle Labels an
                            return this.getLabelForValue(val);
                        } else {
                            // Andernfalls, zeige jedes n-te Label basierend auf der Anzahl der Datenpunkte an
                            const interval = Math.ceil(numberOfDataPoints / totalLabels);
                            return index % interval === 0 ? this.getLabelForValue(val) : '';
                        }
                    },
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                bounds: 'ticks',
            },
        },
    };
};

    /* chart tage */
    const setChartDataDays = (data) => {
        console.log("🚀 ~ file: Dashboard.vue:543 ~ setChartDataDays ~ data:", data)
        return {
            labels: data.labels,
            responsive: true,
            datasets: [
                {
                    label: 'Bezahlt',
                    data: data.paid,
                    fill: false,
                    tension: 0.4,
                    borderDash: [5, 5],
                    borderColor: getBorderColors()[4]
                },
                {
                    label: 'Offen',
                    data: data.NotPaid,
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: getBorderColors()[0]
                },
                {
                    label: 'Umsatz',
                    data: data.values,
                    fill: true,
                    borderColor: getBorderColors()[2],
                    tension: 0.4,
                    backgroundColor: getBackgroundColors()[2]
                }
            ]
        };
    };

    chartOptions.value = {
        scales: {
            x: {
                display: false, // x-achse labels ausblenden
            },
        },
        plugins: {
            legend: {
                display: false, // legende ausblenden
            },
        },
    };

    function generateRandomData() {
        const data = {
            paidMap: {},
            notpaidMap: {}
        };

        const startDate = new Date('2023-01-01');
        const endDate = new Date('2023-12-31');

        const numberOfEntries = Math.floor(Math.random() * (600 - 300 + 1) + 9000);

        for (let i = 0; i < numberOfEntries; i++) {
            const randomDate = new Date(
                startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
            ).toISOString().split('T')[0];

            const randomInvoiceValueBrutto = parseFloat((Math.random() * 30 + 5).toFixed(2));
            const randomInvoiceValueNetto = parseFloat((randomInvoiceValueBrutto * 0.84).toFixed(2));

            const isPaid = Math.random() < 0.5;

            if (isPaid) {
                data.paidMap[randomDate] = {
                    invoiceValueBrutto: randomInvoiceValueBrutto,
                    invoiceValueNetto: randomInvoiceValueNetto
                };
            } else {
                data.notpaidMap[randomDate] = {
                    invoiceValueBrutto: randomInvoiceValueBrutto,
                    invoiceValueNetto: randomInvoiceValueNetto
                };
            }
        }

        return { data };
    }



</script>

<style scoped>
dd {
    display: list-item;
    list-style-type: "\2B1C";
    padding-inline-start: 1ch;
    border: 2px;
    margin-right: 5px;
    line-height: 10px;
    border-radius: 2px;
    background-color: aqua;
}

.chart {
    width: 40em;
    margin: 10px;
    padding: 10px;
}

.container {
    display: flex;
    flex-direction: row;
}

.calendar-container {
    flex: 1;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
}

.buttonChangeDateRange {
    margin-left: 5px;
}

.charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chart {
    flex-basis: 48%;
    /* Breite der Karten anpassen */
    margin-bottom: 20px;
}

.card-item {
    height: 100%;
    padding: 0rem !important;
}

:deep(.p-card .p-card-content) {
    padding: 0 !important;
}

.marker-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.marker {
    width: 20px;
    height: 20px;
    border: 2px;
    margin-right: 5px;
    line-height: 10px;
    border-radius: 2px;
    /* Rundungen anpassen */
}
</style>