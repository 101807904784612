<template>
  <div id="app" class="app">
    <div v-if="store.isAuthenticated && !store.firstLogin">
      <header class="header p-component">
        <div class="grid">
          <div class="col">
            <!-- Kopfzeileninhalt -->
            <!--       <img src="@/assets/sanitybox2_sh.png" alt="Logo" class="logo" />-->
            <!-- Weitere Kopfzeileninhalte -->
            <Button v-if="isMobile" icon="pi pi-bars" class="mobile-sidebar-toggle" @click="mobileMenu" text></Button>
            <img v-if="!isMobile && !isSidebarExpanded" src="@/assets/sanitybox2_icon.svg" alt="Sidebar Icon"
              class="sidebar-icon" />
            <img v-if="isSidebarExpanded" src="@/assets/logo_t.png" alt="Sidebar Icon" class="sidebar-icon" />
            <img v-if="isMobile" src="@/assets/sanitybox2_sh.png" alt="Icon" class="logo" />
          </div>
          <div class="col flex card">
            <div class="flex-grow-1 align-items-center justify-content-center">
            <h2 style="color:#819aa0;font-weight: 400;font-size: auto;" v-if="!isMobile"><span v-html="store.headerTitel"></span></h2>
            <h5 style="color:#819aa0;font-weight: 400;" v-else>{{ store.headerTitel }}</h5>
          </div>
            <div class="flex-none  justify-content-end">
        <Button icon="pi pi-ellipsis-v" text rounded aria-label="help" size="small" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
        <Menu ref="menu" id="overlay_menu" :model="popUpItems" :popup="true" />
    </div>
          </div>
        </div>
      </header>
      <div class="main-container">
        <div v-show="!isMobile" class="sidebar-container">
          <div class="sidebar" :class="{ 'sidebar-expanded': isSidebarExpanded }" @mouseenter="expandSidebar"
            @mouseleave="collapseSidebar">
            <!-- Seitenleisteninhalt -->


            <PanelMenu v-model:expandedKeys="expandedKeys" :separator="false" :model="menuItems" 
              :style="{ width: isSidebarExpanded ? '200px' : '50px' }" :multiple="false" @command="handleMenuCommand"
              class="menu-panel">

              <template #headericon="{ item }">
                <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                  style="padding-right: 10px;margin-left: -5px;font-size: 24px"></span>
                <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                <span v-if="item.iconKind == 'google'" style="padding-right: 10px"
                  class="material-symbols-outlined">{{ item.customIcon }}</span> </template>
              <template #itemicon="{ item }">
                <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                  style="padding-right: 10px;margin-left: -5px;font-size: 24px"></span>
                <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                <span v-if="item.iconKind == 'google'" style="padding-right: 10px"
                  class="material-symbols-outlined">{{ item.customIcon }}</span> </template>
              <!--                 <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                    style="padding-right: 10px;margin-left: -10px;font-size:'30px'"></span> -->

            </PanelMenu>
          </div>
        </div>

        <div v-if="isMobile">
          <!--           <div @click="toggleSidebar">
 -->
          <Sidebar v-model:visible="MenuVisible" :showCloseIcon="false" autoZIndex blockScroll :modal="false" :pt="{
            root: {
              style: { top: '62px' },
              class: 'w-25rem'
            }
          }">
            <div>
              <PanelMenu v-model:expandedKeys="expandedKeys" :separator="false" :model="menuItems" s :multiple="false"
                class="w-full top:21rem md:w-25rem">

                <template #headericon="{ item }">
                  <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                    style="padding-right: 10px;margin-left: -5px;font-size: 24px"></span>
                  <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 10px"
                    class="material-symbols-outlined">{{ item.customIcon }}</span>
                </template>
                <template #itemicon="{ item }">
                  <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                    style="padding-right: 10px;margin-left: -5px;font-size: 24px"></span>
                  <span v-if="item.iconKind == 'primevue'" :class="item.icon" style="padding-right: 10px"></span>
                  <span v-if="item.iconKind == 'google'" style="padding-right: 10px"
                    class="material-symbols-outlined">{{ item.customIcon }}</span> </template>
                <!--                 <span v-if="item.iconKind == 'tabler'" :class="item.customClass"
                    style="padding-right: 10px;margin-left: -10px;font-size:'30px'"></span> -->
                    
              </PanelMenu>
            </div>
          </Sidebar>
          <!--           </div>
 -->
        </div>
      </div>
    </div>
    <div v-if="store.isAuthenticated && !store.firstLogin" class="content"
      :style="{ 'margin-left': isMobile ? '0' : '70px' }">

      <router-view></router-view>
    </div>
    <div v-else class="content">
      <router-view></router-view>
    </div>
    <!-- Hauptinhalt der Anwendung -->

  </div>
</template>

<script setup>
import { ref, onMounted,watchEffect } from 'vue';
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();



const router = useRouter();
const store = userStore();
const tipEnabledList = store.tipEnabledList;

const isSidebarExpanded = ref(false);
const isMobile = ref(false);
const expandedKeys = ref([]);
const isSidebarCollapsed = ref(false);
const MenuVisible = ref(true);
const fastStartVisible=ref(true);

const menuItems = ref([
  {
    key: '0',
    label: 'Schnellstart',
    icon: 'pi pi-star',
    iconKind: 'primevue',
    url: "/faststart",
    visible: fastStartVisible,
    command: () => { MenuVisible.value = false; store.headerTitel = "SCHNELLSTART" },
  },
  {
    key: '1',
    label: 'Dashboard',
    icon: 'pi pi-home',
    iconKind: 'primevue',
    url: "/dashboard",
    command: () => { MenuVisible.value = false; store.headerTitel = "DASHBOARD" },
  },
  {
    key: '2',
    label: 'Katalog',
    icon: 'pi pi-box',
    iconKind: 'primevue',
    url: "/catalogView",
    command: () => { MenuVisible.value = false; store.headerTitel = "KATALOG" },
  },
  {
    key: '3',
    label: 'Patientenverwaltung',
    icon: 'pi pi-users',
    iconKind: 'primevue',
    url: "/patientenview",
    command: () => { MenuVisible.value = false; store.headerTitel = "PATIENTENVERWALTUNG" },
  },
  {
    key: '4',
    label: 'Rechnungsverwaltung',
    iconKind: 'primevue',
    icon: 'pi pi-book',
    //iconSize: 'font-size: 30px',
    customClass: 'ti ti-file-invoice',
    url: "/invoicesView",
    command: () =>  { MenuVisible.value = false; store.headerTitel = "RECHNUNGSVERWALTUNG" },
  },
  {
    key: '5',
    label: 'Versandabwicklung',
    icon: 'pi pi-truck',
    iconKind: 'primevue',
    url: "/shippingPrepareView",
    command: () => { MenuVisible.value = false; store.headerTitel = "VERSANDABWICKLUNG" },
  },
  {
    key: '6',
    label: 'Versand',
    icon: 'pi pi-qrcode',
    iconKind: 'primevue',
    url: "/qrscanner",
    command: () => { MenuVisible.value = false; store.headerTitel = "VERSAND & QR SCANNER" },
  },
  {
    key: '8',
    label: 'Einstellungen',
    //icon: 'pi pi-cog',
    icon: 'pi pi-cog',
    iconKind: 'primevue',
    items: [
      {
        key: '8_0',
        label: 'Profildaten',
        icon: 'pi pi-wrench',
        iconKind: 'primevue',
        url: 'CompanySettingsForm',
        command: () => { MenuVisible.value = false; store.headerTitel = "PROFILDATEN" },
      },
      {
        key: '8_1',
        label: 'Rechtstexte',
        url: 'legalTextsSettingsForm',
        iconKind: 'tabler',
        customClass: 'ti ti-section-sign',
        command: () => { MenuVisible.value = false; store.headerTitel = "RECHTSTEXTE" },
      },
      {
        key: '8_2',
        label: 'Erweitert',
        url: 'Settings',
        iconKind: 'primevue',
        icon: 'pi pi-sliders-h',
        command: () => { MenuVisible.value = false; store.headerTitel = "Einstellungen" },
      },
    ]
  },
  {
    key: '9',
    label: 'Abmelden',
    icon: 'pi pi-sign-out',
    iconKind: 'primevue',
    items: [
      {
        key: '9_0',
        label: 'Abmelden bestätigen',
        icon: 'pi pi-lock',
        iconKind: 'primevue',
        command: () => logout(),
      }
    ]
  }
]);

const menu = ref();
const popUpItems = ref([
    {
        items: [
            {
                label: 'Hilfe erhalten',
                icon: 'pi pi-question-circle',
            },
            {
                label: 'Handbuch',
                icon: 'pi pi-file',
                url: '/handbook',
                command: () => store.headerTitel = "Handbuch",

                }
        ]
    }
]);

const toggle = (event) => {
    menu.value.toggle(event);
};

const logout = () => {
  try {
    store.logout();
    MenuVisible.value = false;
    router.push("/");
  } catch (e) {
    console.log("\n\n logout erro", e);
  };
}

const mobileMenu = () => {
  MenuVisible.value = !MenuVisible.value;
  if (MenuVisible.value === true) {
    const mask = document.querySelector('.p-sidebar-mask');
    const overlayClass = 'p-component-overlay-enter';
    if (mask && mask.classList.contains(overlayClass)) {
      mask.classList.remove(overlayClass);
    }
  }
}

/* const toggleSidebar = () => {
  if (isMobile.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
  }
}; */

watchEffect(()=>{
  if (store.isAuthenticated==false && router.path!="/") {
    router.push('/');
  }
});

const expandSidebar = () => {
  isSidebarExpanded.value = true;
};

const collapseSidebar = () => {
  isSidebarExpanded.value = false;
};

const handleMenuCommand = (event) => {
  // Aktion nach dem Betätigen eines Menüpunkts
  // Hier die Sidebar verkleinern
  collapseSidebar();
};

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf

  if (isMobile.value) {
    isSidebarExpanded.value = false; // Sidebar ausblenden auf mobilen Geräten
  }
};

const chkFastStartVisible = async ()  =>{
  await store.getCompanyTips;
  fastStartVisible.value=!tipEnabledList?.tipsList?.hasOwnProperty("fastStartVisible") || tipEnabledList?.tipsList?.["fastStartVisible"];
}


onMounted(async() => {
  await chkFastStartVisible();
  locale.value = "de";
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

</script>


<style>
body {
  margin: 0;
  background-color: #f2f4f6;
  /* Hintergrundfarbe des body */
}
</style>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  background-color: #ffffff;
  padding-left: 7px;
  z-index: 2;
  height: 60px;
  width: 100%;
  transition: width 0.2s;
}

.logo {
  top: 20px;
  height: 40px;
  width: auto;
  margin-top: 10px;
  padding-left: 10px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  top: 60px;

}

.sidebar {
  background-color: #fff;
  width: 70px;
  height: 100%;
  padding: 10px;
  transition: width 0.3s ease;
}

.sidebar-icon {
  height: 40px;
  width: auto;
  margin-bottom: 10px;
}

.sidebar-expanded {
  width: 220px;
}

.content {
  flex-grow: 1;
  padding: 10px;
  z-index: 1;
  margin-top: 60px;
}

:deep(.p-panelmenu .p-panelmenu-header) {
  width: 55 px;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
  background-color: #ffffff;
  background: #ffffff;
  border: 0;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action) {
  color: #000000;
  padding: 1.25rem;
  font-weight: 700;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .pi) {
  font-size: 24px;
}
:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .pi .material-symbols-outlined ) {
  font-size: 32px;
}



:deep(.p-panelmenu .p-submenu-icon) {
  display: none !important;
}

@media (min-width: 768px) {
  :deep(.p-menuitem-text) {
    display: none;
  }
}

:deep(.sidebar-expanded .p-menuitem-text) {
  display: inline;
  font-size: 12px;
  font-weight: 500;
}

:deep(.p-sidebar) {
  top: 62px;
  background: #944040 !important;
  background-color: #ad0707 !important;

}

.sidebar-expanded {
  width: 100%;
  /* Vollständige Breite der Sidebar */
}
</style>