<template>
    <div>
        <PatientenEditDialog v-model:visible="newPatient" :initialize="false" />
        <Card> 
            <template #content>
                <div>
                    <div v-if="isItemAvailible">
                        <Tip :target="'#noPatients'" :tipId="'noPatientsTip'" :placement="'left'" :overlay="false" />
                        <DataTable>
                            <Column headerStyle="width: 2rem; text-align: center">
                                <template #header>
                                    <Button id="noPatients" type="button" outlined text size="small" icon="pi pi-user-plus"
                                        rounded @click="newPatient = true" />
                                </template>
                            </Column>
                            <Column field="patient_pub_id" header="Patienten -Id" key="patient_pub_id"
                                :showFilterMenu="false" style="min-width: 150px">
                            </Column>
                            <Column v-if="!isMobile" class="" v-for="col of columns" :key="col.field" :field="col.field"
                                :header="col.header" :showFilterMenu="false" style="width: 25%">
                            </Column>
                            <Column field="statusAntrag" header="Status" hideFilterMenu :showFilterMenu="false"
                                :filterMenuStyle="{ width: '8%' }" style="min-width: 8%">
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div v-if="!isItemAvailible">
                    <Tip v-if="!isMobile" :tipId="'NoItemsTip'" :target="'#pv_id_1_2_header'" :placement="'right'" :overlay="true" :route="'/itemsEditView'"
                        :routeLabel="'Artikel anlegen'" :sideTitel="'Artikel'" :showCheckbox="false"/>
                    <Tip v-else :tipId="'NoItemsTip'" :overlay="true" :route="'/itemsEditView'"
                        :routeLabel="'Artikel anlegen'" :sideTitel="'Artikel'" :showCheckbox="false"/>
                    <!--                     <h3 style="color:red">- Bevor ein Patient angelegt werden kann, müssen Artikel vorhanden
                        sein. Aktuell sind jedoch keine Artikel verfügbar.</h3> -->
                    <!--                     <Button label="Neuen Artikel anlegen" @click="router.push('/itemsEditView')" />
 -->
                </div>
            </template>
        </Card>
    </div>
</template>
<script setup>
import PatientenEditDialog from "./PatientenEditDialog.vue";
import { ref, reactive, onMounted } from 'vue';
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";

const store = userStore();
const router = useRouter();
const newPatient = ref(false)
const isMobile = ref(false);

const companyItems = ref();
const isItemAvailible = ref(false);

const columns = ref([
  /*   { field: "patient_pub_id", header: "Patienten ID" },
   */ { field: "firstName", header: "Vorname" },
    { field: "surName", header: "Nachname" },
    { field: "postCode", header: "PLZ" },
    { field: "birthDate", header: "Geburtsdatum" },
    { field: "registered", header: "Registriert" },
]);
async function getItems() {
    try {
        const response = await store.sendToEp("/products/items-nonanual");
        companyItems.value = await response.data;
        const length = Object.keys(companyItems.value).length;
        isItemAvailible.value = (length > 0);
        return companyItems.value;
    } catch (e) {
        router.push('/');
    }
}
const checkMobile = () => {
    isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
    checkMobile();
    getItems();
});


</script>