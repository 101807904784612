<template>
    <div>
      <div class="my-1 text-center offset-2 col-12">
        <!--         <img src="@/assets/register.jpg" class="img-fluid" /> -->
      </div>
      <div class="alert alert-danger col-md-8 offset-2" v-if="error">
        {{ errorDisplayText }}
      </div>
      <Form @submit="submitData" :validation-schema="schema" v-slot="{ errors }">
        <!-- <div>{{ errors }}</div> -->
        <div class="form-row">
          <div class="form-group" :class="columnSizesForm">
            <label for="email"><strong>E-Mail Adresse</strong></label>
            <InputText
            v-model="form.email"
            type="email"
            class="form-control"
            id="email"
            name="email"
            />
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="form-group" :class="columnSizesForm">
            <div class="d-grid">
              <Button @click="submitData" class="btn bg-vue text-vue">
                <span v-if="!isLoading">Password zurücksetzen</span>
                <span v-else class="spinner-border spinner-border-sm"></span>
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </template>
  
  <script setup>
  import { ref,reactive } from "vue";
  import { Form, Field } from "vee-validate";
  import * as yup from "yup";
  import { userStore } from "@/store/userStore";
  
  const store=userStore();
  
  const form= reactive({
    email:"",
  })
  
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("E-Mail Adresse wird benötigt.")
      .trim()
      .email("Keine gültige E-Mail Adresse."),
  });
  
  const error = ref("");
  const isLoading = ref(false);
  
  const columnSizesForm =
    "col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2";
  
  function errorDisplayText() {
    if (error.value) {
/*       if (error.value.includes("EMAIL_EXISTS")) {
        return "E-Mail Adresse existiert bereits.";
      } */
      return "Ein unbekannter Fehler, versuche es nochmal.";
    }
    return "";
  }
  
  function submitData() {
    isLoading.value = true;
    error.value = "";
    store
      .resetPassword(form.email)
      .then(() => {
        isLoading.value = false;
    //schließen des fensters
    })
      .catch((error) => {
        error.value = error.message;
        isLoading.value = false;
      });
  }
  </script>
  
  <style scoped></style>
  