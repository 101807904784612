<template>
    <div class="card">
        <div class="accordion-container">
            <div class="left-column">
                <header>
                    <small style="color:green" for="chbx">Schnellstart beim nächsten Start, nicht mehr anzeigen.</small>
                    <Checkbox id="chbx"
                        v-model="chbxValue" inputId="chbxValue" :binary="true" @change="toogleFastStartShow" />
                    <h2 class="p-component"><strong>Willkommen bei Sanitybox</strong></h2>
                    <div style="margin-top:-20px"><small>In dieser Checkliste sind die wichtigsten Schritte den Start
                            enthalten.
                        </small></div>
                </header>
                <p></p>
                <h3 class="p-component">Einrichten des Firmenaccounts</h3>
                <Accordion class="accordion-custom">
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', firmenDatenFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Firmendaten eingeben</span>
                        </template>
                        <p>
                            Prüfe die Angaben zu deinem Unternehmen und ergänze diese falls nötig
                        </p>
                        <Button size=small label="Einstellungen"
                            @click="checkStep('firmenDatenFast'); $router.push('/CompanySettingsForm')" />
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', rechtsTexteFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Rechtstexte eingeben</span>
                        </template>
                        <p>
                            Prüfe die Rechtstexte wie z.B. AGB,Datenschutz Hinweise usw. zu deinem Unternehmen und ergänze
                            diese falls nötig
                        </p>
                        <Button size=small label="Rechtstexte"
                            @click="checkStep('rechtsTexteFast'); $router.push('/legalTextsSettingsForm')" />
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', pflegeArtikelFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Pflegeartikel anlegen</span>
                        </template>
                        <p>
                            Lege einen neuen Pflegeartikel im Katalog an.
                        </p>
                        <Button size=small label="Pflegeartikel anlegen"  @click="checkStep('pflegeArtikelFast'); $router.push('/catalogView')" />
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', pflegeHilfsMittelFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Pflegehilfmittel-Box anlegen</span>
                        </template>
                        <p>
                            Lege eine neue Box an und füge diesem die Pflegeartikel hinzu.
                        </p>
                        <Button size=small label="Pflegehilfmittel-Box anlegen"  @click="checkStep('pflegeHilfsMittelFast'); $router.push('/catalogView')" />
                    </AccordionTab>
                </Accordion>
                <h3 class="p-component pt-3">Mit Sanitybox arbeiten</h3>
                <Accordion class="accordion-custom">
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', patientFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Patient anlegen, ansehen bearbeiten</span>
                        </template>
                        <p>
                            Die Patienten werden normalerweise automatich angelegt. In diesem Bereich kannst du einen neuen
                            Patienten anlegen, vorhandene beabeiten, oder details ansehen.
                            In den Details kannst du einen das Kostenübernahme Formular an die Krankenversicherung senden und
                            herunterladen.
                        </p>
                        <Button size=small label="Patient anlegen, ansehen bearbeiten"
                        @click="checkStep('patientFast'); $router.push('/patientenview')" />
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', versandFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Versand vorbereiten</span>
                        </template>
                        <p>
                            Bereite den Versand vor. Hier siehst du die Sendungen die zu senden sind.
                            Du kannst hier eine Packliste drucken und die Sendungen auch manuell als versedet markieren.
                        </p>
                        <Button size=small label="Versand vorbereiten"  @click="checkStep('versandFast'); $router.push('/patientenview')" />
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i :class="['pi pr-2', versendenFast && 'pi-check-circle' || 'pi-circle']"></i>
                            <span>Pflegehilfmittel-Box versenden</span>
                        </template>
                        <p>
                            Scanne den QR-Code und markiere dadurch die versendeten Boxen als automatisch als versendet.
                        </p>
                        <Button size=small label="Versand vorbereiten"  @click="checkStep('versendenFast'); $router.push('/patientenview')" />
                    </AccordionTab>

                </Accordion>
            </div>
            <div class="right-column">
                <!-- Platz halter Rechte Spalte -->
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
const router = useRouter();


const store = userStore();
const chbxValue = ref();
const tipEnabledList = store.tipEnabledList;

const firmenDatenFast = ref(false);
const rechtsTexteFast = ref(false);
const pflegeArtikelFast = ref(false);
const pflegeHilfsMittelFast = ref(false);
const patientFast = ref(false);
const versandFast = ref(false);
const versendenFast = ref(false);

const toogleFastStartShow = () => {
    if (!tipEnabledList.tipsList) {
        tipEnabledList.tipsList = {};
    }
    tipEnabledList.tipsList["fastStartVisible"] = !chbxValue.value;
    store.setCompanyTips(tipEnabledList);
    // console.log("🚀 ~ file: FastStart.vue:124 ~ toogleFastStartShow ~ tipEnabledList:", tipEnabledList)
};

const showTip = (tipId) => {
      return (
       tipEnabledList?.tipsList?.[tipId]
    )
};

onBeforeMount(async () => {
    await store.getCompanyTips;
    if (showTip("fastStartVisible") == false) {
        router.push('/dashboard')
    }
    checkTips();
});

const checkTips = () => {
    firmenDatenFast.value = showTip("firmenDatenFast");
    rechtsTexteFast.value = showTip("rechtsTexteFast");
    pflegeArtikelFast.value = showTip("pflegeArtikelFast");
    pflegeHilfsMittelFast.value = showTip("pflegeHilfsMittelFast");
    patientFast.value = showTip("patientFast");
    versandFast.value = showTip("versandFast");
    versendenFast.value = showTip("versendenFast");
}

const checkStep = (tipStep) => {
    if (!tipEnabledList.tipsList) {
        tipEnabledList.tipsList = {};
    }
    tipEnabledList.tipsList[tipStep] = true;
    store.setCompanyTips(tipEnabledList);
    console.log(tipEnabledList);
    //router.push('/CompanySettingsForm')
    checkTips();
};

</script>
  
<style scoped>
/* Stil für die Container */
.accordion-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

/* Stil für die linke Spalte */
.left-column {
    flex: 1;
    padding-right: 2rem;
    /* Abstand zur rechten Spalte */
    padding-left: 3rem;
}

/* Stil für den Titel */
.left-column h2 {
    font-size: 1.5rem;
}

/* Stil für die rechte Spalte */
.right-column {
    flex: 1;
    display: flex;
    align-items: center;
}

/* Stil für den Hilfe-Link */
.right-column a {
    text-decoration: none;
    color: blue;
}

.pi-circle {
    color: lightcoral;
    font-size: 1.5rem;
}

.pi-check-circle {
    color: lightgreen;
    font-size: 1.5rem;
}
</style>
  