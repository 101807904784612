import { defineStore } from "pinia";
import iAxios from "./axiosInterface";
import { nextTick, reactive } from "vue";

export const userStore = defineStore("userStore", {
  state: () => ({
    headerTitel: "",
    userId: "",
    whoAmIData: {},
    loggedIN: false,
    expired: false,
    firstLogin: null,
    registersStayOpen: false,
    tipEnabledList:reactive({}),
    //expiresIn: "",
  }),


  getters: {
    getWhoAmIData(state) {
      return state.whoAmIData;
    },

    async whoAmI(state) {
      let url = process.env.VUE_APP_BASE_API_URL + "/whoami";
      try {
        const response = await iAxios.get(url);
        state.whoAmIData = await response.data;
        nextTick();
        state.userId = response.data.cid;
        state.firstLogin = response.data.firstLogin;
        return response.data;
      } catch (error) {
        state.userId = "";
      }
    },

    async getCompanyTips(state){
      try {
      let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
      const response = await iAxios.get(url);
      state.tipEnabledList=response.data?response.data:reactive({tipsList:{}});
      return state.tipEnabledList;
      }catch (e) {
      }
    },

    isAuthenticated(state) {
      try {
        this.whoAmI;
      } catch (error) {//console.log("error: " + error)
      };
      if (state.userId && state.userId != "") {
        state.loggedIN = true;
      } else {
        state.loggedIN = false;
      }
      return state.loggedIN;
    },
    isFirstLogin(state) {
      return state.firstLogin;
    },
  },
  actions: {
    // savepath="/.." 
    // responsetype='data' or default=root response
    // object=object to send (nullable) 
    // kind='patch','post' or 'get' default=get 
    async deleteFromEp(deletePath) {
      this.sendToEp(deletePath, null, "delete", null, null);
    },
    async sendToEp(savePath, object, saveKind, responseType, epKind) {

      let url = process.env.VUE_APP_BASE_API_URL + savePath;
      if (epKind == "patient") url = process.env.VUE_APP_BASE_API_URL_PATIENT + savePath;
      //let url=url1 + '?nocache=' + new Date().getTime(); // Safari fix
      try {
        if (object) {
          object.cid = this.userId;
        }

        let response = null;
        response =
        saveKind == 'post' ?
            await iAxios.post(url, object) :
            saveKind == 'patch' ?
              await iAxios.patch(url, object) :
              saveKind == 'delete' ?
                await iAxios.delete(url) :
                await iAxios.get(url, object);
                if (responseType == 'data') return Promise.resolve(response.data);
                else return response;
      } catch (error) {
        return error;
      }
    },

    async setCompanyTips(tipEnabledList) {
      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.post(url, tipEnabledList);
        const tips={};
        tips.tipsList=response.data;
        return tips;
      } catch (e) {
      }
    },

    async resetPassword(emailAddress4ResetPassword) {
      let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword;
      try {
        const response = await iAxios.get(url);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        if (response.status == 403) {
          return response;
        }
      }
    },
    async logout() {
      let url = process.env.VUE_APP_BASE_API_URL + "/logout";
      try {
        this.userId = "";
        await iAxios.get(url);
        //return response;
      } catch (error) {
        this.userId = "";
      }
    },
    async auth(payload) {
      let url = "";
      if (payload.mode === "signin") {
        url = process.env.VUE_APP_BASE_API_URL + "/login";
      } else if (payload.mode === "signup") {
        url = process.env.VUE_APP_BASE_API_URL + "/register";
      } else {
        return;
      }
      const authDO = {
        email: payload.email,
        password: payload.password,
      };
      try {
        const response = await iAxios.post(url, authDO);
        this.userId = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        this.whoAmIData = this.whoAmI;
        return response;
      } catch (error) {
        const errorMessage = new Error(
          error.response.data.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      }
    },
    async signup(payload) { //Register user
      const signupDO = {
        ...payload,
        mode: "signup",
      };
      try {
        const response = await this.auth(signupDO);
        this.userId = response.data.cid;
        return response;
      } catch (error) {
        //console.log("Error during signup: ", error);
        throw error;
      }
    },
    async signin(payload) { //Login
      const signinDO = {
        ...payload,
        mode: "signin",
      };
      try {
        const response = await this.auth(signinDO);
        this.userId = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        return response;
      } catch (error) {
        //console.log("Error during signin: ", error);//TODO debug
        throw error;
      }
    },
    setExpired(bool) {
      this.expired = bool;
    },
    setHeaderTitel(titelText) {
      this.headerTitel = titelText;
    }
  },
  persist: true,
});
