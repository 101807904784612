<template>
  <div class="card p-fluid">
    <Loading v-show="loading" />


    <Toast />
    <DataTable v-model:filters="filters" :value="virtualBoxes" editMode="row" scrollable scrollHeight="85vh"
      filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
      selectionMode="single" dataKey="patient_pub_id" sortField="product_id" :sortOrder="-1" class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick" :virtualScrollerOptions="{
        lazy: true,
        onLazyLoad: loadBoxesLazy,
        itemSize: 46,
        delay: 300,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100
      }">
      <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #header>
          <Button type="button" outlined text size="small" icon="pi pi-plus-circle" rounded @click="handleNewRow" />
        </template>
        <template #body="{ data }">
          <Button type="button" id="editBox" outlined text size="small" icon="pi pi-file-edit" aria-haspopup="true"
            aria-controls="overlay_menu" rounded @click="handleRowDoubleClick(data)" />
        </template>
      </Column>

      <Column class="" v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" style="width: {{ col.width }}" sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>

      <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" id="deleteBox" outlined text size="small" icon="pi pi-trash" aria-haspopup="true"
            aria-controls="overlay_menu" rounded @click="deleteRow(data)" severity="danger" />
        </template>
      </Column>
    </DataTable>

  </div>
  <Dialog v-model:visible="showDeleteDialog" :modal="true" :closable="false">
      <h3>{{ message.title }}</h3>
      <p>{{ message.description }}</p>
      <div class="dialog-footer">
        <Button label="Nein" @click="showDeleteDialog = false" />
        <Button label="Ja" class="p-button-danger" @click="forceDelete" />
      </div>
    </Dialog>

</template>

<script setup>
import { ref, onMounted, onBeforeMount, defineAsyncComponent, markRaw } from "vue";
import { FilterMatchMode } from "primevue/api";
import { BoxesService } from "@/service/BoxesService";
import Loading from "@/components/Loading";
import { useDialog } from "primevue/usedialog";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";

const toast = useToast();

const dialog = useDialog();

const isMobile = ref(false);
const loading = ref(true);
const lazyLoading = ref(false);
const loadLazyTimeout = ref();
const boxes = ref([]);
const virtualBoxes = ref([]);
const showDeleteDialog = ref(false);
const message = ref({});

const columns = ref([
  /*   { field: "patient_pub_id", header: "Patienten ID" }, */
  { field: "product_id", header: "Plegebox ID", width: "15%" },
  { field: "productName", header: "Pflegebox Name", width: "85%" },
]);

const filters = ref({
  product_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const BoxesInsertComponent = defineAsyncComponent(() => import('@/views/BoxesInsertComponent.vue'));
const BoxesEditFooter = defineAsyncComponent(() => import('@/views/BoxesEditFooter.vue'));

const handleRowDoubleClick = (event) => {
  const data = ref(event.data ? event.data : event);

  const originalData = JSON.parse(JSON.stringify(data.value));
  const dialogRef = dialog.open(BoxesInsertComponent, {
    props: {
      header: 'Box Bearbeiten',
      style: {
        width: '50vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(BoxesEditFooter)
    },
    onClose: async (options) => {
      console.log(dialogRef);
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          save(dialogRef, originalData);
        } else if (buttonType == "Cancel") {
          Object.assign(data.value, originalData);
        }
      }
    }
  });
  dialogRef.data = {
    box: data
  };
}

const save = async (dialogRef, originalData) => {
  let box = JSON.parse(JSON.stringify(dialogRef.data.box.value));

  let productItems = box.productItemList;
  delete box.productItemList;

  box.productItemList = [];
  productItems.forEach(item => {
    box.productItemList.push([item.item_id, item.quantity]);;
  });

  try {
    const url = process.env.VUE_APP_BASE_API_URL + "/products/boxes";
    let response;
    if (box.product_id) {
      response = await iAxios.patch(url, box);
      console.log(response);
    } else {
      response = await iAxios.post(url, box);
      boxes.value.push(response.data);
      virtualBoxes.value.push(response.data);
    }
  } catch (e) {
    toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Speichern der Daten" });
    if (originalData) {
      Object.assign(dialogRef.data.box.value, originalData);
    }
  }
}

const handleNewRow = () => {
  const newItem = { productName: "Neue Pflegebox", productActive: true, productItemList: [], productValue: parseFloat("1.5") };

  const dialogRef = dialog.open(BoxesInsertComponent, {
    props: {
      header: 'Neue Pflegebox Anlegen',
      style: {
        width: '50vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(BoxesEditFooter)
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          try {
            save(dialogRef, null);
          } catch (error) {
            console.error(error);
            toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Speichern der Daten" });
          }
        }
      }
    }
  });

  dialogRef.data = {
    box: ref(newItem)
  };
}

function isSortable(field) {
  return field === 'birthDate' || field === 'registered';
}

const loadBoxesLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuelleBoxen = [...boxes.value];
    let { first, last } = event;

    //load data of required page
    const loadedBoxes = boxes.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuelleBoxen, [...[first, last - first], ...loadedBoxes]);

    virtualBoxes.value = virtuelleBoxen;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
}

const deleteRow = (data) => {
  message.value.title = "Pflegebox Löschen?";
  message.value.description = "Möchten sie die Pflegebox wirklich löschen? Dieser vorgang kann nicht wieder rückgängig gemacht werden.";
  message.value.box = data;
  showDeleteDialog.value = true;
}

const forceDelete = async () => {
  showDeleteDialog.value = false;

  const data = message.value.box;
  try {
    let url = process.env.VUE_APP_BASE_API_URL + "/products/boxes/" + data.product_id;
    let response = await iAxios.delete(url);
    
    let index = boxes.value.indexOf(data);
    let virtualIndex = virtualBoxes.value.indexOf(data);

    boxes.value.splice(index, 1);
    virtualBoxes.value.splice(virtualIndex, 1);
    console.log(data);
  } catch (error) {
    console.error(error);
    toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Speichern der Daten" });
  }
}

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

async function werteLaden() {
  loading.value = true;
  try {
    await BoxesService.getBoxesXLarge().then((data) => {
      boxes.value = data;
    });
    loading.value = false;
  } catch (Exception) {
    console.log(Exception);
  } finally {
    loading.value = false;
  }
  loadBoxesLazy([0, 50]);
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
}

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
})

onBeforeMount(async () => {
  await werteLaden();
})

</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
