import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'

export const speichern = async (injectedValue) => {
    let rechnungsDaten = injectedValue.data.rechnungsDaten;
    
    let flatRechnung = JSON.parse(JSON.stringify(rechnungsDaten));
    flatRechnung.patbox_setup = {
        boxtype: "custom",
        selectedBox: {
            productName: "rechnungsbox",
            productItemList: []
        }
    }
    flatRechnung.productItemList.forEach(itemEntry => {
        let copyItemEntry = JSON.parse(JSON.stringify(itemEntry));

        if (!copyItemEntry.selectedItem) {
            copyItemEntry.selectedItem = {};
            copyItemEntry.selectedItem.itemName = copyItemEntry.itemName;
        }
        copyItemEntry.selectedItem.quantity = copyItemEntry.menge;
        copyItemEntry.selectedItem.steuersatz = copyItemEntry.steuersatz


        if (copyItemEntry.selectedItem.selectedVariant) {
            copyItemEntry.selectedItem.selectedVariant.variantOutPrice = copyItemEntry.preisNetto;

            copyItemEntry.selectedItem.selectedVariant = copyItemEntry.selectedItem.selectedVariant;
            copyItemEntry.selectedItem.selectedVariant.variantOutPrice = copyItemEntry.preisNetto;
        } else {
            copyItemEntry.selectedItem.itemOutPrice = copyItemEntry.preisNetto;
        }
        copyItemEntry = { ...copyItemEntry.selectedItem };

        flatRechnung.patbox_setup.selectedBox.productItemList.push(copyItemEntry);
    });
    flatRechnung.storno = injectedValue.data.invoice.storno;
    flatRechnung.docType = "INVOICE";
    delete flatRechnung.productItemList;
    let url = process.env.VUE_APP_BASE_API_URL + "/company/manual-document";
    let response = await iAxios.post(url, flatRechnung)
        .then((response) => {
            const content = response.headers['content-type'];
            download(response.data, "invoice_" + flatRechnung.leistungsdatum + ".pdf", content);
        })
        .catch((error) => {
            console.error(error);
        });

    return response;
}