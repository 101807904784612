<template>
  <div v-if="showTip">
    <div v-if="props.overlay && showOverlay" class="overlay"></div>
    <v-tour v-if="RdyToShow" name="Tour" :steps="steps" :options="tipOptions" :callbacks="Callbacks"
      @checkboxChanged="handleCheckboxChange" :debug=true>
    
    
    </v-tour>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, computed, } from 'vue';
import tips from './tips.js';
import router from '@/router';
import { userStore } from "@/store/userStore";

const internalInstance = getCurrentInstance();
const $tours = internalInstance.appContext.config.globalProperties.$tours;

const store = userStore();

const tipEnabledList = store.tipEnabledList;

const showTip = computed(() => {
  return (
    !tipEnabledList?.tipsList?.hasOwnProperty(props.tipId) || tipEnabledList?.tipsList?.[props.tipId]
  )
});

const RdyToShow = ref(false);
const showOverlay = ref(false);
const props = defineProps({
  overlay: {
    type: Boolean,
    required: false,
    default: true,
  },
  tipId: {
    type: String,
    required: true
  },
  target: {
    required: false,
    default: 'false'
  },
  placement: {
    type: String,
    required: false,
    default: 'top'
  },
  route: {
    type: String,
    required: false,
    default: '',
  },
  routeLabel: {
    type: String,
    required: false,
    default: 'OK',
  },
  sideTitel: {
    type: String,
    required: false,
    default: '',
  },
  timeout:{
    required: false,
    default: 0,
  },
  showCheckbox:{
    required: false,
    default: true,
  },
});

const tip = computed(() => {
  return tips.find((tip) => tip.tipId === props.tipId);
});

const tipOptions = ref({
  labels: {
    buttonSkip: 'Überspringen',
    buttonPrevious: 'Zurück',
    buttonNext: 'Weiter',
    buttonStop: props.routeLabel,
  },
  highlight: true,
  checkbox: props.showCheckbox,
  checkboxLabel: "Nicht erneut anzeigen",
});

function handleCheckboxChange(event) {
  console.log("checkbox changed");//DEBUG

  const tipId = props.tipId;
  if (!tipEnabledList.tipsList) {
    tipEnabledList.tipsList = {};
  }
  tipEnabledList.tipsList[tipId] = !event;
  store.setCompanyTips(tipEnabledList);
  $tours['Tour'].stop();
}

const steps = [
  {
    target: props.target,
    header: {
      title: `<span style="font-size: 1.1rem; color:#fff" class="p-component">${tip.value.title}</span>`,
    },
    content: `<div class="flex p-component"><div class="p-2"><i class="pi pi-info-circle text-justify " style="font-size: 2rem;color:#fff"></i></div><div>${tip.value.contentText}</div></div>${tip.value.image}${tip.value.contentFooter}<br>`,
    params: {
      highlight: true,
      placement: props.placement,
    }
  },
];

const Callbacks = {
  onStart: () => {
    showOverlay.value = true;
  },
  onStop: () => {
    if (props.route != 'OK') {
      router.push(props.route);
    }
    showOverlay.value = false;
    console.log("onFinish");
  },
  onFinish: () => {
    console.log("onFinish");
    showOverlay.value = false;
    store.headerTitel=props.sideTitel;
  },
};


onMounted(async () => {
  try {
    const tips=await store.getCompanyTips;
  } catch (e) {
    console.error(e);
  }
  if (props.route != '' && props.route != 'OK') {
    const newZIndexValue = 1000;
    const targetElement = document.querySelector(props.target);
    if (targetElement) {
      targetElement.style.zIndex = newZIndexValue;
    }
  }

  RdyToShow.value = true;
  setTimeout(() => {
    if ($tours) {
      if ($tours['Tour']) {
        $tours['Tour'].start();
      }
    }//LÖsung für Timingproblem rendern
  }, props.timeout);


});


</script>
<style scoped>
:deep(.overlay) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999 !important;
}
</style>
<style>
@keyframes glowing {
  0% {
    box-shadow: 0 0 0 2px #00b11db8;
  }

  50% {
    box-shadow: 0 0 0 10px #00ff2a;
  }

  100% {
    box-shadow: 0 0 0 2px #00b11db8;
  }
}

.v-tour__target--highlighted {
  animation: glowing 1300ms infinite;
  /* box-shadow: 0 0 0 99999px rgba(0,0,0,.4); */
  /*  box-shadow: 0 0 0 4px #06ff30b8!important; */
}
</style>