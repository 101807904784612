<template #content>
  <div>
    <div class="content">

      <h2>Inhaltsverzeichnis</h2>
      <ol>
        <li><a href="#section1">Einführung</a>
          <ul>
            <li><a href="#section1-1">1.1 Über die SanityBox Webseite</a></li>
          </ul>
        </li>
        <li><a href="#section2">Registrierung, Anmeldung, Zugriff und Passwort vergessen</a>
          <ul >
            <li><a href="#section2-1">2.1 Anmelden als Betreiber</a></li>
          </ul>
        </li>
        <li><a href="#section3">Benutzeroberfläche</a>
          <ul>
            <li><a href="#section3-1">3.1 Header</a></li>
            <li><a href="#section3-2">3.2 Sidebar</a></li>
            <li><a href="#section3-3">3.3 Hauptinhalt</a></li>
          </ul>
        </li>
        <li><a href="#section4">Navigation</a></li>
        <li><a href="#section5">Funktionen für Betreiber</a>
          <ul>
            <li><a href="#section5-1">5.1 Schnellstart</a></li>
            <li><a href="#section5-2">5.2 Dashboard</a></li>
            <li><a href="#section5-3">5.2 Katalog</a></li>
            <ul>
              <li><a href="#section5-3-1">5.3.1 Pflegeboxverwaltung</a></li>
              <li><a href="#section5-3-2">5.3.2 Pflegeartikelverwaltung</a></li>
            </ul>
            <li><a href="#section5-4">5.4 Patientenverwaltung</a></li>
            <li><a href="#section5-5">5.5 Rechnungsverwaltung</a></li>
            <li><a href="#section5-6">5.6 Versandabwicklung</a></li>
            <li><a href="#section5-7">5.7 Versand & QR Scanner</a></li>
            <li><a href="#section5-8">5.8 Einstellungen</a>
              <ul>
                <li><a href="#section5-8-1">8.1 Profildaten</a></li>
                <li><a href="#section5-8-2">8.1 Rechtstexte</a></li>
              </ul>
            </li>
            <li><a href="#section5.9">5.9 Abmelden</a></li>
          </ul>
        </li>
        <li><a href="#section6">Anpassungen</a>
          <ul>
            <li><a href="#section6-1">6.1 Antrag auf Kostenübernahme</a></li>
            <li><a href="#section6-2">6.2 Abrechnung mit der Krankenkasse</a></li>
          </ul>
        </li>
        <li><a href="#section7">Support und Kontakt</a>
          <ul>
            <li><a href="#section7-1">7.1 Hilfe und Unterstützung</a></li>
            <li><a href="#section7-2">7.2 Kontakt zum Support</a></li>
          </ul>
        </li>
      </ol>

      <section id="section1">
        <h2>1. Einführung</h2>

        <div id="section1-1">
          <h3>1.1 Über die SanityBox Webseite</h3>
          <p>Die SanityBox Webseite ist eine Plattform, die Betreibern von SanityBox-Geschäften dient. Sie ermöglicht die
            Verwaltung von Artikeln, Bestellungen, Kundendaten und bietet Einblicke in Berichte und Statistiken.</p>

          <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>
        </div>
      </section>

      <section id="section2">
        <h2>2. Registrierung, Anmeldung, Zugriff und Passwort vergessen</h2>
        <div id="section2-1">
          <h3>2.1 Registrierung</h3>
          <p>Um sich als Betreiber zu registrieren, gehen Sie bitte auf den Link "Erstelle ein neues Konto". Anschließend
            tragen Sie Ihre E-Mail-Adresse ein, wählen ein sicheres Passwort und bestätigen es. Klicken Sie dann auf den
            "Registrieren"-Button. Nach Absenden des Formulars erhalten Sie eine E-Mail zur Bestätigung Ihrer
            Registrierung und zur Überprüfung Ihrer E-Mail-Adresse.</p>
          <h3>2.2 Anmeldung</h3>
          <p>Melden Sie sich bitte mit Ihrer E-Mail-Adresse und dem zuvor ausgewählten Passwort an. Falls Sie Ihr Passwort
            vergessen haben, folgen Sie bitte den Schritten unter "Passwort vergessen".</p>
          <h3>2.3 Zugriff</h3>
          <p>Bei Ihrem ersten Login ist es erforderlich, Ihre Profilinformationen auszufüllen. Bitte vervollständigen Sie
            diese Seite und speichern Sie die Informationen. Sie können jederzeit über die Einstellungen auf diesen
            Bereich zugreifen.</p>
          <h3>2.4 Passwort vergessen</h3>
          <p>Klicken Sie auf "Passwort vergessen?" und geben Sie anschließend Ihre E-Mail-Adresse ein. Sie erhalten
            daraufhin eine E-Mail, die Ihnen ermöglicht, Ihr Passwort zurückzusetzen. Nachdem Sie dies erledigt haben,
            können Sie ein neues, sicheres Passwort festlegen.</p>
          <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>
        </div>
      </section>

      <section id="section3">
        <h2>3. Benutzeroberfläche</h2>
        <div id="section3-1">
          <h3>3.1 Header</h3>
          <p>Der Header der Webseite enthält das Logo der Plattform und den aktuellen Titel der Seite.</p>
        </div>

        <div id="section3-2">
          <h3>3.2 Sidebar</h3>
          <p>Die Sidebar-Navigation auf der linken Seite bietet den Betreibern Zugriff auf verschiedene Funktionen und
            Verwaltungsbereiche der Webseite.</p>
        </div>

        <div id="section3-3">
          <h3>3.3 Hauptinhalt</h3>
          <p>Der Hauptinhalt der Webseite zeigt den ausgewählten Inhalt oder die ausgewählte Funktion an.</p>
        </div>
      </section>

      <section id="section4">
        <h2>4. Navigation</h2>
        <p>Die Navigation auf der Webseite erfolgt über die Sidebar-Navigation und die bereitgestellten Links.</p>
        <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

      </section>

      <section id="section5">
        <h2>5. Funktionen für Betreiber</h2>
        <div id="section5-1">
          <h3>5.1 Schnellstart</h3>
          <p>Der Schnellstart ist ein nützliches Werkzeug, um auf unserer Plattform einen reibungslosen Einstieg zu finden
            und die essentiellen Schritte zügig zu bewältigen, um rasch beginnen zu können. Indem Sie die einzelnen
            Aufgaben in geordneter Reihenfolge abarbeiten, werden Sie sich schnell mit der Plattform vertraut machen und
            einen klaren Überblick gewinnen. Dieses Feature kann nach Bedarf im Einstellungsmenü deaktiviert und für einen
            erneuten Start ebenso leicht wieder aktiviert werden.</p>
        </div>

        <section id="section5-2">
          <h3>5.2 Dashboard</h3>
          <p>Übersicht über wichtige Kennzahlen und Statistiken. Das Dashboard bietet einen umfassenden Überblick über
            unsere monatlichen Umsätze und die Anzahl der registrierten Kunden. Es dient dazu, die Entwicklung unserer
            Schlüsselkennzahlen zu verfolgen und Muster im Laufe der Zeit zu erkennen. Mit der Möglichkeit, den Zeitraum
            anzupassen, können Sie gezielt Daten analysieren und fundierte Entscheidungen treffen.</p>
        </section>

        <section id="section5-3">
          <h3>5.3 Katalog</h3>
          <p>Verwaltung von Produkten und Artikeln im Sortiment.</p>
          <section id="section5-3-1">
            <h3>5.3.1 Pflegeboxen- Verwaltung</h3>
            <p>Die Pflegeboxenverwaltung ist ein wichtiger Bereich der SanityBox-Webanwendung, der die Verwaltung von
              Pflegeboxen ermöglicht. Pflegeboxen sind Artikel oder Produkte, die für die Pflege von Patienten benötigt
              werden.</p>
            <h3>Register-Ansicht</h3>
            <p>Die Pflegeboxen -verwaltung ist in einer Register-Ansicht organisiert, die zwei Tabs enthält:</p>
            <ol>
              <li><strong>Pflegeartikel:</strong> Dieser Tab ermöglicht die Verwaltung von einzelnen Pflegeartikeln.</li>
              <li><strong>Pflegeboxen:</strong> Dieser Tab ermöglicht die Verwaltung von Pflegeboxen, die aus einer
                Sammlung von Pflegeartikeln bestehen.</li>
            </ol>
            <h3>Pflegeboxenliste</h3>
            <p>Die Pflegeboxenliste ist eine tabellarische Darstellung von Pflegeboxen. Ähnlich wie in der
              Patientenverwaltung können Betreuer Pflegeboxen suchen, filtern und auswählen. Die Liste ist virtuell, um
              die Leistung zu optimieren.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Sortieren:</strong> Klicken Sie auf die Spaltenüberschriften, um die Liste nach verschiedenen
                Kriterien zu sortieren, z. B. "Pflegebox-ID" oder "Pflegebox-Name".</li>
              <li><strong>Filtern:</strong> Verwenden Sie die Eingabefelder in den Spaltenüberschriften, um nach
                bestimmten Pflegeboxen zu suchen.</li>
              <li><strong>Daten anzeigen:</strong> Doppelklicken Sie auf eine Zeile, um die Details einer Pflegebox
                anzuzeigen oder zu bearbeiten.</li>
              <li><strong>Neue Pflegebox hinzufügen:</strong> Klicken Sie auf das Plus-Symbol in der oberen rechten Ecke,
                um eine neue Pflegebox hinzuzufügen.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Bearbeiten:</strong> Klicken Sie auf den "Bearbeiten"-Button, um in den Bearbeitungsmodus zu
                wechseln und die Pflegeboxendaten zu aktualisieren.</li>
              <li><strong>Löschen:</strong> Klicken Sie auf den "Löschen"-Button, um eine Pflegebox zu löschen. Es wird
                eine Bestätigungsdialogbox angezeigt, bevor die Löschung durchgeführt wird.</li>
            </ul>
            <h3>Pflegeboxen-Detailansicht</h3>
            <p>Wenn Sie auf eine Zeile in der Pflegeboxenliste doppelklicken oder auf den "Bearbeiten"-Button klicken,
              wird eine Pflegeboxen-Detailansicht geöffnet. Hier können Sie alle Informationen zu einer bestimmten
              Pflegebox anzeigen und bearbeiten.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Bearbeitungsmodus:</strong> Im Bearbeitungsmodus können Sie die Daten der Pflegebox bearbeiten
                und speichern.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Bearbeiten:</strong> Ändern und speichern Sie die Daten der Pflegebox.</li>
            </ul>
            <h3>Neues Pflegeboxen erstellen</h3>
            <p>Durch Klicken auf das Plus-Symbol können Sie eine neue Pflegebox erstellen. Dies öffnet einen Dialog, in
              dem Sie die Informationen für die neue Pflegebox eingeben können.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Bearbeitungsmodus:</strong> Im Bearbeitungsmodus können Sie die Daten der neuen Pflegebox
                bearbeiten und speichern.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Speichern:</strong> Speichern Sie die Daten der neuen Pflegebox.</li>
              <li><strong>Abbrechen:</strong> Verwerfen Sie die Änderungen und schließen Sie den Dialog.</li>
            </ul>
            <h3>Löschen von Pflegeboxen</h3>
            <p>Das Löschen von Pflegeboxen erfordert eine Bestätigung, um versehentliche Löschungen zu verhindern. Wenn
              Sie eine Pflegebox löschen möchten, klicken Sie auf den "Löschen"-Button, und es wird eine Dialogbox
              angezeigt, in der Sie die Löschung bestätigen können.</p>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Ja:</strong> Bestätigen Sie die Löschung der Pflegebox. Die Pflegebox wird aus der Liste
                entfernt.</li>
              <li><strong>Nein:</strong> Brechen Sie den Löschvorgang ab, und die Pflegebox bleibt unverändert.</li>
            </ul>
            <hr>
            <p>Die Pflegeboxenverwaltung in der SanityBox-Webanwendung ermöglicht es Betreuern, Pflegeboxen effizient zu
              verwalten und sicherzustellen, dass die erforderlichen Pflegeartikel für die Patientenpflege immer verfügbar
              sind.</p>
          </section>

          <section id="section5-3-2">
            <h3>5.3.2 Pflegeartikel -Verwaltung</h3>
            <p>Die Artikelverwaltung ermöglicht es Betreibern, Informationen über verschiedene Produkte und Artikel in der
              Anwendung zu verwalten. Ähnlich wie bei der Patientenverwaltung bietet diese Funktion ein leistungsstarkes
              Werkzeug zur Bearbeitung von Produktdaten.</p>
            <h4>Artikelübersicht</h4>
            <p>Die Artikelübersicht ist eine tabellarische Darstellung der vorhandenen Artikel. Hier können Betreuer
              Artikel suchen, filtern und auswählen. Die Liste ist virtuell, was bedeutet, dass die Daten für die Tabelle
              bei Bedarf geladen werden, um die Leistung zu optimieren.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Sortieren:</strong> Klicken Sie auf die Spaltenüberschriften, um die Liste nach verschiedenen
                Kriterien zu sortieren, wie z.B. "Artikelnummer" oder "Artikelname".</li>
              <li><strong>Filtern:</strong> Verwenden Sie die Eingabefelder in den Spaltenüberschriften, um nach
                spezifischen Produktinformationen zu filtern, z.B. die Artikelnummer oder den Verkaufspreis.</li>
              <li><strong>Daten anzeigen und bearbeiten:</strong> Doppelklicken Sie auf eine Zeile, um die Details eines
                Artikels anzuzeigen oder zu bearbeiten.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Neuen Artikel hinzufügen:</strong> Klicken Sie auf das Plus-Symbol in der oberen rechten Ecke,
                um einen neuen Artikel hinzuzufügen.</li>
              <li><strong>Artikel bearbeiten:</strong> Klicken Sie auf den "Bearbeiten"-Button, um die Details eines
                Artikels anzuzeigen und zu bearbeiten.</li>
              <li><strong>Artikel löschen:</strong> Klicken Sie auf das Papierkorbsymbol, um einen Artikel zu löschen.
                Beachten Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann.</li>
            </ul>
            <h4>Artikeldetailansicht</h4>
            <p>Wenn Sie auf eine Zeile in der Artikelübersicht doppelklicken oder auf den "Bearbeiten"-Button klicken,
              wird eine Detailansicht des ausgewählten Artikels geöffnet. Hier können Sie alle Informationen zu einem
              bestimmten Artikel anzeigen und bearbeiten.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Bearbeitungsmodus:</strong> Im Bearbeitungsmodus können Sie die Daten des Artikels bearbeiten
                und speichern.</li>
              <li><strong>Artikelstatus:</strong> Der Status des Artikels wird angezeigt.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Artikel bearbeiten:</strong> Klicken Sie auf den "Bearbeiten"-Button, um in den
                Bearbeitungsmodus zu wechseln und die Artikeldaten zu aktualisieren.</li>
              <li><strong>Artikel löschen:</strong> Klicken Sie auf das Papierkorbsymbol, um den ausgewählten Artikel zu
                löschen.</li>
            </ul>
            <h4>Filtern und Sortieren</h4>
            <p>Die Artikelübersicht bietet leistungsstarke Filter- und Sortierfunktionen, um die gewünschten Informationen
              schnell zu finden. Nutzen Sie die Filterfelder in den Spaltenüberschriften, um nach spezifischen Kriterien
              zu suchen, und klicken Sie auf die Spaltenüberschriften, um die Liste zu sortieren.</p>
            <h3>Pop-up-Dialog</h3>
            <p>Wenn Sie einen Artikel löschen möchten, öffnet sich ein Pop-up-Dialog zur Bestätigung. Hier können Sie
              entscheiden, ob Sie den ausgewählten Artikel endgültig löschen möchten.</p>
            <h3>Neuen Artikel hinzufügen</h3>
            <p>Durch Klicken auf das Plus-Symbol können Betreuer einen neuen Artikel hinzufügen. Ein Dialog wird geöffnet,
              in dem die Informationen für den neuen Artikel eingegeben werden können. Nach dem Speichern wird der Artikel
              der Liste hinzugefügt.</p>
            <h3>Speichern von Änderungen</h3>
            <p>Im Bearbeitungsmodus können Sie Änderungen an den Artikeldaten vornehmen. Diese Änderungen werden erst nach
              dem Speichern dauerhaft übernommen. Der "Speichern"-Button im Dialog speichert die vorgenommenen Änderungen.
            </p>
            <h3>Löschen von Artikeln</h3>
            <p>Wenn Sie einen Artikel löschen möchten, klicken Sie auf das Papierkorbsymbol in der Artikelübersicht. Sie
              werden aufgefordert, die Löschung zu bestätigen. Beachten Sie, dass das Löschen eines Artikels
              unwiderruflich ist.</p>
            <hr>
            <p>Dies sind die grundlegenden Funktionen und Interaktionen der Artikelverwaltung in der
              SanityBox-Webanwendung. Diese Funktion bietet Betreuern ein leistungsstarkes Tool zur Verwaltung von
              Produktdaten und trägt zur Automatisierung und Effizienzsteigerung bei.</p>
            </section>
        </section>

        <div id="section5-4">
          <h3>5.4 Patienten -Verwaltung</h3>
          <p>Die Patientenverwaltung ist ein zentraler Bereich der SanityBox-Webanwendung, der es Betreuern ermöglicht,
            Patientendaten anzuzeigen, zu bearbeiten und zu verwalten. Diese Funktion automatisiert und erleichtert die
            Verwaltung von Pflegepatienten erheblich.</p>
          <section id="patientenliste">
            <h4>Patientenliste</h4>
            <p>Die Patientenliste ist eine tabellarische Darstellung der Pflegepatienten. Hier können Betreuer Patienten
              suchen, filtern und auswählen. Die Liste ist virtuell, was bedeutet, dass die Daten für die Tabelle bei
              Bedarf geladen werden, um die Leistung zu optimieren.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Sortieren:</strong> Klicken Sie auf die Spaltenüberschriften, um die Liste nach verschiedenen
                Kriterien zu sortieren, wie beispielsweise "Patienten-ID" oder "Registrierungsdatum".</li>
              <li><strong>Filtern:</strong> Verwenden Sie die Eingabefelder in den Spaltenüberschriften, um nach
                spezifischen Patienteninformationen zu filtern, z. B. den Namen des Patienten oder die Postleitzahl.
              </li>
              <li><strong>Daten anzeigen:</strong> Doppelklicken Sie auf eine Zeile, um die Details eines Patienten
                anzuzeigen oder zu bearbeiten.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Neuen Patienten hinzufügen:</strong> Klicken Sie auf das Plus-Symbol in der oberen rechten
                Ecke, um einen neuen Patienten hinzuzufügen.</li>
            </ul>
          </section>
          <section id="patientendetailansicht">
            <h4>Patienten-Detailansicht</h4>
            <p>Wenn Sie auf eine Zeile in der Patientenliste doppelklicken oder auf den "Bearbeiten"-Button klicken,
              wird eine Patienten-Detailansicht geöffnet. Hier können Sie alle Informationen zu einem bestimmten
              Patienten anzeigen und bearbeiten.</p>
            <h4>Funktionen und Interaktionen:</h4>
            <ul>
              <li><strong>Bearbeitungsmodus:</strong> Im Bearbeitungsmodus können Sie die Daten des Patienten bearbeiten
                und speichern.</li>
              <li><strong>Registriertes Datum:</strong> Das Registrierungsdatum des Patienten wird angezeigt.</li>
              <li><strong>Status:</strong> Der Status des Patienten wird farblich hervorgehoben, um seinen aktuellen
                Zustand anzuzeigen.</li>
            </ul>
            <h4>Aktionen:</h4>
            <ul>
              <li><strong>Bearbeiten:</strong> Klicken Sie auf den "Bearbeiten"-Button, um in den Bearbeitungsmodus zu
                wechseln und die Patientendaten zu aktualisieren.</li>
              <li><strong>Rechnung erstellen:</strong> Erstellen Sie eine neue Rechnung für den ausgewählten Patienten.
                Dies ist besonders nützlich für die Abrechnung von Pflegeleistungen.</li>
            </ul>
          </section>
          <section id="patientenfilternundsortieren">
            <h4>Filtern und Sortieren</h4>
            <p>Die Patientenliste bietet leistungsstarke Filter- und Sortierfunktionen, um die gewünschten Informationen
              schnell zu finden. Nutzen Sie die Filterfelder in den Spaltenüberschriften, um nach spezifischen Kriterien
              zu suchen, und klicken Sie auf die Spaltenüberschriften, um die Liste zu sortieren.</p>
          </section>
          <section id="Popupmenue">
            <h4>Pop-up-Menü</h4>
            <p>Das Pop-up-Menü wird angezeigt, wenn auf den "Bearbeiten"-Button in einer Zeile der Tabelle geklickt
              wird. Es enthält Optionen zum Ansehen und Bearbeiten von Patientendaten sowie zum Erstellen von Rechnungen
              für den ausgewählten Patienten.</p>
          </section>
        </div>

        <section id="section5-5">
          <h3>5.5 Rechnungen</h3>
          <p>Anzeigen und Verwalten von Rechnungen.</p>
        </section>

        <section id="section5-6">
          <h3>5.6 Versandabwicklung</h3>
          <p>Vorbereitung von Versandaufträgen. Die Versandabwicklung ist ein wichtiger Menüpunkt in unserer Anwendung,
            der es Ihnen ermöglicht, Versandvorgänge effizient zu verwalten und zu überwachen. Dieses Handbuch führt Sie
            durch die verschiedenen Funktionen und Optionen, die in der Versandabwicklung verfügbar sind.</p>
          <ul>
            <li>
              <strong>Ansicht der Versandliste</strong>
              Die Versandliste zeigt eine Liste der Versandvorgänge an, die in Ihrem Unternehmen bearbeitet werden
              müssen. Sie können Details zu jedem Versandvorgang einsehen, einschließlich des Versand-IDs, des
              Patientennamens, der PLZ und des Versandstatus
            </li>
            <li>
              <strong>Filtern und Sortieren der Versandliste</strong>
              Sie können die Versandliste nach verschiedenen Kriterien filtern und sortieren. Verwenden Sie die
              Filterfelder oben in der Tabelle, um die Liste nach bestimmten Kriterien wie Versand-ID oder
              Patientennamen zu durchsuchen. Sie können auch die Sortierreihenfolge ändern, indem Sie auf die
              Spaltenüberschriften klicken.
            </li>
            <li>
              <strong>Bearbeiten von Versanddaten</strong>
              Durch Doppelklicken auf eine Zeile in der Versandliste können Sie die Daten eines Versandvorgangs
              bearbeiten. Dies ermöglicht es Ihnen, Informationen zu aktualisieren oder zu ändern, falls erforderlich.
            </li>
            <li>
              <strong>Ändern des Versandstatus</strong>
              Der Versandstatus ist wichtig, um den Fortschritt eines Versandvorgangs zu verfolgen. Sie können den
              Versandstatus ändern, indem Sie auf das entsprechende Symbol in der "Versandstatus"-Spalte klicken. Es
              stehen verschiedene Statusoptionen zur Verfügung, z. B. "BOX_IN_PROGRESS" und "BOX_SENT".
            </li>
            <li>
              <strong>Etikettengenerierung</strong>
              Die Versandabwicklung bietet auch eine Funktion zur Etikettengenerierung. Wenn Sie Versandvorgänge
              auswählen, können Sie Etiketten für diese Vorgänge generieren. Verwenden Sie dazu die Schaltfläche
              "Etiketten generieren" oben in der Tabelle.
            </li>
            <li>
              <strong>Anzeige auf mobilen Geräten</strong>
              Die Versandabwicklung ist auch für die Anzeige auf mobilen Geräten optimiert. Sie können die Anwendung
              auf Ihrem Smartphone oder Tablet verwenden, um die Versandliste und andere Funktionen anzuzeigen und zu
              nutzen.
            </li>
          </ul>
        </section>

        <section id="section5-7">
          <h3>5.7 Versand & QR Scanner</h3>
          <p>Dieses Feature ermöglicht es Ihnen, QR-Codes auf Sendungen oder Lieferdokumenten zu scannen und die Sendungen
            automatisch als "versendet" zu markieren. Der QR-Scanner bietet eine effiziente Methode zur Identifizierung
            und Verfolgung von Sendungen, wodurch der Versandprozess optimiert und beschleunigt wird.</p>
          <h4>Funktionsweise</h4>
          Der QR-Scanner erfasst QR-Codes, die auf Sendungen angebracht sind. Sobald ein QR-Code erfolgreich gescannt
          wurde, wird die zugehörige Sendung automatisch als "versendet" markiert. Dieser automatisierte Prozess bietet
          eine schnelle und effiziente Möglichkeit zur Überwachung und Verfolgung von Lieferungen.
          <h4>Vorteile</h4>
          <ul>
            <li><strong>Echtzeit-Verfolgung</strong>: Sie haben jederzeit Einblick in den aktuellen Status aller erfassten
              Sendungen.</li>
            <li><strong>Automatisierung</strong>: Der Scanvorgang markiert Sendungen automatisch als "versendet", wodurch
              menschliche Eingriffe minimiert werden.</li>
            <li><strong>Effizienz</strong>: Die automatisierte Erfassung und Markierung reduziert den manuellen Aufwand
              und erhöht die Produktivität.</li>
            <li><strong>Fehlerminimierung</strong>: Durch die Automatisierung werden menschliche Fehler minimiert.</li>
          </ul>
          <h4>Nutzung</h4>
          <ul>
            <li><strong>Scannen</strong>: Aktivieren Sie den Scanner und richten Sie die Kamera auf den QR-Code. Scannen
              Sie den QR-Code, um die Sendung zu erfassen.</li>
            <li><strong>Automatische Markierung</strong>: Nach erfolgreicher Erfassung wird die Sendung automatisch als
              "versendet" markiert.</li>
          </ul>
          <h4>Hinweis</h4>
          Der QR-Scanner benötigt Zugriff auf die Kamera Ihres Geräts. Bitte gewähren Sie die entsprechenden
          Berechtigungen, wenn Sie dazu aufgefordert werden.
          Mit dem QR-Scanner für Sendungsverfolgung wird die Verfolgung und Überwachung Ihrer Sendungen einfacher und
          schneller, da Sendungen nach dem Scannen automatisch als "versendet" markiert werden.
          <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

        </section>

        <section id="section5-8">
          <h3>5.8 Einstellungen</h3>
          <section id="section5-8-1">
            <h3>Profildaten</h3>
            <p>Verwalten von Unternehmensprofilen.</p>
            <ul>
              <li>
                <strong>Firmenname</strong> Das Feld "Firmenname" ermöglicht es Ihnen, den Namen Ihrer Firma oder Ihres
                Unternehmens anzugeben oder
                zu
                aktualisieren. Dieser Name wird in der Benutzeroberfläche angezeigt und kann nicht nachträglich geändert
                werden. Bitte stellen Sie sicher, dass der Name korrekt und eindeutig ist.

              </li>

              <li>
                <strong>Adresse</strong> In den Abschnitten "Straße", "Hausnummer", "Postleitzahl" und "Stadt" können Sie
                die
                Adresse Ihres Unternehmens eingeben oder aktualisieren. Stellen Sie sicher, dass die Adresse korrekt ist,
                da sie für die Versandabwicklung und die Anzeige auf der Plattform verwendet wird.
              </li>

              <li>
                <strong>Adresszusatz</strong> Das Feld "Adresszusatz" ermöglicht es Ihnen, zusätzliche Informationen zur
                Adresse
                hinzuzufügen, beispielsweise spezielle Anweisungen oder Hinweise für den Versand.
              </li>

              <li>
                <strong>Telefonkontakt</strong> Hier können Sie die Telefonnummer Ihres Unternehmens ändern oder
                aktualisieren.
                Dies ist wichtig, um sicherzustellen, dass Kunden oder Patienten Sie bei Fragen oder Anliegen kontaktieren
                können.
              </li>

              <li>
                <strong>Steuernummer oder Umsatzsteuer-ID</strong> In diesem Abschnitt können Sie wählen, ob Sie Ihre
                Steuernummer
                oder Ihre Umsatzsteuer-Identifikationsnummer (Umsatzsteuer-ID) angeben möchten.
              </li>

              <li>
                <strong>Institutionskennzeichen (IK-Nummer)</strong> Das Feld "IK-Nummer" ermöglicht es Ihnen, die
                Institutionskennzeichen-Nummer Ihres Unternehmens einzugeben oder zu aktualisieren. Dies kann relevant
                sein, wenn Sie im Gesundheitswesen tätig sind und in Korrespondenz mit der Krankenversicherung stehen.
              </li>

              <li>
                <strong>Bankverbindung</strong> Im Abschnitt "Bankverbindung" können Sie Informationen zur Bankverbindung
                Ihres
                Unternehmens eingeben oder aktualisieren. Dies beinhaltet den Kontoinhaber, IBAN, BIC und den Namen der
                Bank.
              </li>

              <li>
                <strong>Aliasname für die Firma</strong> Das Feld "Firmen-Alias" ermöglicht es Ihnen, einen Aliasnamen für
                Ihre
                Firma zu wählen. Dieser Aliasname kann verwendet werden, um Ihr Unternehmen auf der Plattform zu
                identifizieren.
              </li>

              <li>
                <strong>Kontaktdaten</strong> Im Abschnitt "Kontaktdaten" können Sie Informationen zur Webseite Ihres
                Unternehmens, zur Mobiltelefonnummer, zur Festnetznummer, zur Rechtsform, zum Inhaber der Firma, zum
                Registergericht und zur HRB-Nummer eingeben oder aktualisieren. Diese Informationen sind wichtig und
                werden möglicherweise für rechtliche und geschäftliche Zwecke benötigt.
              </li>

              <li>
                <strong>Passwort ändern</strong> Hier haben Sie die Möglichkeit, Ihr Passwort zu ändern. Wenn Sie Ihr
                Passwort
                aktualisieren möchten, klicken Sie auf den entsprechenden Button und folgen Sie den Anweisungen.
              </li>
            </ul>
            <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

          </section>
          <section id="section5-8-2">
            <h3>Rechtstexte</h3>
            <p>Die Rechtstexte-Seite ermöglicht es Ihnen, verschiedene rechtliche Texte, wie AGB, Widerrufsbelehrung,
              Datenschutzerklärung, Versandbedingungen und Impressum, zu bearbeiten und zu speichern. Diese Texte werden
              auf der Seite angezeigt, auf der sich Patienten registrieren und ihre Pflegehilfsmittel auswählen. Folgen
              Sie den Schritten unten, um die Rechtstexte auf Ihrer Webseite anzupassen:</p>
            <h4>Schritt 1: AGB bearbeiten</h4>
            <ol>
              <li>Klicken Sie auf die Registerkarte "AGB", um die Allgemeinen Geschäftsbedingungen (AGB) zu bearbeiten.
              </li>
              <li>Verwenden Sie den Editor auf der Seite, um die AGB nach Ihren Anforderungen anzupassen.
              </li>
            </ol>
            <h4>Schritt 2: Widerrufsbelehrung bearbeiten</h4>
            <ol>
              <li>Klicken Sie auf die Registerkarte "Widerrufsbelehrung", um die Widerrufsbelehrung zu bearbeiten.
              </li>
              <li>Bearbeiten Sie den Text im Editor entsprechend Ihren Bedürfnissen.
              </li>
            </ol>
            <h4>Schritt 3: Datenschutzerklärung bearbeiten</h4>
            <ol>
              <li>Klicken Sie auf die Registerkarte "Datenschutzerklärung", um die Datenschutzerklärung zu bearbeiten.
              </li>
              <li>Nutzen Sie den Editor, um die Datenschutzerklärung an Ihre Datenschutzpraktiken anzupassen.
              </li>
            </ol>
            <h4>Schritt 4: Versandbedingungen bearbeiten</h4>
            <ol>
              <li>Wählen Sie die Registerkarte "Versandbedingungen" aus, um die Versandbedingungen anzupassen.
              </li>
              <li>Bearbeiten Sie die Texte im Editor, um die Versandbedingungen gemäß Ihren Anforderungen zu gestalten.
              </li>
            </ol>
            <h4>Schritt 5: Impressum bearbeiten</h4>
            <ol>
              <li>Klicken Sie auf die Registerkarte "Impressum", um das Impressum anzupassen.
              </li>
              <li>Optional: Aktivieren Sie den Schalter "Link Impressum anzeigen", um das Impressum auf Ihrer Webseite
                anzuzeigen.
              </li>
              <li>Verwenden Sie den Editor, um das Impressum nach Ihren Anforderungen zu gestalten. Der Editor unterstützt
                HTML-Inhalte.
              </li>
            </ol>
            <h4>Schritt 6: Speichern</h4>
            <ol>
              <li>Nachdem Sie alle gewünschten Änderungen vorgenommen haben, klicken Sie auf die Schaltfläche
                "Speichern".Dadurch werden die aktualisierten Rechtstexte auf Ihrer Webseite gespeichert.</li>
            </ol>
            <h4>Schritt 7: Erfolgsmeldung</h4>
            <ol>
              <li>Sie erhalten eine Erfolgsmeldung, die bestätigt, dass die Rechtstexte erfolgreich gespeichert wurden.
              </li>
              <li>Die aktualisierten Rechtstexte sind nun auf der Seite verfügbar, auf der sich Patienten registrieren und
                ihre Pflegehilfsmittel auswählen können.
              </li>
            </ol>
            Mit dieser Anleitung können Sie die Rechtstexte auf Ihrer Webseite anpassen und sicherstellen, dass sie den
            aktuellen rechtlichen Anforderungen entsprechen. Beachten Sie, dass Änderungen an Rechtstexten von großer
            Bedeutung sind, und es ist ratsam, rechtlichen Rat einzuholen, um sicherzustellen, dass sie den gesetzlichen
            Vorschriften entsprechen.
          </section>
          <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

        </section>

        <div id="section5.9">
          <h3>5.9 Abmelden</h3>
          <p>Sicher abmelden und die Sitzung beenden.</p>
          <ol>
            <li>Um sich sicher abzumelden, klicken Sie auf "Abmelden" im Menü unten auf der Seite.</li>
            <li>
              Nachdem Sie auf "Abmelden" geklickt haben, wird eine Bestätigung angezeigt.
            </li>
            <li>
              Klicken Sie erneut auf "Abmelden" in der Bestätigung, um sich sicher abzumelden.
            </li>
          </ol>
        </div>
        <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

      </section>

      <section id="section6">
        <h2>6. Antrag auf Kostenübernahme</h2>
        <div id="section6-1">
          <h3>6.1 Antrag auf Kostenübernahme</h3>
          <p>Vielleicht kommt der Teil zur Patientenverwaltung
          </p>
        </div>

        <div id="section6-2">
          <h3>6.2 Abrechnung mit der Krankenkasse</h3>
          <p>Vielleicht kommt der Teil noch keine Ahnung</p>
        </div>
        <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>

      </section>

      <section id="section7">
        <h2>7. Support und Kontakt</h2>
        <div id="section7-1">
          <h3>Hilfe und Unterstützung</h3>
          <p>Wenn Sie Hilfe oder Unterstützung benötigen, stehen Ihnen Ressourcen zur Verfügung, um Ihre Fragen zu
            beantworten und Probleme zu lösen.</p>
        </div>

        <div id="section7-2">
          <h3>Kontakt zum Support</h3>
          <p>Für dringende Fragen oder technische Probleme können Sie den Support kontaktieren, um Unterstützung zu
            erhalten.</p>
        </div>
        <a class="backToMain" href="#" @click="scrollToTop">Zurück zum Anfang</a>
      </section>
      <footer>
        © 2023 sanitybox. Alle Rechte vorbehalten.
      </footer>
    </div>
  </div>
</template>



  
<script>
export default {
  name: 'SanityBoxBetreiberHandbuch',
}
</script>
  
<style scoped>

@counter-style q {
  system: extends decimal;
  prefix: "Q";
}
ol.q {
  counter-reset: q;
}

ol.q > li {
  counter-increment: q;
}

ol.q > li::marker {
  content: "Q" counter(q) ". ";
}

header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.content {
  margin: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  overflow-y: auto;
}

nav ol {
  margin: 0;
  padding: 0;
}

section {
  scroll-margin-top: 80px;
}

section.div {
  scroll-margin-top: 80px;
}

nav ol li {
  list-style-type: none;
}

nav ol a {
  text-decoration: none;
  color: #0074D9;
  display: block;
  margin-top: 10px;
  position: relative;
  transition: 0.2s;
}


nav a:hover {
  text-decoration: underline;
}

.backToMain {
  display: block;
  text-align: right;
  margin-top: 10px;
  color: #0074D9;
}


h1,
h2,
h3,
h4 {
  color: #333;
  scroll-margin-top: 80px;
}

p {
  line-height: 1.6;
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #007BFF;
  color: white;
}</style>
  