<template>
  <div class="grid grid-nogutter surface-section text-800">
    <div
      class="col-12 md:col-5 p-5 text-center md:text-left flex align-items-center"
    >
      <Toast />
      <section>
        <span class="block text-4xl md:text-6xl font-bold mb-1">
          mühelos leicht &</span
        >
        <div class="text-4xl md:text-6xl text-primary font-bold mb-3">
          voll automatisiert!
        </div>

        <!--             <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
 -->
        <!--  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6"> -->
        <div class="text-center mb-4">
          <img
            src="@/assets/sanitybox2_icon_mini.png"
            alt="Image"
            height="60"
            class="mb-3"
          />
          <div class="text-900 text-3xl font-medium mb-3">
            In Sanitybox einloggen
          </div>
          <span class="text-600 font-medium line-height-3"
            >Noch kein Account?</span
          >
          <div>
            <a
              class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              @click="store.registersStayOpen = true"
              >Erstelle ein neues Konto!</a
            >
          </div>
          <span style="color: rgb(184, 7, 7)" v-if="store.expired == true">
            <p></p>
            <div>Ihr Zugang ist abgelaufen,</div>
            <div>Bitte neu einloggen!</div>
            <p></p>
          </span>
        </div>

        <div>
          <Form
            @submit="submitData"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="alert alert-danger col-md-8 offset-2" v-if="error">
              {{ errorDisplayText }}
            </div>
            <div class="card flex justify-content-center gap-3 p-3">
              <span class="p-float-label">
                <InputText
                  class="px-4"
                  id="email"
                  v-model="form.email"
                  type="email"
                  aria-describedby="text-error"
                />
                <label for="email">E-Mail Adresse</label>
              </span>
            </div>
            <div class="card flex justify-content-center gap-3 p-1"></div>
            <div class="card flex justify-content-center">
              <span class="p-float-label">
                <Password
                  v-model="form.password"
                  id="password1"
                  type="password"
                  toggleMask
                  :feedback="false"
                  
                />
                <label for="password">Passwort</label>
              </span>
            </div>

            <div class="card flex justify-content-center p-3">
              <a
                class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                @click="resetPassShow = true"
                >Passwort vergessen?</a
              >
            </div>

            <Button
              type="submit"
              label="Jetzt anmelden"
              icon="pi pi-user"
              class="w-full"
              @click="submitData"
              :disabled="isLoading"
            ></Button>
            <!--             <Button @click="whoami()"> </Button>
            <Button @click="store.logout"> logout </Button> -->
          </Form>
        </div>
        <!-- </div> -->
        <!-- </div>
 -->
        <BlockUI :blocked="store.registersStayOpen" fullScreen />

        <Dialog
          v-model:visible="store.registersStayOpen"
          :modal="false"
          :closable="true"
          @keydown.esc.prevent=""
          modal
          header="Registrierung"
          :style="{ width: '20vw' }"
          :breakpoints="{ '960px': '40vw', '641px': '70vw' }"
        >
          <RegisterComponent></RegisterComponent>
        </Dialog>

        <Dialog
          v-model:visible="resetPassShow"
          :modal="false"
          :closable="true"
          @keydown.esc.prevent=""
          modal
          header="Password zurücksetzen"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '75vw', '641px': '80vw' }"
        >
          <ResetPassword></ResetPassword>
        </Dialog>
      </section>
    </div>
    <div class="hidden md:inline-flex col-12 md:col-7 overflow-hidden">
      <img
        src="@/assets/startImage.jpg"
        alt="Image"
        class="hidden md:inline-flex md:ml-auto block md:h-full"
        style="clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%)"
      />
      <!--  -->
    </div>
  </div>
</template>
<script setup>
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { ref, reactive, watchPostEffect, watch, provide } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import RegisterComponent from "./RegisterComponent";
import ResetPassword from "./ResetPassword";
import { useToast } from "primevue/usetoast";

const toast = useToast();

const store = userStore();
const router = useRouter();

const resetPassShow = ref(false);

const schema = yup.object().shape({
  email: yup
    .string()
    .required("E-Mail Adresse wird benötigt.")
    .trim()
    .email("Keine gültige E-Mail Adresse."),
  password: yup
    .string()
    .required("Password wird benötigt.")
    .min(6, "Password mindestens 6 Zeichen"),
});

const form = reactive({
  email: "", 
  password: "",
});
const error = ref("");
const isLoading = ref(false);

function errorDisplayText() {
  console.log(error.value);
  if (error.value) {
    if (error.value.includes("WRONG_PASSWORD")) {
      return "Passwort nicht korrekt.";
    } else if (error.value.includes("USER_NOT_FOUND")) {
      return "E-Mail Adresse nicht gefunden.";
    }
    return "Ein unbekannter Fehler, versuche es nochmal.";
  }
  return "";
}

function whoami() {
  console.log(store.whoAmI); //TODO DEBUG
}

const userId = ref("");

watchPostEffect(() => {
  userId.value = store.userId; // Überwache die Änderungen von store.userId
});

const tipEnabledList = store.tipEnabledList;

watch(userId, () => {
  if (store.isAuthenticated === true) {
    //store.whoAmI;
    if (store.firstLogin === true) {
      router.push("/companySettingsForm");
    } else {
    !tipEnabledList?.tipsList?.hasOwnProperty("fastStartVisible") || tipEnabledList?.tipsList?.["fastStartVisible"]?
    router.push("/faststart"):router.push("/dashboard");
    }
  }
});
 
async function submitData() {
  toast.removeAllGroups();
  isLoading.value = true;
  store.expired = false;
  error.value = "";
  const payload = {
    email: form.email,
    password: form.password,
  };
  try {
    await schema.validate(payload);
    const response = await store.signin(payload);
    isLoading.value = false;
    //store.whoAmI();
    if (store.firstLogin === true) {
      router.push("/companySettingsForm");
    } else {    !tipEnabledList?.tipsList?.hasOwnProperty("fastStartVisible") || tipEnabledList?.tipsList?.["fastStartVisible"]?
    router.push("/faststart"):router.push("/dashboard");}
  } catch (error) {
    error.value = error.message;
    console.log("🚀 ~ file: TheLogin.vue:226 ~ submitData ~ error.message:",error.value)
    if (error.value == "CONFIRM_EMAIL") {
      toast.add({
        severity: "error",
        summary: "Email Validierung",
        detail:
          "Bitte vorerst die Email bestätigen, schauen Sie auch in Ihrem Spam Ordner",
           life: 3000
      });
    } else if (
      error.value == "USER_NOT_FOUND" ||
      error.value == "WRONG_PASSWORD"
    ) {
      toast.add({
        severity: "error",
        summary: "Eingabe Fehler",
        detail: "Benutzername oder Passwort Fehlerhaft.",
         life: 3000
      });
    }
    else{
      toast.add({
        severity: "error",
        summary: "Eingabe Fehler",
        detail: error.value,
        life: 3000
      });
    }
    isLoading.value = false;
  }
}
</script>

<style scoped>
.font-medium {
  font-weight: 500 !important;
}
</style>
