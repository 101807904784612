<template>
  <div>
    <Toast />
    <div class="card flex justify-content-center">
<!--       <Card style="width: 100%; margin: 10px;">
        <template #title>Pflegeboxen [Auswahlbereich]</template>
        <template #content> -->
          <Tip :tipId="'KlickWeiterOrAbortTip'" :target="'#weiterButton'" :placement="'left'" :overlay="false" />
<!--           <Button v-if="!props.hideSelectButton" class="mr-2" icon="pi pi-forward" id="weiterButton" label="Weiter"
            :outlined="!selected" :disabled="!selected" @click="handleSelectBox"></Button> -->
          <slot></slot>
          <div v-if="loading">Loading...</div>
          <div v-else>
            <Tip v-if="showEmptyBoxTip" :tipId="'NoBoxesTip'" :target="'#BoxenListe'" :placement="'top'"
              :overlay="true" />

            <Accordion @tab-open="handleTabOpen" @tab-close="handleTabClose" collapseIcon="pi pi-check-circle"
              expandIcon="pi pi-circle">
              <AccordionTab v-for="(box, index) in boxes" :key="index" :active="activeIndex === index" :pt="{
                headerAction: ({ props, parent }) => ({
                  class: panelClass(props, parent, index),
                }),
              }">
                <template #header>
                  <div :class="[
                    'accordion-header flex flex-wrap p-0 justify-content-left gap-3',
                    activeIndex === index ? 'bg-primary' : '',
                  ]">
                    <img class="w-4rem shadow-2 flex-shrink-0 border-round"
                      :src="box.boxImageUrl ? item.boxImageUrl : require('@/assets/no-box.jpg')" :alt="box.productName" />
                    <div
                      v-tooltip.top="{ value: getTooltipContent(box), escape: true, fitContent: false, class: 'custom-tooltip' }">
                      <div>
                        <div class="flex-1 flex flex-column gap-2 header-text ">{{ box.productName }}</div>
                        <div class="flex align-items-left gap-2">
                          <i class="pi pi-tag text-sm">{{ getBoxDescription(box) }}</i>
                        </div>
                        <div class="header-subtitle">{{ hasVariants(box) ? "Varianten vorhanden" : "" }}</div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="accordion-content flex flex-column md:flex-row card-container indigo-container">
                  {{ item }}
                  <template v-for="item in box.productItemList">
                    <div v-if="item.hasGroup" class="flex m-1">
                      <div class="flex-1 w-12rem md:flex-none w-full">
                        <div class="h-full" v-for="variantItems in item.itemGroupList">
                          <div class="p-card h-full">
                            <div class="card-container indigo-container overflow-hidden h-auto m-1">
                              <div class="p-1 m-1 w-full md:h-5rem sm:h-auto">{{ item.itemName }}</div>
                              <span>
                                <h5 style="position: relative; text-align: center;">{{ variantItems.groupName }}</h5>
                              </span>
                              <div class="flex-1 md:flex-none flex align-items-center justify-content-left px-2 py-1 "
                                v-for="variant in variantItems.groupVariantList">
                                <span style="display:none">{{ variantItems.selectedVariant ==
                                  variant ? variantValue = variant.variantValue : "" }}</span>
                                <div class="w-full">
                                  <Button :label="variant.variantValue" size="small" class="p-1 m-1 w-full"
                                    :severity="variantItems.selectedVariant == variant ? 'success' : 'error'"
                                    :outlined="variantItems.selectedVariant != variant"
                                    @click="selectVariant(box, variant, variantItems)" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
<!--         </template>
      </Card> -->
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted,inject } from 'vue';
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";

const dialogRef = inject("dialogRef");
const store = userStore();
const toast = useToast();
const boxes = ref({});
const loading = ref(true);
const selected =ref(false);
dialogRef.selected=selected;
let selectedBox = [];
dialogRef.selectedBox=selectedBox;
const showEmptyBoxTip = ref(false);
const props = defineProps({
  hideSelectButton: false,
  saveButtonRef: Object,
});

const variantValue = ref("");

const selectVariant = (box, variant, variantItems) => {
  variantItems.selectedVariant = variant;
  variantValue.value = variant.variantValue;
  selected.value = checkValideVariants(box);
  if (selected.value == true) selectedBox.value=box;
}

const hasVariants = ref((box) => {
  return box.productItemList.some(item => item.hasGroup === true);
});

const checkValideVariants = (box) => {
  return !box.productItemList.some(item => item.itemGroupList[0].selectedVariant == null);
}

const handleTabOpen = (event) => {
  const index = event.index;
  const box = boxes.value[index];
  selected.value = false;
  selectedBox.value = null;

  if (!hasVariants.value(box)) {
    selectedBox.value =box;
    selected.value = true;
  } else {
    selected.value = checkValideVariants(box);
    console.log("🚀 ~ file: Boxes.vue:130 ~ handleTabOpen ~ dialogRef:", dialogRef)
    if (selected.value===true) selectedBox.value =box;
  }
}

const handleTabClose = () => {
  selected.value = false;
}

const panelClass = (props, parent, index) => {
  return [
    {
      'panel-highlight': parent.state.d_activeIndex === index,
    },
  ];
};

const getBoxDescription = (data) => {
  const items = data.productItemList;
  const itemList = items.map(item => `${item.quantity}x ${item.itemName}`).join(', ');
  return itemList;
}

const getTooltipContent = (data) => {
  const items = data.productItemList;
  const itemsList = items.map((item, index) => {
    if (index % 2 === 0) {
      // Start einer neuen Spalte
      return `<div class="column"><div class="item"><div class="grid"><div class="p-2">${item.quantity}Stk. ${item.itemName}</div><img class="w-2rem shadow-2 flex-shrink-0 border-round" src="${item.itemImageUrl !== '' ? item.itemImageUrl : require('@/assets/no-image.jpg')}" alt="${item.itemName}" /></div></div>`;
    } else {
      // Fortsetzung der aktuellen Spalte
      return `<div class="item"><div class="grid"><div class="p-2">${item.quantity}Stk. ${item.itemName}</div><img class="w-2rem shadow-2 flex-shrink-0 border-round" src="${item.itemImageUrl !== '' ? item.itemImageUrl : require('@/assets/no-image.jpg')}" alt="${item.itemName}" /></div></div></div>`;
    }
  }).join('');
  return `<h5 class="text-white">Boxinhalt:</h5><div class="columns">${itemsList}</div>`;
};


onMounted(async () => {
  try {
    const response = await store.sendToEp("/products/boxes");
    boxes.value = response.data;
    if (boxes.length == 0) {
      console.log("🚀 ~ file: Boxes.vue:111 ~ onMounted ~ boxes.length:", boxes.length)
      showEmptyBoxTip.value = true;
    }
  } catch (error) {
    console.error(error);
    toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Laden der Daten" });
  } finally {
    loading.value = false; // Daten sind geladen, Ladezustand beenden
  }
});

</script>
<style scoped>
:deep(.columns) {
  display: flex;
  flex-wrap: wrap;
}
:deep(.column) {
  flex-basis: 50%;
  box-sizing: border-box;
}
:deep(.item) {
  margin-bottom: 10px;
}
.custom-tooltip {
  width: 400px !important;
}
.accordion-header {
  display: flex;
  align-items: center;
}
.header-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.header-text {
  font-weight: bold;
}
.header-subtitle {
  color: #888;
}
:deep(.p-accordion .p-accordion-content) {
  padding: 0;
}
</style>
<style>
.panel-highlight {
  color: #1D4ED8;
  background: #d0e1fd;
}
</style>