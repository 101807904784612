import { userStore } from "@/store/userStore";

export const getItems = (data) => {
  return data;
};

export const ItemsService = {
  async getData() {
    const store = userStore();
    const response = await store.sendToEp("/products/item");
    const data = await response.data;
    //data.numberOfPatients = data.numberOfPatients; //TODO zu letzt eingefügt denk daran wenn Probleme 29.06.15:53
    //console.log("🚀 ~ file: PatientenService.js:8 ~ getData ~ data:", data)
    return Promise.resolve(data);
  },

  getItemsSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getItemsMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async getItemsXLarge() {
    return Promise.resolve(this.getData());
  },

};
