import axios from "axios";
import router from "@/router";
import { userStore } from "@/store/userStore";

// definition of request <pre> settings and headers
// these will be applied for all requests made with defAxios
const iAxios = axios.create({
  withCredentials: true,
  xsrfHeaderName: "X-Csrftoken",
  xsrfCookieName: "csrf_access_token",
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
  responseType: "json"
});

/*  iAxios.interceptors.response.use((config) => {
    return config;
  });
 */

  iAxios.interceptors.response.use(
    config => {
      return config;
    },
    async error => {
      const originalRequest = error.config;
      const store = userStore();
      if (error.response && error.response.status === 401) {/* 
        if (router.currentRoute.value.name !== 'login' && error.response.status === 403) {
          store.setExpired(true);
        } */
        try {
          const res_1 = await new Promise((resolve, reject) => {         
            store.sendToEp("/refresh")
              .then(res => {
                resolve(res);
              })
              .catch(err => {
                reject(err);
              });
          });
          //return await axios(originalRequest);
          return await Promise.reject(error);
        } catch (err_1) {
          return await Promise.reject(err_1);
        }
      } else if (error.response && error.response.status === 403) {
        if (router.currentRoute.value.name !== 'login') {
          store.setExpired(true);
          console.clear();
          this.$router.push('/login');
        }
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );





/* iAxios.interceptors.response.use(response => response,error => {
  console.log("response");
      if (error.response.status === 401) {
        // Fehler ignorieren und eigene Fehlermeldung anzeigen
        console.log("Unauthorisiert: Bitte melden Sie sich an.");
      }
      return Promise.reject(error);
    }
  ); */




export default iAxios;