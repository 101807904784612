<template>
  <div class="card p-fluid">
    <Loading v-show="loading" />


    <!--Tip v-if="lazyLoading == false && virtualItems.length > 0" :target="'#editPatient'" :tipId="'editItemTip'" :placement="'right'"
      :overlay="true" /-->

    <DataTable v-model:filters="filters" :value="virtualItems" editMode="row" scrollable scrollHeight="85vh"
      filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
      selectionMode="single" dataKey="patient_pub_id" sortField="item_id" :sortOrder="-1" class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick" :virtualScrollerOptions="{
        lazy: true,
        onLazyLoad: loadItemsLazy,
        itemSize: 46,
        delay: 300,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100
      }">
      <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #header>
          <Button type="button" outlined text size="small" icon="pi pi-plus-circle" rounded @click="handleNewRow" />
        </template>
        <template #body="{ data }">
          <Button type="button" id="editPatient" outlined text size="small" icon="pi pi-file-edit" aria-haspopup="true"
            aria-controls="overlay_menu" rounded @click="popUptoggle($event, data)" />
          <Menu ref="menu" id="overlay_menu" :model="popUpitems" :popup="true" />
        </template>
      </Column>

      <Column class="" v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" style="width: 25%" sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" autofocus />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>

      <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" id="deleteBox" outlined text size="small" icon="pi pi-trash" aria-haspopup="true"
            aria-controls="overlay_menu" rounded @click="deleteRow(data)" severity="danger" />
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="showDeleteDialog" :modal="true" :closable="false">
      <h3>{{ message.title }}</h3>
      <p>{{ message.description }}</p>
      <div class="dialog-footer">
        <Button label="Nein" @click="showDeleteDialog = false" />
        <Button label="Ja" class="p-button-danger" @click="forceDelete" />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, defineAsyncComponent, markRaw, computed, reactive } from "vue";
import Tip from "@/components/Tips/Tip.vue";
import { FilterMatchMode } from "primevue/api";
import { ItemsService } from "@/service/ItemsService";
import Loading from "@/components/Loading";
import { useDialog } from "primevue/usedialog";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";

const toast = useToast();

const dialog = useDialog();

const isMobile = ref(false);
const loading = ref(true);
const lazyLoading = ref(false);
const loadLazyTimeout = ref();
const items = ref([]);
const virtualItems = ref([]);
const showDeleteDialog = ref(false);
const message = ref({});

const columns = ref([
  /*   { field: "patient_pub_id", header: "Patienten ID" }, */
  { field: "item_id", header: "Artikelnummer" },
  { field: "itemName", header: "Artikelname" },
  { field: "itemAlias", header: "Artikel Alias" },
  { field: "itemOutPrice", header: "Verkaufspreis" },
  { field: "itemInPrice", header: "Einkaufspreis" },
]);

const filters = ref({
  item_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemAlias: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemOutPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
  itemInPrice: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const ItemInsertComponent = defineAsyncComponent(() => import('@/views/ItemInsertComponent.vue'));
const DefaultFooter = defineAsyncComponent(() => import('@/views/ItemsEditFooter.vue'));
const ItemsEditHeader = defineAsyncComponent(() => import('@/views/ItemsEditHeader.vue'));

const popUpLabel = reactive({ label: "" });
let rowData = null;

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.itemName;
  menu.value.toggle(event);
};
const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: 'Ansehen/Bearbeiten',
        icon: 'pi pi-file-edit',
        command: () => {
          handleRowDoubleClick(rowData);
        },
      },
      {
        label: 'Artike Duplizieren / Kopieren',
        icon: 'pi pi-copy',
        command: () => {
          handleNewRow({}, JSON.parse(JSON.stringify(rowData)));
        },
      }
    ],
  },
]);

const deleteRow = (data) => {
  message.value.title = "Artikel Löschen?";
  message.value.description = "Möchten sie den Artikel wirklich löschen? Dieser vorgang kann nicht wieder rückgängig gemacht werden.";
  message.value.item = data;
  showDeleteDialog.value = true;
}

const forceDelete = async () => {
  const data = message.value.item;
  const itemID = data.item_id;
  showDeleteDialog.value = false;

  let url = process.env.VUE_APP_BASE_API_URL + "/products/item/" + itemID;
  await iAxios.delete(url);

  let index = items.value.indexOf(data);
  let virtualIndex = virtualItems.value.indexOf(data);

  items.value.splice(index, 1);
  virtualItems.value.splice(virtualIndex, 1);
  console.log(data);
}

const handleRowDoubleClick = (event) => {
  const data = ref(event.data ? event.data : event);

  const originalData = data.value;
  const dialogRef = dialog.open(ItemInsertComponent, {
    props: {
      header: 'Artikel Bearbeiten',
      modal: true,
      closable: false,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(ItemsEditHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          save(data.value, originalData);
          console.log("🚀 ~ file: ItemsEditView.vue:183 ~ onClose: ~ originalData:", originalData)
        } else if (buttonType == "Cancel") {
          Object.assign(data.value, originalData);
        }
      }
    }
  });
  dialogRef.data = {
    item: data
  };
}

const handleNewRow = (event, predefinedItem) => {

  const newItem = ref({
    isKKArtikel: true,
    isAnual: false,
    itemName: '',
    itemActive: true,
    itemDescription: '',
    itemInPrice: 0.0,
    itemOutPrice: 0.0,
    itemGroupList: [],
    
  });
  if (predefinedItem) {
    Object.assign(newItem.value, predefinedItem);
  }

  const dialogRef = dialog.open(ItemInsertComponent, {
    props: {
      header: predefinedItem ? 'Artikel Duplizieren' : 'Artikel Anlegen',
      modal: true,
      closable: false,
      style: {
                width: '60vw',
            }
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(ItemsEditHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          try {
            const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
            console.log(JSON.stringify(dialogRef.data.item.value));
            let response = await iAxios.post(url, dialogRef.data.item.value);
            items.value.push(response.data);
            virtualItems.value.push(response.data);
          } catch (error) {
            console.error(error);
            toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Speichern der Daten" });
          }
        }
      }
    }
  });

  dialogRef.data = {
    item: newItem
  };
}

const save = async (item, originalData) => {
  try {
    const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
    console.log(JSON.stringify(item));
    
    delete item.hasGroup;

    await iAxios.patch(url, item);
  } catch (error) {
    console.error(error);
    toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Speichern der Daten" });
    Object.assign(item, originalData);
  }
}

function isSortable(field) {
  return field === 'birthDate' || field === 'registered';
}

const loadItemsLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuelleItems = [...items.value];
    let { first, last } = event;

    //load data of required page
    const loadedItems = items.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuelleItems, [...[first, last - first], ...loadedItems]);

    virtualItems.value = virtuelleItems;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
}

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

async function werteLaden() {
  loading.value = true;
  try {
    await ItemsService.getItemsXLarge().then((data) => {
      console.log("🚀 ~ file: ItemsEditView.vue:306 ~ awaitItemsService.getItemsXLarge ~ data:", data)
      items.value = data.map((item) => ({
        ...item,
      }));  //optimierbar würde um 1-1,5 sekunden schneller laden wenn vom backend kommt
    });
    loading.value = false;
  } catch (exception) {
    console.log(exception);
  } finally {
    loading.value = false;
  }
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
}

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
})

onBeforeMount(async () => {
  await werteLaden();
})

</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
