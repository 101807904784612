// Deine footer-Variable mit der Checkbox
const footer = ``;

// Dein Tip-Array mit der aktualisierten "enable"-Eigenschaft für die Checkbox
const tips = [
  {
    tipId: 'noPatientsTip',
    title: 'Keine Patienten verfügbar!',
    contentText: 'Bitte legen Sie zunächst einen Patienten an.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editPatientTip',
    title: 'Patienten Bearbeiten!',
    contentText: 'Hier können Sie die Patientenakte<br> -Ansehen & Bearbeiten  <br>-Rechnung erstellen .',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'editItemTip',
    title: 'Artikel Bearbeiten!',
    contentText: 'Hier können Sie die Artikel<br> -Ansehen & Bearbeiten.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer,
  },
  {
    tipId: 'itemSettingsTip',
    title: 'Variante/Anzahl nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick auf "Anzahl" erreichen.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'hilfsMittelSettingsTip',
    title: 'Pflegehilfsmittel nachträglich Bearbeiten',
    contentText: 'Sie können diese Auswahl jederzeit erneut durch einen Mausklick hier erreichen.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoItemsTip',
    title: 'Aktuell sind keine Artikel verfügbar.',
    contentText: 'Bevor ein Patient angelegt werden kann, müssen Artikel vorhanden sein.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterOrAbortTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button [Weiter] betätigen oder [Abbrechen] für Abbruch.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'KlickWeiterTip',
    title: 'Abschließend',
    contentText: 'Nach Abschluss den Button > Weiter < betätigen.',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
  {
    tipId: 'NoBoxesTip',
    title: 'Auswahl Boxen',
    contentText: 'Es sind keine Boxen zusammengestellt worden. Menü Katalog->Boxen zum hinzufügen von Boxen ',
    contentIcon: 'pi-info-circle',
    image: '',
    contentFooter: footer, // Hier fügst du die Checkbox-Footer hinzu
  },
];

export default tips;