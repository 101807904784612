<template>
  <Loading v-show="loading" />
  <DynamicDialog />

  <Tip v-if="lazyLoading == false" :target="'#editPatient'" :tipId="'editPatientTip'" :placement="'right'"
    :overlay="true" />

  <DataTable v-if="items.length > 0" v-model:filters="filters" :value="virtualItems" editMode="row" scrollable scrollHeight="85vh"
    filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
    selectionMode="single" dataKey="patient_pub_id" sortField="item_id" :sortOrder="-1" class="p-datatable-sm"
    @row-dblclick="handleRowDoubleClick" :virtualScrollerOptions="{
      lazy: true,
      onLazyLoad: loadItemsLazy,
      itemSize: 46,
      delay: 300,
      showLoader: true,
      loading: lazyLoading,
      numToleratedItems: 100
    }">
    <Column headerStyle="width: 15px;" footer="" bodyStyle="width: 15px;">
      <template #header>
        <Button type="button" outlined text size="small" icon="pi pi-plus-circle" rounded @click="handleNewRow" />
      </template>
      <template #body="{ data }">
        <Button type="button" id="editPatient" outlined text size="small" icon="pi pi-file-edit" aria-haspopup="true"
          aria-controls="overlay_menu" rounded @click="editParcelConfig($event, data)" />
      </template>
    </Column>

    <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :footer="col.header"
      :showFilterMenu="false" :sortable="col.isSortable">
      <template #filter="{ filterModel, filterCallback }">
        <InputText v-if="!col.isFilterCheckbox" v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
          @keydown.enter="filterCallback()" class="p-column-filter" />
        <div v-else>
          <input type="checkbox" id="checkbox" v-model="filterModel.value" @change="filterCallback()" />
          <label for="checkbox">{{ filterModel.value ? 'Aktiv' : 'Inaktiv' }}</label>
        </div>
      </template>
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus />
      </template>
      <template #body="{ data, field }">
        <div v-if="field === 'parcelService'">
          {{ getParcelService(data) }}
        </div>
        <div v-else>
          {{ data ? "Aktiv" : "Inaktiv" }}
        </div>
      </template>
      <template #loading>
        <Skeleton width="60%" height="2rem" />
      </template>
    </Column>

    <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
      <template #body="{ data }">
        <Button type="button" id="deleteBox" outlined text size="small" icon="pi pi-trash" aria-haspopup="true"
          aria-controls="overlay_menu" rounded @click="deleteRow(data)" severity="danger" />
      </template>
    </Column>
  </DataTable>
  <div v-else>
    <Card>
      <template #title>Keine Versanddienste Konfiguriert</template>
      <template #content>
        <h3 style="color:red">- Bevor Versanddienste angezeigt werden können, müssen welche konfiguriert werden.</h3>
        <Button label="Neuen Versanddienst Konfigurieren" @click="handleNewRow()" />
      </template>
    </Card>    
  </div>

  <Dialog v-model:visible="showDeleteDialog" :modal="true" :closable="false">
      <h3>{{ message.title }}</h3>
      <p>{{ message.description }}</p>
      <div class="dialog-footer">
        <Button label="Nein" @click="showDeleteDialog = false" />
        <Button label="Ja" class="p-button-danger" @click="forceDelete" />
      </div>
    </Dialog>
</template>
<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted } from 'vue';
import { ref } from 'vue';
import { ParcelServicesService } from "@/service/ParcelServicesService";
import Loading from "@/components/Loading";
import { defineAsyncComponent } from 'vue';
import { useDialog } from 'primevue/usedialog';
import { markRaw } from 'vue';
import iAxios from '@/store/axiosInterface';
import * as calcUtils from "@/utils/calculationUtils.js";

const lazyLoading = ref(true);
const loadLazyTimeout = ref();
const loading = ref(true);
const items = ref([]);
const virtualItems = ref([]);
const dialog = useDialog();
const showDeleteDialog = ref(false);
const message = ref({});

const ParcelServiceConfig = defineAsyncComponent(() => import('@/views/ParcelServiceConfig.vue'));
const ParcelServiceConfigFooter = defineAsyncComponent(() => import('@/views/ParcelServiceConfigFooter.vue'));


const columns = ref([
  { field: "parcelService", header: "Versanddienst", isSortable: true, isFilterCheckbox: false },
  { field: "tracking", header: "Sendungsverfolgung", isSortable: false, isFilterCheckbox: true },
  { field: "printing", header: "Etikettendruck", isSortable: false, isFilterCheckbox: true },

]);

const filters = ref({
  parcelService: { value: null, matchMode: FilterMatchMode.CONTAINS },
  tracking: { value: null, matchMode: FilterMatchMode.CONTAINS },
  printing: { value: null, matchMode: FilterMatchMode.CONTAINS },
});


const loadItemsLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuelleItems = [...items.value];
    let { first, last } = event;

    //load data of required page
    const loadedItems = items.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuelleItems, [...[first, last - first], ...loadedItems]);

    virtualItems.value = virtuelleItems;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
}

const handleRowDoubleClick = (event) => {
  console.log(event);
}

const editParcelConfig = ($event, data) => {
  const dialogRef = dialog.open(ParcelServiceConfig, {
    props: {
      header: 'Versanddienstleister Konfigurieren',
      modal: true,
      closable: false,
      style: {
        width: '60vw',
      },
      editingParcelService: true,
    },
    templates: {
      footer: markRaw(ParcelServiceConfigFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/settings/parcelservice", { parcelService: data.parcelService, apiKey: dialogRef.data.parcelServiceConfig.apiKey });
        }
      }
    }
  });

  dialogRef.data = {
    parcelServiceConfig: data
  }
}

const handleNewRow = (event) => {

  const newParcelService = ref({
    parcelService: "",
    apiKey: "",
    tracking: true,
    printing: true,
  });

  const dialogRef = dialog.open(ParcelServiceConfig, {
    props: {
      header: 'Versanddienstleister Konfigurieren',
      modal: true,
      closable: false,
      style: {
        width: '60vw',
      }
    },
    templates: {
      footer: markRaw(ParcelServiceConfigFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/settings/parcelservice", { parcelService: dialogRef.data.parcelServiceConfig.value.parcelService, apiKey: dialogRef.data.parcelServiceConfig.value.apiKey });
          items.value.push(newParcelService)
          loadItemsLazy();
        }
      }
    }
  });

  dialogRef.data = {
    parcelServiceConfig: newParcelService
  }
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

async function werteLaden() {
  loading.value = true;
  try {
    await ParcelServicesService.getParcelServicesXLarge().then((data) => {
      console.log("🚀 ~ file: ItemsEditView.vue:306 ~ awaitItemsService.getItemsXLarge ~ data:", data)
      let bValue = true;
      for (var i in data) {
        items.value.push({ parcelService: i, apiKey: data[i], tracking: bValue, printing: bValue });
        bValue = !bValue;
      }
    });
    loading.value = false;
  } catch (exception) {
    console.log(exception);
  } finally {
    loading.value = false;
  }
}

const getParcelService = (parcelConfig) => {

  let parcelService = calcUtils.parcelServices.value.find(o => {
    return o.code == parcelConfig.parcelService;
  })
  if (!parcelService) {
    return parcelConfig.parcelService;
  }
  return parcelService.name;
}

const deleteRow = (parcelConfig) => {
  message.value.title = "Versanddienstleister Konfiguration Löschen?";
  message.value.description = "Möchten sie den Versanddienstleister-Konfiguration wirklich löschen? Dieser vorgang kann nicht wieder rückgängig gemacht werden.";
  message.value.parcelConfig = parcelConfig;
  showDeleteDialog.value = true;
}

const forceDelete = async () => {
  const parcelConfig = message.value.parcelConfig;
  showDeleteDialog.value = false;

  let url = process.env.VUE_APP_BASE_API_URL + "/company/settings/parcelservice/" + parcelConfig.parcelService;
  await iAxios.delete(url);

  let index = items.value.indexOf(parcelConfig);
  let virtualIndex = virtualItems.value.indexOf(parcelConfig);

  items.value.splice(index, 1);
  virtualItems.value.splice(virtualIndex, 1);
}

onBeforeMount(async() => { 
  await werteLaden();

  loadItemsLazy({first: 0, last: 0});
});
</script>