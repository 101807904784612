<template>
  <div class="card p-fluid" v-if="invoices.length > 0">
    <Loading v-show="loading" />

    <DynamicDialog />
    <div class="card">
        <DataTable :value="virtualInvoices" tableStyle="min-width: 50rem">
            <Column field="invoiceId" header="invoiceId" sortable style="width: 25%"></Column>
            <Column field="eventTime" header="eventTime" sortable style="width: 25%"></Column>
        </DataTable>
    </div>

    <DataTable v-model:filters="filters" :value="virtualInvoices" editMode="row" scrollable scrollHeight="85vh"
      filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
      selectionMode="single" dataKey="invoiceId" sortField="invoiceId" :sortOrder="-1" class="p-datatable-sm"
      @row-dblclick="openReadOnlyInvoice" :virtualScrollerOptions="{
        lazy: true,
        onLazyLoad: loadPatientsLazy,
        itemSize: 46,
        delay: 300,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100
      }">

      <Column field="invoiceId" header="Rechnungsnummer" footer="Rechnungsnummer" key="invoiceId" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #header>
          <Button type="button" outlined text size="small" icon="pi pi-plus-circle" rounded @click="handleNewRow" />
        </template>
        <template #body="{ data }">
          {{ data.invoiceId }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
      </Column>
      <Column field="eventTime" header="Erstelldatum" footer="Erstelldatum" key="eventTime" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          {{ data.eventTime }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
      </Column>
      <Column field="invoiceStatus" header="Zustand" footer="Zustand" key="invoiceStatus" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          <div class="p-inputgroup">
            <Button icon="pi pi-angle-down" :label="getActionLabel(data.invoiceStatus)" :severity="getRechnungSeverity(data.invoiceStatus)" text
              size=small rounded aria-haspopup="true" @click="popUptoggle($event, data)" aria-controls="overlay_menu"
              iconPos="right" />
          </div>
          <Menu :selectedOptionIndex="data.invoiceStatus == 'INVOICE_OPEN' ? 0 : 1" ref="menu" id="overlay_menu" :model="popUpitems" :popup="true" />
        </template>

        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" optionLabel="label" optionValue="value" placeholder="Auswählen"
            class="p-column-filter" style="min-width: 10%" :showClear="true">
            <template #option="slotProps">
              <Tag :value="slotProps.option.label" :class="slotProps.option.label" :severity="getRechnungSeverity(slotProps.option.value)"  />
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Rechnung anlegen'" severity="danger" rounded @click="openRechnung(data)" />
        </template>
      </Column>
    </DataTable>

  </div>
  <div v-else>
    <Card>
      <template #title>Keine Rechnungen vorhanden</template>
      <template #content>
        <h3 style="color:red">- Bevor Rechnungen angezeigt werden können, müssen welche in der Patientenverwaltung
          erstellt werden.</h3>
        <Button label="Zur Patientenverwaltung" @click="router.push('/patientenView')" />
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, defineAsyncComponent, markRaw, reactive, computed } from "vue";
import { FilterMatchMode } from "primevue/api";
import { InvoicesService, setInvoiceStatus } from "@/service/InvoicesService";
import Loading from "@/components/Loading";
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import { speichern } from "@/utils/invoiceUtils";

const InvoicesEditComponent = defineAsyncComponent(() => import('@/views/InvoicesEditComponent.vue'));
const DefaultFooter = defineAsyncComponent(() => import('@/views/InvoicesEditFooter.vue'));
const DefaultHeader = defineAsyncComponent(() => import('@/views/InvoicesEditHeader.vue'));

const router = useRouter();

const isMobile = ref(false);
const loading = ref(true);

const lazyLoading = ref(false);
const loadLazyTimeout = ref();

const invoices = ref([]);
const virtualInvoices = ref([]);
const dialog = useDialog();
let rowData = null;

const popUpLabel = reactive({ label: "" });

const statuses = reactive([
  {label:"Offen", value:"INVOICE_OPEN"},
  {label:"Bezahlt", value:"INVOICE_PAID"},
]);

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = 'Rechnung ' + rowData.invoiceId;
  menu.value.toggle(event);
};

const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Offen",
        icon: 'pi pi-money-bill', 
        command: () => {
          console.log("🚀 ~ file: ShippingPrepareView.vue:183 ~ rowData.invoiceId:", rowData.invoiceId)

          if (rowData && rowData.invoiceStatus != 'INVOICE_OPEN') {
            setInvoiceStatus(rowData.invoiceId, 'INVOICE_OPEN');
            rowData.invoiceStatus = 'INVOICE_OPEN';
          }
        },
      },
      {
        label: "Bezahlt",
        icon: 'pi pi-dollar',
        command: () => {
          console.log("🚀 ~ file: ShippingPrepareView.vue:183 ~ rowData.invoiceId:", rowData.invoiceId)

          if (rowData && rowData.invoiceStatus != 'INVOICE_PAID') {
            setInvoiceStatus(rowData.invoiceId, 'INVOICE_PAID');
            rowData.invoiceStatus = 'INVOICE_PAID';
          }
        },
      }
    ],
  },
]);

const filters = ref({
  invoiceId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  eventTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
  invoiceStatus: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

const handleNewRow = () => {
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: 'Rechnung',
      style: {
        width: '75vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader)
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let response = speichern(dialogRef);
          console.log(response.data);
        }
      }
    }
  });
  dialogRef.data = {
    invoice: {
      userObj: null,
      invoiceId: null,
      isDisabled: false,
      storno: false
    }
  };
}

const loadPatientsLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let tempVirtualInvoices = [...invoices.value];
    let { first, last } = event;

    //load data of required page
    const loadedPatients = invoices.value.slice(first, last);
    console.log(loadedPatients);
    //populate page of virtual cars
    Array.prototype.splice.apply(tempVirtualInvoices, [...[first, last - first], ...loadedPatients]);

    virtualInvoices.value = tempVirtualInvoices;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
};

const openReadOnlyInvoice = (event) => {
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: 'Rechnung (Schreibgeschützte Ansicht)',
      style: {
        width: '',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    }
  });
  dialogRef.data = {
    invoice: {
      userObj: null,
      invoiceId: event.data.invoiceId,
      isDisabled: true,
      storno: false
    }
  };
};

const getRechnungSeverity = (statusRechnung) => {
  switch (statusRechnung) {
    case "INVOICE_OPEN":
      return "warning";

    case "INVOICE_PAID":
      return "success";
  }
  return "info";
};

const openRechnung = (data) => {
  const dialogRef = dialog.open(InvoicesEditComponent, {
    props: {
      header: 'Storno Rechnung Erstellen',
      style: {
        width: '90vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(DefaultFooter)
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let response = speichern(dialogRef);
          console.log(response.data);
        }
      }
    }
  });
  dialogRef.data = {
    invoice: {
      userObj: null,
      invoiceId: data.invoiceId,
      isDisabled: false,
      storno: true
    }
  };
};

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
}

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
}

async function werteLaden() {
  loading.value = true;
  await
    InvoicesService.getInvoicesXLarge().then((data) => {
      if (data.invoices) {
        //  patients.value = data;
        invoices.value = data.invoices.map((invoice) => ({
          ...invoice,
          eventTime: formatDate(invoice.eventTime),
        }));
      }
    });

  loading.value = false;
}
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
