<template>
  <Dialog v-model:visible="dialogVisible" :closeOnEscape=false modal :style="{ width: '100vw', height: '100vh' }" :pt="{
    closeButton: {
      style: { left: '-5px' },
    }
  }">
    <ConfirmDialog />


    <template #header class="flex overflow-hidden">
      <div class="flex-grow-1">
        <span style="font-size: larger;
                                  font-weight: 700;
                                  color: rgb(48, 46, 46)">
          Details zu:
        </span>
        <span class="pr-5">
          <Badge :value="dialogData.patient_pub_id" size="large" severity="success"
            v-tooltip="'registriert seit:\n' + dialogData.registered">
            <i class="pi pi-user" />
            <span v-if="!dialogData.patient_pub_id">*Neuer Patient*</span><span v-else>{{ dialogData.patient_pub_id
            }}</span>
          </Badge>
        </span>

        <Dropdown v-model="dialogData.statusAntrag" optionLabel="label" optionValue="value" :options="statuses"
          :class="['p-column-filter', t(dialogData.statusAntrag)]" style="min-width: 10%;color:white!important;">
          <template #value>
            <span style="min-width: 10%;color:white">{{ t(dialogData.statusAntrag) }}</span>
          </template>
          <template #dropdownicon>
            <i class="pi pi-angle-down"></i>
          </template>
          <template #option="slotProps">
            <Tag :value="slotProps.option.label" :class="slotProps.option.label" />
          </template>
        </Dropdown>
      </div>
      <div v-if="dialogData.patient_pub_id" class="flex-grow-1 flex justify-content-end">
        <ConfirmDialog group="shipping" :pt="{ headertitle: 'mr-4' }" :closable="false" @keydown="handleDialogKeydown">
          <template #message="slotProps">
            <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
          </template>
        </ConfirmDialog>
<!--         <Button label="test" @click="patchNullTest"></Button>"
 -->        <SplitButton :loading="loadingConfirm" size="small" v-tooltip="'für Download Antrag (rechts V ) anklicken'"
          label="Speichern" @click="saveForm('patch')" icon="pi pi-check" :model="items" severity="info" outlined
          class="pr-2" :disabled="saveButton">
        </SplitButton>
      </div>
      <div v-else class="flex-grow-1 flex justify-content-end mr-4">
        <Button label="Speichern" size="small" outlined @click="saveForm('post')" icon="pi pi-check"
          :disabled="saveButton" />
      </div>

    </template>
    <div class="dialog-content">

      <!--tostmeldung position="bottom-center"-->
      <div v-if="showInsuFail">
        <Toast position="bottom-center" group="h">
          <template #message="slotProps">
            <div class="flex flex-column align-items-center" style="flex: 1">
              <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <div class="font-bold text-xl my-3">
                  {{ slotProps.message.summary }}
                </div>
              </div>
              <div class="flex gap-2">
                <img src="@/assets/MusterKrankenkassenkarte.png" />
              </div>
            </div>
          </template>
        </Toast>
      </div>
      <div v-else>
        <Toast />
      </div>
      <!--tostmeldung ende-->

      <Form @submit.prevent="saveForm">
        <TabView update:activeIndex="activeIndex" @tab-change="handleTabChange">
          <TabPanel :pt="{
            headeraction: ({ props, parent }) => ({
              class: panelClass(props, parent, index)
            })
          }">
            <template #header>
              <div class="flex align-items-center gap-2">
                <i class="pi pi-database"></i>
                <span class="font-bold white-space-nowrap">Patientendaten</span>
              </div>
            </template>
            <div class="p-card p-3">
              <div class="grid">
                <div class="col-12 md:col-6 lg:col-4">

                  <Card style="backgroundColor: var(--indigo-100)">
                    <template #title>
                      <!-- titel -->
                    </template>
                    <template #content>
                      <h2 class="p-card-title pt-1">Patientendaten</h2>
                      <div class="grid">
                        <div class="col-12 md:col-6">
                          <label for="salutation">Anrede / Titel</label>
                          <InputText class="col-12" id="salutation" v-model="dialogData.salutation" />
                        </div>
                        <div class="col-12 md:col-1"></div>
                        <div class="col-12 md:col-5">
                          <label for="birthDate">Geburtsdatum</label>
                          <div class="p-inputgroup">
                            <Calendar v-model="dialogData.birthDate" showIcon dateFormat="dd.mm.yy"
                              placeholder="01.01.1900" id="birthDate" :class="{ 'p-invalid': isInvalid('birthDate') }" />
                          </div>
                        </div>
                        <div class="col-12 md:col-6">
                          <label for="firstName">Vorname</label>
                          <InputText class="col-12" id="firstName" v-model="dialogData.firstName"
                            :class="{ 'p-invalid': isInvalid('firstName') }" />
                        </div>
                        <div class="col-12 md:col-6">
                          <label for="surName">Nachname</label>
                          <InputText class="col-12" id="surName" v-model="dialogData.surName"
                            :class="{ 'p-invalid': isInvalid('surName') }" />
                        </div>
                        <div class="col-12 md:col-9">
                          <label for="street">Straße</label>
                          <InputText class="col-12" id="street" v-model="dialogData.street"
                            :class="{ 'p-invalid': isInvalid('street') }" />
                        </div>
                        <div class="col-12 md:col-3">
                          <label for="houseNumber">Hausnr</label>
                          <InputText class="col-12" id="houseNumber" v-model="dialogData.houseNumber"
                            :class="{ 'p-invalid': isInvalid('houseNumber') }" />
                        </div>
                        <div class="col-12 md:col-3">
                          <label for="postCode">PLZ</label>
                          <InputText class="col-12" id="postCode" v-model="dialogData.postCode"
                            :class="{ 'p-invalid': isInvalid('houseNumber') }" />
                        </div>
                        <div class="col-12 md:col-9">
                          <label for="city">Stadt</label>
                          <InputText class="col-12" id="city" v-model="dialogData.city"
                            :class="{ 'p-invalid': isInvalid('houseNumber') }" />
                        </div>
                        <div class="col-12 md:col-6">
                          <label for="emailAddress">E-Mail Adresse</label>
                          <InputText class="col-12" id="emailAddress" v-model="dialogData.emailAddress"
                            :class="{ 'p-invalid': isInvalid('houseNumber') }" />
                        </div>
                        <div class="col-12 md:col-6">
                          <label for="phone">Telefon</label>
                          <InputText class="col-12" id="phone" v-model="dialogData.phone"
                            :class="{ 'p-invalid': isInvalid('houseNumber') }" />
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>

                <!-- Versicherung anfang-->
                <div class="col-12 md:col-6 lg:col-4">

                  <Card style="backgroundColor: var(--surface-100)">
                    <template #title>
                      <!-- titel -->
                    </template>
                    <template #content>
                      <h2 class="p-card-title pt-1">Versicherungsdaten</h2>
                      <div class="grid">

                        <div class="col-12">
                          <div>
                            <label for="Pflegegrad">Pflegegrad</label>
                          </div>
                          <div>
                            <Dropdown id="Pflegegrad" placeholder="Bitte Pflegegrad wählen" v-model="dialogData.careLevel"
                              :options="careLevelOptions" :class="{ 'p-invalid': isInvalid('careLevel') }" />
                          </div>
                        </div>

                        <div class="col-12">
                          <div>
                            <label for="Versicherungsart">Versicherungsart</label>
                          </div>
                          <div>
                            <Dropdown placeholder="Bitte Versicherungsart wählen" id="Versicherungsart"
                              v-model="dialogData.insuranceKind" :options="insuranceKindOptions" optionLabel="label"
                              optionValue="value" :class="{ 'p-invalid': isInvalid('insuranceKind') }" />
                          </div>
                        </div>

                        <div v-if="dialogData.insuranceKind == 'qLegalInsurance'" class="col-12">
                          <label for="healthInsurance">Versicherung</label>
                          <AutoComplete v-model="healthInsuranceField" forceSelection text="dialogData.insuranceName"
                            optionLabel="insuName" :suggestions="filteredInsurances" @complete="search"
                            @blur="chkInsField" style="width:100%" :class="{ 'p-invalid': isInvalid('insuranceName') }"
                            :pt="{
                              input: { class: 'w-full' },
                            }">
                            <template #option="{ option }">
                              <div>
                                <div>{{ option.insuName }}</div>
                              </div>
                            </template>
                          </AutoComplete>
                        </div>


                        <div v-if="dialogData.insuranceKind == 'qLegalInsurance'" class="col-12">
                          <label for="insurancePolicyNumber">Versichertennr.</label>
                          <InputText @focus="showInsuFail = true" id="insurancePolicyNumber"
                            v-model="dialogData.insurancePolicyNumber" :maxlength="10" placeholder="a123456789"
                            @input="checkInputLength" :class="{ 'p-invalid': isInvalid('insurancePolicyNumber') }"
                            class="col-12" />
                        </div>
                      </div>
                    </template>
                  </Card>
                  <!--                   <pre>{{ dialogData }}</pre> -->
                </div>
                <!-- Versicherungsbereich ende -->

                <div class="col-12 md:col-6 lg:col-4">
                  <Card style="backgroundColor: var(--purple-100)">
                    <template #title>
                      <SelectButton v-model="dialogData.isCaregivingPerson" :options="isCaregivingPersonOptions"
                        optionLabel="label" unselectable optionValue="value" aria-labelledby="basic" />
                    </template>
                    <template #content>
                      <div v-if="!dialogData.isCaregivingPerson">
                        <h2 style="margin-top:-23px" class="p-card-title">Pflegedienst</h2>
                        <div class="grid">
                          <div class="col-12">
                            <label for="nursingServiceCompany">Firmenname</label>
                            <InputText class="col-12" id="nursingServiceCompany"
                              v-model="dialogData.nursingServiceCompany"
                              :class="{ 'p-invalid': isInvalid('nursingServiceCompany') }" />
                          </div>
                          <div class="col-9">
                            <label for="nursingServiceStreet">Straße</label>
                            <InputText class="col-12" id="nursingServiceStreet" v-model="dialogData.nursingServiceStreet"
                              :class="{ 'p-invalid': isInvalid('nursingServiceStreet') }" />
                          </div>
                          <div class="col-3">
                            <label for="nursingServiceHouseNumber">Hausnr</label>
                            <InputText class="col-12" id="nursingServiceHouseNumber"
                              v-model="dialogData.nursingServiceHouseNumber"
                              :class="{ 'p-invalid': isInvalid('nursingServiceHouseNumber') }" />
                          </div>
                          <div class="col-4">
                            <label for="nursingServicePostCode">PLZ</label>
                            <InputText class="col-12" id="nursingServicePostCode"
                              v-model="dialogData.nursingServicePostCode"
                              :class="{ 'p-invalid': isInvalid('nursingServicePostCode') }" />
                          </div>
                          <div class="col-8">
                            <label for="nursingServiceCity">Stadt</label>
                            <InputText class="col-12" id="nursingServiceCity" v-model="dialogData.nursingServiceCity"
                              :class="{ 'p-invalid': isInvalid('nursingServiceCity') }" />
                          </div>
                          <div class="col-8">
                            <label for="nursingServicePhone">Telefon</label>
                            <InputText class="col-12" id="nursingServicePhone" v-model="dialogData.nursingServicePhone"
                              :class="{ 'p-invalid': isInvalid('nursingServicePhone') }" />
                          </div>
                        </div>
                      </div>

                      <!-- Pflegeperson -->
                      <div v-if="dialogData.isCaregivingPerson">
                        <h2 style="margin-top:-23px" class="p-card-title">Pflegeperson</h2>
                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <label for="caregiverSalutation">Anrede / Titel</label>
                            <InputText class="col-12" id="caregiverSalutation" v-model="dialogData.caregiverSalutation" />
                          </div>
                          <div class="col-12 md:col-6">
                          </div>
                          <div class="col-12 md:col-6">
                            <label for="caregiverFirstName">Vorname</label>
                            <InputText class="col-12" id="caregiverFirstName" v-model="dialogData.caregiverFirstName"
                              :class="{ 'p-invalid': isInvalid('caregiverFirstName') }" />
                          </div>
                          <div class="col-12 md:col-6">
                            <label for="caregiverSurName">Nachname</label>
                            <InputText class="col-12" id="caregiverSurName" v-model="dialogData.caregiverSurName"
                              :class="{ 'p-invalid': isInvalid('caregiverSurName') }" />
                          </div>
                          <div class="col-12 md:col-9">
                            <label for="caregiverStreet">Straße</label>
                            <InputText class="col-12" id="caregiverStreet" v-model="dialogData.caregiverStreet"
                              :class="{ 'p-invalid': isInvalid('caregiverStreet') }" />
                          </div>
                          <div class="col-12 md:col-3">
                            <label for="caregiverHouseNumber">Hausnr</label>
                            <InputText class="col-12" id="caregiverHouseNumber" v-model="dialogData.caregiverHouseNumber"
                              :class="{ 'p-invalid': isInvalid('caregiverHouseNumber') }" />
                          </div>
                          <div class="col-12 md:col-3">
                            <label for="caregiverPostCode">PLZ</label>
                            <InputText class="col-12" id="caregiverPostCode" v-model="dialogData.caregiverPostCode"
                              :class="{ 'p-invalid': isInvalid('caregiverPostCode') }" />
                          </div>
                          <div class="col-12 md:col-9">
                            <label for="caregiverCity">Stadt</label>
                            <InputText class="col-12" id="caregiverCity" v-model="dialogData.caregiverCity"
                              :class="{ 'p-invalid': isInvalid('caregiverCity') }" />
                          </div>
                          <div class="col-12 md:col-6">
                            <label for="caregiverEmailAddress">E-mail Adresse</label>
                            <InputText class="col-12" id="caregiverEmailAddress"
                              v-model="dialogData.caregiverEmailAddress"
                              :class="{ 'p-invalid': isInvalid('caregiverEmailAddress') }" />
                          </div>
                          <div class="col-12 md:col-6">
                            <label for="caregiverPhone">Telefon</label>
                            <InputText class="col-12" id="caregiverPhone" v-model="dialogData.caregiverPhone"
                              :class="{ 'p-invalid': isInvalid('caregiverPhone') }" />
                          </div>
                          <div class="col-12 md:col-6">
                            <label for="caregiverRelationship">Verwandschaftsverhältnis</label>
                            <InputText class="col-12" id="caregiverRelationship"
                              v-model="dialogData.caregiverRelationship" />
                          </div>
                        </div>
                      </div>
                    </template>
                  </Card>
                  <p></p>

                </div>

              </div>
            </div>
          </TabPanel>

          <!-- Hilfmittel liste -->
          <TabPanel :pt="{
            headeraction: ({ props, parent }) => ({
              class: panelClass(props, parent, index)
            })
          }">
            <template #header>
              <div class="flex align-items-center gap-2">
                <i class="pi pi-box"></i>
                <span class="font-bold white-space-nowrap">Pflegehilfsmittel</span>
              </div>
            </template>

            <div v-if="offerAnualItem" class="flex align-items-center">
              <InputSwitch v-model="isAnualItem" @change="chkAndAnual"></InputSwitch>
              <span class="ml-2">Patient wünscht jährliche Einmal-Zusendung von:
                <span v-tooltip="'Einstellbar unter Katalog->erweitert'"><strong>
                  {{
                  dialogData?.anualItem?.itemName }}</strong>
                </span>
              </span>
            </div>

            <p></p>
            <Menu ref="popupMenu" id="overlay_popupMenu" :model="popupMenuItems" :popup="true" />
            <Card unstyled style="backgroundColor: var(--yellow-100)">
              <template #content>
                <div class="">
                  <div v-if="!dialogData.patbox_setup.boxtype">
                    <Button icon="pi pi-box" style="width: 10em;" label="Box hinzufügen" :outlined="!showBoxUpdate"
                      @click="{ showBoxUpdate = true; showItemUpdate = false }" />
                    <Button icon="pi pi-inbox" style="width: 10em; margin-left: 5px;" label="Artikel hinzufügen"
                      :outlined="!showItemUpdate" @click="{ showItemUpdate = true; showBoxUpdate = false }" />
                  </div>
                  <div v-if="!showBoxUpdate && !showItemUpdate && dialogData.patbox_setup.boxtype" class="">
                    <OrderList v-model="dialogData.patbox_setup.selectedBox.productItemList"
                      :value="dialogData.patbox_setup.selectedBox.productItemList" listStyle="height:auto"
                      dataKey="item_id" :pt="{
                        list: { style: { height: 'auto' } },
                        controls: {
                          style: { display: 'none' }
                        },
                        //header: { style: {padding: '0.25rem'} }
                      }">
                      <template #header>
                        <div class="flex flex-wrap justify-content-between align-items-center" style="margin-top: -20px;">
                          <div class="text-left flex-grow-1">
                            {{ dialogData.patbox_setup.selectedBox.productName }}
                          </div>

                          <div class="text-right">
                            <Button id="hilfsMittelSettings" type="button" aria-haspopup="true" text
                              aria-controls="overlay_menu" icon="pi pi-cog" rounded aria-label="Favorite"
                              @click="changeBox" />
                          </div>
                        </div>
                        <ProgressbarPro :optimalValue="40" :wert="totalValue" />

                        <div class="text-right">
                          <Tip v-if="showHilfsmittelInfo" :visible="showHilfsmittelInfo" :target="'#hilfsMittelSettings'"
                            :tipId="'hilfsMittelSettingsTip'" :placement="'right'" :overlay="true" />
                        </div>

                      </template>
                      <template #item="data">
                        <div class="flex flex-wrap p-2  gap-3">
                          <img class="w-4rem h-4rem shadow-2 flex-shrink-0 border-round"
                            :src="data.item.itemImageUrl != null && data.item.itemImageUrl !== '' ? data.item.itemImageUrl : require('@/assets/no-image.jpg')"
                            :alt="data.item.itemName" />
                          <div class="flex-1 flex flex-column gap-2">
                            <span class="font-bold">{{ data.item.itemName }}</span>
                            <div class="flex  gap-2">
                              <i class="pi pi-tag text-sm"></i>
                              <Tag severity="info">Anzahl in Box: {{ data.item.quantity }}</Tag>

                              <span v-if="data.item.hasGroup == true">
                                <Tag severity="success">Einzel-VK: {{
                                  formatPrice(data.item.itemGroupList[0].selectedVariant.variantOutPrice) }}</Tag>&nbsp;
                                <Tag severity="warning">{{ data.item.itemGroupList[0].groupName }}: {{
                                  data.item.itemGroupList[0].selectedVariant.variantValue }}</Tag>
                              </span>
                              <span v-else>
                                <Tag severity="success">Einzel-VK: {{ formatPrice(data.item.itemOutPrice) }}</Tag>
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </OrderList>
                  </div>
                  <!--                   <div v-if="showBoxUpdate" class="">
 -->

                  <!--                   <DynamicDialog />
 --> <!-- 
                    <Boxes @box-selected="handleBoxSelected">
                      <Button label="Abbrechen" icon="pi pi-cancel" @click="showBoxUpdate = false" />
                    </Boxes> -->



                  <!--                   </div>
 -->
                  <!--                   <div v-if="showItemUpdate" class="">
                    <Items @items-selected="handleItemsSelected">
                      <Button label="Abbrechen" icon="pi pi-cancel" @click="showItemUpdate = false" />
                    </Items>
                  </div> -->
                </div>
              </template>
            </Card>
          </TabPanel>


          <TabPanel :pt="{
            headeraction: ({ props, parent }) => ({
              class: panelClass(props, parent, index)
            })
          }">
            <template #header>
              <div class="flex align-items-center gap-2">
                <i class="pi pi-list"></i>
                <span class="font-bold white-space-nowrap">Weiteres</span>
              </div>
            </template>
            <div class="grid">
              <div class="col-12 md:col-6 lg:col-4">
                <Card style="backgroundColor: var(--yellow-100)">
                  <template #title> Lieferung an:
                    <Dropdown v-model="dialogData.whereToSendBox" :options="deliverOptions" optionLabel="label"
                      optionValue="value" />
                  </template>
                  <template #content>
                    <div v-if="dialogData.whereToSendBox === 'qDeliverPackstation'">
                      <h2 class="p-card-title pt-1">Packstation</h2>
                      <div class="p-fluid">
                        <div class="p-field pb-1">
                          <label for="packstationPostNumber">Postnummer</label>
                          <InputText id="packstationPostNumber" v-model="dialogData.packstationPostNumber" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="packstationNumber">Packstation Nummer</label>
                          <InputText id="packstationNumber" v-model="dialogData.packstationNumber" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="packstationPostCode">Packstation PLZ</label>
                          <InputText id="packstationPostCode" v-model="dialogData.packstationPostCode" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="packstationCity">Packstation Ort</label>
                          <InputText id="packstationCity" v-model="dialogData.packstationCity" />
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
              <div class="col-12 md:col-6 lg:col-4">
                <Card style="backgroundColor: var(--highlight-bg)">

                  <template #title>
                    <div class="p-fluid gap-2" style="font-size:smaller">
                      <div class="flex align-items-left gap-2">
                        <InputSwitch name="isDifferentInvoicePostAddress"
                          v-model="dialogData.isDifferentInvoicePostAddress" />
                        <label for="isDifferentInvoicePostAddress" class="ml-2">
                          abweichende Rechnungsadresse
                        </label>
                      </div>
                      <div class="flex align-items-left gap-2">
                        <InputSwitch v-model="dialogData.isTerminateOldContract" />
                        <label for="isTerminateOldContract" class="ml-2">
                          ehemaliger Pflegehilfsmittelzulieferer</label>
                      </div>
                    </div>
                  </template>
                  <template #content>

                    <!-- 
            <div class="formgroup-inline">
            </div> -->

                    <div v-if="dialogData.isTerminateOldContract">
                      <h2 class="p-card-title">
                        Ehemaliger Pflegehilfsmittel-Zulieferer
                      </h2>
                      <div class="p-fluid">
                        <div class="p-field pb-1">
                          <label for="toTerminateCompanyName">Firmenname</label>
                          <InputText id="toTerminateCompanyName" v-model="dialogData.toTerminateCompanyName" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminateStreet">Straße</label>
                          <InputText id="toTerminateStreet" v-model="dialogData.toTerminateStreet" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminateHouseNumber">Hausnr</label>
                          <InputText id="toTerminateHouseNumber" v-model="dialogData.toTerminateHouseNumber" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminatePostCode">Postleitzahl</label>
                          <InputText id="toTerminatePostCode" v-model="dialogData.toTerminatePostCode" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminateCity">Stadt</label>
                          <InputText id="toTerminateCity" v-model="dialogData.toTerminateCity" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminateEmailAdress">E-mail Adresse</label>
                          <InputText id="toTerminateEmailAdress" v-model="dialogData.toTerminateEmailAdress" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="toTerminateSendByeEmail">per Emailservice</label>
                          <InputText disabled id="toTerminateSendByeEmail" v-model="dialogData.toTerminateSendByeEmail" />
                        </div>
                      </div>
                    </div>

                    <div v-if="dialogData.isDifferentInvoicePostAddress">
                      <h2 class="p-card-title">Rechnungsadresse</h2>
                      <div class="p-fluid">
                        <div class="p-field pb-1">
                          <label for="invoiceSendToFirstName">Vorname(oder Firma)</label>
                          <InputText id="invoiceSendToFirstName" v-model="dialogData.invoiceSendToFirstName" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="invoiceSendToLastName">Nachname(oder Firmenzusatz)</label>
                          <InputText id="invoiceSendToLastName" v-model="dialogData.invoiceSendToLastName" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="invoiceSendToStreet">Straße</label>
                          <InputText id="invoiceSendToStreet" v-model="dialogData.invoiceSendToStreet" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="invoiceSendToHouseNumber">Hausnr</label>
                          <InputText id="invoiceSendToHouseNumber" v-model="dialogData.invoiceSendToHouseNumber" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="invoiceSendToPostCode">Postleitzahl</label>
                          <InputText id="invoiceSendToPostCode" v-model="dialogData.invoiceSendToPostCode" />
                        </div>
                        <div class="p-field pb-1">
                          <label for="invoiceSendToPostCity">Stadt</label>
                          <InputText id="invoiceSendToPostCity" v-model="dialogData.invoiceSendToPostCity" />
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Form>

    </div>
  </Dialog>
</template>
<script setup>
import { reactive, ref, onMounted, onUpdated, getCurrentInstance, defineAsyncComponent, markRaw, watch, computed, watchEffect } from "vue";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import download from 'downloadjs';
import { useI18n } from "vue-i18n";
import { PatientValidator } from "@/validation/PatientValidator";
import { useConfirm } from "primevue/useconfirm";
import ProgressbarPro from "@/components/ProgressbarPro.vue";
import { useDialog } from "primevue/usedialog";
import Boxes from '@/components/Boxes.vue';
import Items from "@/components/Items.vue";


const UploadFiles = defineAsyncComponent(() => import("@/components/UploadFile.vue"));
const BoxesFooter = defineAsyncComponent(() => import('@/components/BoxesFooter.vue'));
const ItemsFooter = defineAsyncComponent(() => import('@/components/ItemsFooter.vue'));
const ItemsHeader = defineAsyncComponent(() => import('@/components/ItemsHeader.vue'));

const toast = useToast();
const confirm = useConfirm();
const saveButton = ref(false);
const showInsuFail = ref(false);
const { t } = useI18n();
const { emit } = getCurrentInstance();
const offerAnualItem = ref(false);

//const { value, errorMessage } = useField('value', validateField);
const store = userStore();
const careLevelOptions = reactive(["1", "2", "3", "4", "5", "6"]);
const dialog = useDialog();


const insuranceKindOptions = reactive([
  { label: 'Gesetzlich Versichert', value: 'qLegalInsurance' },
  { label: 'Privat Versichert', value: 'qPrivatInsurance' },
  { label: 'Beihilfeberechtigt', value: 'qEligibility' },
  { label: 'Ortsamt/ Sozialamt', value: 'qSocialWelfareOffice' },
]);

const deliverOptions = reactive([
  { label: 'Patient', value: 'qDeliverInsurencedPerson' },
  { label: 'Pflegedienst', value: 'qDeliverNursingService' },
  { label: 'Pflegeperson', value: 'qDeliverCarePerson' },
  { label: 'Packstation', value: 'qDeliverPackstation' },
]);

const dialogVisible = ref(false);
const totalValue = ref(0);

const showHilfsmittelInfo = ref(false);
const props = defineProps({
  dataForDialog: Object,
  initialize: false,
});

const healthInsuranceField = ref("");
const healthInsuranceRollBack = ref("")
const formatPrice = (value) => {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}

const resetDialogData = reactive({
  patient_pub_id: "",

  registered: "",
  registered_us: "",
  statusAntrag: "REGISTERED",

  salutation: "",
  firstName: "",
  surName: "",
  street: "",
  houseNumber: "",
  postCode: "",
  city: "",
  birthDate: "",
  birthDate_us: "",
  emailAddress: "",
  phone: "",
  careLevel: "",
  careType: "qNursingService",

  insuranceKind: "",
  insuranceName: "",
  insurancePolicyNumber: "",
  healthInsurance: healthInsuranceField,

  isCaregivingPerson: false,
  caregiverSalutation: "",
  caregiverFirstName: "",
  caregiverSurName: "",
  caregiverStreet: "",
  caregiverHouseNumber: "",
  caregiverPostCode: "",
  caregiverCity: "",
  caregiverEmailAddress: "",
  caregiverPhone: "",
  caregiverRelationship: "",

  isDifferentInvoicePostAddress: false,
  invoiceSendToFirstName: "",
  invoiceSendToLastName: "",
  invoiceSendToStreet: "",
  invoiceSendToHouseNumber: "",
  invoiceSendToPostCode: "",
  invoiceSendToPostCity: "",

  packstationCity: "",
  packstationNumber: "",
  packstationPostCode: "",
  packstationPostNumber: "",

  nursingServiceCompany: "",
  nursingServiceStreet: "",
  nursingServiceHouseNumber: "",
  nursingServicePostCode: "",
  nursingServiceCity: "",
  nursingServicePhone: "",

  isSendDocsWithEmailForSign: false,
  isPrintedManuell: false,
  signedFlag: false,

  isTerminateOldContract: false,
  toTerminateCompanyName: "",
  toTerminateStreet: "",
  toTerminateHouseNumber: "",
  toTerminatePostCode: "",
  toTerminateCity: "",
  toTerminateEmailAdress: "",
  toTerminateSendByeEmail: "",
  whereToSendBox: "qDeliverCarePerson",

  patbox_setup: {
    boxtype: null,
    selectedBox: {
      productItemList: []  // Vordefiniert als leeres Array
    },
  },
  anualItem: null,
});

const dialogData = reactive({
  patient_pub_id: "",

  registered: "",
  registered_us: "",
  statusAntrag: "REGISTERED",

  salutation: "",
  firstName: "",
  surName: "",
  street: "",
  houseNumber: "",
  postCode: "",
  city: "",
  birthDate: "",
  birthDate_us: "",
  emailAddress: "",
  phone: "",
  careLevel: "",
  careType: "qNursingService",

  insuranceKind: "",
  insuranceName: "",
  insurancePolicyNumber: "",
  healthInsurance: healthInsuranceField,

  isCaregivingPerson: false,
  caregiverSalutation: "",
  caregiverFirstName: "",
  caregiverSurName: "",
  caregiverStreet: "",
  caregiverHouseNumber: "",
  caregiverPostCode: "",
  caregiverCity: "",
  caregiverEmailAddress: "",
  caregiverPhone: "",
  caregiverRelationship: "",

  isDifferentInvoicePostAddress: false,
  invoiceSendToFirstName: "",
  invoiceSendToLastName: "",
  invoiceSendToStreet: "",
  invoiceSendToHouseNumber: "",
  invoiceSendToPostCode: "",
  invoiceSendToPostCity: "",

  packstationCity: "",
  packstationNumber: "",
  packstationPostCode: "",
  packstationPostNumber: "",

  nursingServiceCompany: "",
  nursingServiceStreet: "",
  nursingServiceHouseNumber: "",
  nursingServicePostCode: "",
  nursingServiceCity: "",
  nursingServicePhone: "",

  isSendDocsWithEmailForSign: false,
  isPrintedManuell: false,
  signedFlag: false,

  isTerminateOldContract: false,
  toTerminateCompanyName: "",
  toTerminateStreet: "",
  toTerminateHouseNumber: "",
  toTerminatePostCode: "",
  toTerminateCity: "",
  toTerminateEmailAdress: "",
  toTerminateSendByeEmail: "",
  whereToSendBox: "qDeliverCarePerson",

  patbox_setup: {
    boxtype: null,
    selectedBox: {
      productItemList: []  // Vordefiniert als leeres Array
    }
  },
  anualItem: null,
});

const offeredAnualItem = ref(); //für den allgmeinen AnualItem

const isAnualItem = ref(false);

watchEffect(() => {
  console.log("🚀 ~ file: PatientenEditDialog.vue:823 ~ isAnualItem ~ dialogData:", dialogData);
  if (dialogData.anualItem == null) {
    console.log("null", (dialogData.anualItem != null));
  } else {
    console.log("nicht null", (dialogData.anualItem != null));
  }
  isAnualItem.value = dialogData.anualItem != null && !dialogData.anualItem?.reset;
});

const chkAndAnual = () => {
  const nulldata={'reset':true};
  if (!isAnualItem.value) { // Achte auf .value
    dialogData.anualItem = nulldata;
    console.log("🚀 ~ file: PatientenEditDialog.vue:840 ~ chkAndAnual ~ dialogData:", dialogData)
  } else {
    dialogData.anualItem = offeredAnualItem.value;
    console.log("🚀 ~ file: PatientenEditDialog.vue:842 ~ chkAndAnual ~ dialogData:", dialogData)
  }
}


const isOfferAnualItem = async () => {
  try {
    const response = await store.sendToEp("/products/items-anual");
    offeredAnualItem.value = response.data.find(item => item.anualItemActive) || { value: null };
    offerAnualItem.value = (response.data.length > 0) && (offeredAnualItem.value!=null);
  } catch (error) {
    console.error(error);
  }
}

/* const patchNullTest =()=>{
  const nulldata={'reset':true};
  dialogData.anualItem = nulldata;
  let url = "/company/patient-details/" + dialogData.patientUid;
  const response = store.sendToEp(url, dialogData, "patch", null, "");
}
 */
function handleTabChange(event) {
  if (event.index === 1) {
    showHilfsmittelInfo.value = true;
  }
}

const calculateTotalValue = () => {
  const items = dialogData.patbox_setup.selectedBox.productItemList;
  let sum = 0;
  for (const item of items) {
    if (item.hasGroup) {
      sum += item.quantity * item.itemGroupList[0].selectedVariant.variantOutPrice;
    } else {
      sum += item.quantity * item.itemOutPrice;
    }
  }
  totalValue.value = sum;
  return sum;
};



const statuses = reactive([
  { label: t("REGISTERED"), value: "REGISTERED" },
  { label: t("QUALIFIED"), value: "QUALIFIED", },
  { label: t("PAUSED"), value: "PAUSED" },
  { label: t("CANCELED"), value: "CANCELED" },
  { label: t("REJECTED"), value: "REJECTED" },
  { label: t("TERMINATED"), value: "TERMINATED" },
  { label: t("DELETED"), value: "DELETED" },
]);

const changeBox = (event) => {
  popupMenu.value.toggle(event);
}

let responsePatient = reactive({});
const loadingConfirm = ref(false);

const showBoxUpdate = ref(false);
const showItemUpdate = ref(false);


const popupMenu = ref();
const popupMenuItems = ref([
  {
    label: 'Optionen',
    items: [
      {
        label: 'Box-Bearbeiten/-Hinzufügen',
        icon: 'pi pi-box',
        command: () => {
          openBoxUpdateDialog();
          //showBoxUpdate.value = true;
          //showItemUpdate.value = false;
          //saveButton.value = true;
        }
      },
      {
        label: 'Artikel-Bearbeiten/-Hinzufügen',
        icon: 'pi pi-list',
        command: () => {
          openItemUpdateDialog();
          //showItemUpdate.value = true;
          //showBoxUpdate.value = false;
          //saveButton.value = true;
        }
      }
    ]
  },
]);

const items = ref([
  {
    label: 'Patient Löschen',
    icon: 'pi pi-trash',
    command: () => {
      confirmDelete();
    }
  },
  {
    label: 'Download Antrag',
    icon: 'pi pi-cloud-download',
    command: () => {
      responsePatient.kind = 'antrag-kk';
      downloadPDF();
    }
  },
  {
    label: 'Download Kündigung',
    icon: 'pi pi-cloud-download',
    command: () => {
      responsePatient.kind = 'terminate-contract';
      if (responsePatient.isTerminateOldContract === true) downloadPDF(); else {
        toast.add({
          severity: "error",
          summary: "Keine Kündigung definiert",
          detail: "Es muss vorher eine Kündigung ausgewählt werden, und daten befüllt werden.",
          life: 2000,
        });
      }
    }
  },
  {
    label: 'Upload Antrag',
    icon: 'pi pi-cloud-upload',
    command: () => {uploadFiles();
/*       toast.add({
        severity: "error",
        summary: "Lizenz nicht vorhanden",
        detail: "Die Funktion ist nur in Premium Plus vorhanden",
        life: 2000,
      }); */
    }
  }
])

const panelClass = (props, parent, index) => {
  return [
    {
      'bg-primary': parent.state.d_activeIndex === index
    }
  ];
};

const uploadFiles=()=>{
  dialog.open(UploadFiles, {
    props: {
      closable: true,
      header: '',
      modal: true,
    },
    templates: {
    },
    onClose: (options) => {
/*       const selectedBox = options.data.selectedBox;
      const buttonType = options.data.buttonType;
      if (buttonType == "apply") {
        handleBoxSelected(selectedBox.value);
        calculateTotalValue();
      } */
    },
  });
};

//const isCaregivingPersonValue = (dialogData.isCaregivingPerson == true ? ref('Pflegedienst') : ref('Pflegeperson'));
const isCaregivingPersonOptions = [
  { label: 'Pflegeperson', value: true },
  { label: 'Pflegedienst', value: false }
];

watch(() => dialogData.isCaregivingPerson, (newVal) => {
  dialogData.careType = newVal ? "qCarePerson" : "qNursingService";
});

/* function deliverChange() {
  dialogData.isPackstation = (dialogData.whereToSendBox === 'qPackstation')
} */

const handleBoxSelected = (selectedBox) => {
  dialogData.patbox_setup.selectedBox = selectedBox;
  dialogData.patbox_setup.boxtype = "predefined";
  showBoxUpdate.value = false;
  //saveButton.value = false;
  toast.add({
    severity: "info",
    summary: "Box Auswahl erfolgreich",
    detail: "",
    life: 1500,
  });
};

const handleItemsSelected = (selectedItems) => {
  dialogData.patbox_setup.boxtype = "custom";
  dialogData.patbox_setup.boxImageUrl = null;
  dialogData.patbox_setup.company_id = store.userId;
  dialogData.patbox_setup.numberOfBoxes = 0;
  dialogData.patbox_setup.numberOfItems = selectedItems.length;
  dialogData.patbox_setup.options = "PUBLIC";
  dialogData.patbox_setup.productActive = true;
  dialogData.patbox_setup.selectedBox.productItemList = selectedItems;
  dialogData.patbox_setup.productName = "selbst Konfiguriert";
  dialogData.patbox_setup.productValue = 0;
  dialogData.patbox_setup.product_id = 0;
  dialogData.patbox_setup.selectedSanityItems = selectedItems;
  showItemUpdate.value = false;

  toast.add({
    severity: "info",
    summary: "Artikel Auswahl erfolgreich",
    detail: "",
    life: 2000,
  });
};

const confirmDelete = () => {
  confirm.require({
    message: 'Möchten Sei diesen Patienten wirklick löschen?',
    header: 'Bestätige Löschen',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    acceptLabel: 'Ja',
    rejectLabel: 'Nein',
    defaultFocus: 'reject',
    accept: () => {
      deletePatient();
      toast.add({ severity: 'info', summary: 'Löschen Bestätigt', detail: 'Patient gelöscht', life: 3000 });
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Abgebrochen', detail: 'Löschen abgebrochen', life: 3000 });
    }
  });
};

const closeDialog = () => {
  emit('closeDialog');
};

const deletePatient = async () => {
  const data = responsePatient;
  store.deleteFromEp("/company/patient/" + data.patientUid);
  emit('delete-Patient', data.patientUid);
  dialogVisible.value = false;
  closeDialog();
}

const downloadPDF = async () => {
  const data = responsePatient;
  loadingConfirm.value = true;
  let fileName = data.kind == "antrag-kk" ? "Antrag-kk_" + data.firstName + "," + data.surName + ".pdf" : "Kuendigung_" + data.firstName + "," + data.surName + ".pdf"
  await axios
    .get(
      "https://stagingapi.sanitybox.de/api/patient/" + data.kind + "/" + data.companyId + "/" + data.patientUid,
      {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: 'blob'
      }
    )
    .then((response) => {
      const content = response.headers['content-type'];
      download(response.data, fileName, content);
    })
    .catch((error) => {
      console.error(error);
    });
  loadingConfirm.value = false;
};

const showInvalidInsuNrToast = () => {
  toast.removeAllGroups();
  toast.add({
    severity: "error",
    summary: "Versichertennummer",
    detail: "",
    life: 2000,
  });
};
const validationErrors = ref({ insurancePolicyNumber: false });

const checkInputLength = () => {
  const { insurancePolicyNumber } = dialogData;
  const isValid = /^[A-Za-z](\d*)$/.test(insurancePolicyNumber);

  if (
    insurancePolicyNumber.length > 10 ||
    (!isValid &&
      insurancePolicyNumber.length > 0 &&
      insurancePolicyNumber.value != "")
  ) {
    validationErrors.value.insurancePolicyNumber = true;
    showInvalidInsuNrToast();
  } else {
    validationErrors.value.insurancePolicyNumber = false;
  }
};

const invalidFields = reactive({});

// Manuelle Überprüfung der Feldgültigkeit
function isInvalid(fieldName) {
  return invalidFields[fieldName] && invalidFields[fieldName].length > 0;
}

async function checkFormValidity() {
  //reset:alle elemente des Object löschen
  Object.keys(invalidFields).forEach((key) => {
    delete invalidFields[key];
  });

  try {
    PatientValidator.validateSync(dialogData, {
      abortEarly: false,
    });
  } catch (error) {
    error.inner.forEach((fieldError) => {
      invalidFields[fieldError.path] = fieldError.message;
    });
  }
}

watch(dialogData, async () => {
  await checkFormValidity();
}, { deep: true });

const handleDialogKeydown = (event) => {
  // Überprüfen Sie, ob die Escape-Taste gedrückt wurde
  if (event.key === 'Escape') {
    event.preventDefault(); // Verhindern Sie das Schließen des Dialogs über die Escape-Taste
  }
};

const saveData = async (saveKind) => {
  try {
    // Daten vom Backend abrufen
    let url = "/company/patient-details/" + dialogData.patientUid;
    let epKind = "";
    if (saveKind == 'post') {
      url = "/company/create-user";
      epKind = 'company';
    }

    const response = await store.sendToEp(url, dialogData, saveKind, null, epKind);
    healthInsuranceRollBack.value = healthInsuranceField.value; // zum setzen der temp Versicherungsfeld, ist nötig damit der alte werte bei ungültiger eingabe nicht geladen wird.
    const data = props.dataForDialog;
    data.statusAntrag = dialogData.statusAntrag;
    data.firstName = dialogData.firstName;
    data.lastName = dialogData.lastName;
    data.postCode = dialogData.postCode;
    data.birthDate = formatDate(dialogData.birthDate);
    data.patbox_setup = dialogData.patbox_setup;
    toast.add({
      severity: "success",
      summary: "Speichern",
      detail: "Daten erfolgreich gespeichert.",
      life: 2000,
    });
    dialogVisible.value = false;
    closeDialog(); //DEBUG 
  } catch (error) {
    console.log(error);
  }
}

const saveForm = async (saveKind) => {
  console.log("🚀 ~ file: PatientenEditDialog.vue:1186 ~ saveData ~ dialogData:", dialogData)

  const errorCount = Object.keys(invalidFields).length;

  if (errorCount > 0) {
    toast.removeAllGroups();
    Object.entries(invalidFields).forEach(([fieldName, errors]) => {
      const translatedField = t(fieldName);
      const errorMessage = t(errors);

      toast.add({
        severity: "error",
        summary: t("pleaseFillField"),
        detail: `${translatedField}: ${errorMessage}`,
        life: 4000,
      });
    });
    return;
  }
  try {
    if (!dialogData.hasOngoingShipping == true) {
      saveData(saveKind);
    } else {
      if (dialogData.hasOngoingShipping == true) {
        confirm.require({
          group: 'shipping',
          header: 'Versand überschreiben',
          message: 'Sollen die bestehenden Sendungen für diesen Patienten, die noch nicht versendet wurden, mit den aktuellen Änderungen aktualisiert werden?',
          icon: 'pi pi-question-circle',
          acceptLabel: 'Ja',
          acceptIcon: 'pi pi-check',
          rejectLabel: 'Nein',
          rejectIcon: 'pi pi-times',
          accept: () => {
            dialogData.shippingOverwrite = true;
            toast.add({ severity: 'info', summary: 'Akzeptiert', detail: 'Überschreiben der aktuellen Sendungen akzeptiert.', life: 3000 });
            saveData(saveKind);
          },
          reject: () => {
            dialogData.shippingOverwrite = false;
            toast.add({ severity: 'error', summary: 'Abbruch', detail: 'Überschreiben der aktuellen Sendungen übersprungen', life: 3000 });
            saveData(saveKind);
          }
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
/* Für Autocomplete */
const filteredInsurances = ref([]);
const insuranceDataRef = ref(null);

/* Für die Filterung und suche für Autocomlete */
const search = (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredInsurances.value =
        healthInsuranceField.value?.insuranceList ||
        insuranceDataRef.value?.insuranceList ||
        [];
    } else {
      filteredInsurances.value =
        insuranceDataRef.value?.insuranceList?.filter((insurance) => {
          dialogData.insuranceName = insurance.insuName;
          return insurance.insuName
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        }) || [];
    }
  }, 250);
};

const chkInsField = () => {
  if (healthInsuranceField._value == null) {
    healthInsuranceField.value = healthInsuranceRollBack.value;
  }
}


onMounted(async () => {
  await checkFormValidity();
  window.scrollTo({
    top: 0,
    behavior: "auto", // Scrollt sofort nach oben, ohne Animation
  });
  try {
    const insuranceData = await store.sendToEp("/insurance-all", null, null, "data", "patient"); //await getAxiosDataWithModel(url, InsuranceModel);
    filteredInsurances.value = insuranceData.insuranceList;
    // Speichere die insuranceData in einer Ref-Variable, um später darauf zugreifen zu können
    insuranceDataRef.value = insuranceData;
    isOfferAnualItem();
  } catch (error) {
  }
});

onUpdated(async () => {
  if (props.initialize) {
    const data = props.dataForDialog;
    try {
      // Daten vom Backend abrufen
      let url = "/company/patient-details/" + data.patientUid;
      const response = await store.sendToEp(url);
      const responseData = await response.data;
      responsePatient = responseData.patient;
      healthInsuranceField.value = responsePatient.insuranceName;
      healthInsuranceRollBack.value = responsePatient.insuranceName;

      // Dialog-Daten setzen
      Object.assign(dialogData, responsePatient);
      dialogData.isCaregivingPerson =
        responsePatient.careType === "qCarePerson";
      calculateTotalValue();
    } catch (error) {
      // Fehlerbehandlung bei der Backend-Anfrage
      console.error(error);
    }
  } else {
    Object.assign(dialogData, resetDialogData);
  }
  showBoxUpdate.value = false;
  if (dialogVisible.value == false) {
    saveButton.value = false;
  }
});


const openBoxUpdateDialog = () => {
  dialog.open(Boxes, {
    props: {
      closable: false,
      header: 'Box Auswahl',
      modal: true,
    },
    templates: {
      footer: markRaw(BoxesFooter)
    },
    onClose: (options) => {
      const selectedBox = options.data.selectedBox;
      const buttonType = options.data.buttonType;
      if (buttonType == "apply") {
        handleBoxSelected(selectedBox.value);
        calculateTotalValue();
      }
    },
  });
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const openItemUpdateDialog = () => {
  dialog.open(Items, {
    props: {
      closable: false,
      modal: true,
      /*       style: {
              width: '72vw',
            },
            breakpoints: {
              '960px': '55vw',
              '640px': '75vw',
            }, */
    },
    data: {
      currentItems: dialogData.patbox_setup.selectedBox.productItemList,
      pickListe: Object,
    },
    templates: {
      footer: markRaw(ItemsFooter),
      header: markRaw(ItemsHeader),
    },
    onClose: (options) => {
      console.log("🚀 ~ file: PatientenEditDialog.vue:1281 ~ openItemUpdateDialog ~ options:", options)
      const pickListe = options.data.pickListe;
      const buttonType = options.data.buttonType;
      if (buttonType == "apply") {
        handleItemsSelected(pickListe.value[1]); //pickListe.value[1] targetliste(productitemlist) von Artikel-Auswahl
        calculateTotalValue();
      }
    },
  });
};
</script>
<style scoped>
.p-tabview .p-tabview-panels {
  padding: 0.25rem;
}

.Registriert {
  background: #f2d066 !important;
}

.Pausiert {
  background: #35c4dc !important;
}

.Abgebrochen {
  background: #8183f4 !important;
}

.Abgelehnt {
  background: #ff6259 !important;
}

.Beendet {
  background: #dab6fc !important;
}

.Gelöscht {
  background: #64748b !important;
}

.Akzeptiert {
  background: #22c55e !important;
}
</style>