import { userStore } from "@/store/userStore";
import { reactive } from "vue";

export const ParcelServicesService = {
  async getData() {
    const store = userStore();
    const pat = await store.sendToEp("/company/settings");
    console.log("🚀 ~ file: ParcelServicesService.js:7 ~ getData ~ pat:", pat)
    
    const data = await reactive(pat.data.parcelConfig);
    return data;
  },

  getParcelServicesSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  async getParcelServicesXLarge() {
    return Promise.resolve(this.getData());
  },

};
