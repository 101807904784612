import { userStore } from "@/store/userStore";

export const getBoxes = (data) => {
  return data;
};

export const BoxesService = {
  async getData() {
    const store = userStore();
    const response = await store.sendToEp("/products/boxes");
    const data = await response.data;
    //data.numberOfPatients = data.numberOfPatients; //TODO zu letzt eingefügt denk daran wenn Probleme 29.06.15:53
    //console.log("🚀 ~ file: PatientenService.js:8 ~ getData ~ data:", data)
    return data;
  },

  getBoxesSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getBoxesMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async getBoxesXLarge() {
    return Promise.resolve(this.getData());
  },

};
