<template>
  <div>
    <Toast></Toast>

    <Card>
      <template #title class="p-card">
        <div class="flex justify-content-between flex-wrap">
          <div
            v-if="store.firstLogin === false"
            class="flex align-items-left w-4rem h-4rem m-1"
          >
            <!--             <h3>Einstellungen</h3>
 -->
          </div>
          <div v-else class="flex align-items-left w-20rem h-4rem m-1">
            <img
              src="@/assets/sanitybox2_sh.png"
              alt="Image"
              height="80"
              class="mb-3"
            />
          </div>

          <div
            v-if="store.firstLogin === false"
            class="flex align-items-center w-4rem h-4rem m-1"
          ></div>

          <div
            v-else
            class="bg-bluegray-900 text-gray-50 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap"
          >
            <div class="align-items-center hidden lg:flex">
              <span class="line-height-2">
                Erster Schritt: Bitte vervollständigen Sie Ihre Daten</span
              >
            </div>
            <a class="flex align-items-center ml-2 mr-8">
              <a class="text-white" href="#" @click="moreinfos = true"
                ><span class="underline font-bold">Mehr Infos</span></a
              >
            </a>
            <a
              v-ripple
              class="flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 p-ripple"
              style="width: 2rem; height: 2rem"
            >
              <i class="pi pi-times"></i>
            </a>
          </div>
          <Dialog
            v-model:visible="moreinfos"
            modal
            header="Erster Schritt: Bitte vervollständigen Sie Ihre Daten"
            :style="{ width: '100vw' }"
          >
            <p>Bitte kontrollieren Sie die Angaben sehr genau</p>
            <p>
              Wichtig: Der Firmenname kann nahträglich nicht mehr geändert
              werden.
              <br />
              Nachdem die Daten ausgefüllt sind, gelangen Sie auf die Plattform.
            </p>
          </Dialog>
          <Dialog
            v-model:visible="changePassword"
            modal
            header="Passwort ändern"
            :style="{ width: '100vw' }"
          >
            <ChangePasswordForm></ChangePasswordForm>
          </Dialog>

          <div class="flex align-items-right w-5.5rem h-4rem m-1">
            <Button
              class="shadow-7"
              label="Speichern"
              icon="pi pi-check"
              size="small"
              @click="saveForm()"
            />
          </div>
        </div>
      </template>
      <template #content>
        <form @submit.prevent="saveForm">
          <div class="p-card p-3" style="backgroundcolor: var(--indigo-50)">
            <div class="grid">
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title">Anschrift</h2>
                <div class="p-fluid">
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-building" />
                      <InputText
                        :disabled="!store.firstLogin"
                        id="cname"
                        v-model="formData.cname"
                        placeholder="Firmennname"
                        @blur="generateSlug"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-map-marker" />
                      <InputText
                        id="streetName"
                        v-model="formData.streetName"
                        placeholder="Straße"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-home" />
                      <InputText
                        id="houseNumber"
                        v-model="formData.houseNumber"
                        placeholder="Hausnummer"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-info-circle" />
                      <InputText
                        id="adressZusatz"
                        v-model="formData.adressZusatz"
                        placeholder="Adresszusatz"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-inbox" />
                      <InputText
                        id="postCode"
                        v-model="formData.postCode"
                        placeholder="Postleitzahl"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-globe" />
                      <InputText
                        id="city"
                        v-model="formData.city"
                        placeholder="Stadt"
                      />
                    </div>
                  </div>

                  <div class="pt-5">
                    <div class="formgroup-inline">
                      <div class="field-radiobutton">
                        <label for="strNr">Steuernummer</label>
                        <RadioButton
                          v-model="formData.steuerNrArt"
                          name="steuerArt"
                          inputId="strNr"
                          value="strNr"
                        />
                      </div>
                      <div class="field-radiobutton">
                        <label for="ustId"> Umsatzsteuer-Id</label>
                        <RadioButton
                          v-model="formData.steuerNrArt"
                          name="steuerArt"
                          inputId="ustId"
                          value="ustId"
                        />
                      </div>
                    </div>

                    <div class="p-input-icon-right">
                      <i class="pi pi-credit-card" />
                      <InputText
                        id="steuerUmsatz"
                        v-model="formData.steuerUmsatz"
                        placeholder="Steuernummer oder Umsatzsteuer-ID"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title">Institutionskennzeichen</h2>
                <div class="p-fluid">
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-circle-fill" />
                      <InputText
                        id="ikNumber"
                        v-model="formData.ikNumber"
                        placeholder="IK Nummer"
                      />
                    </div>
                  </div>
                </div>

                <h2 class="p-card-title pt-1">Bankverbindung</h2>
                <div class="p-fluid">
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        id="kontoInhaber"
                        v-model="formData.kontoInhaber"
                        placeholder="Kontoinhaber"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-credit-card" />
                      <InputText
                        id="iban"
                        v-model="formData.iban"
                        placeholder="IBAN"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-money-bill" />
                      <InputText
                        id="bic"
                        v-model="formData.bic"
                        placeholder="BIC"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-building" />

                      <InputText
                        id="bankName"
                        v-model="formData.bankName"
                        placeholder="Name der Bank"
                      />
                    </div>
                  </div>

                  <h2 class="p-card-title">Firmen Alias</h2>
                  <div class="p-fluid">
                    <div class="p-field">
                      <div class="p-input-icon-right">
                        <i class="pi pi-cloud" />
                        <InputText
                          :disabled="!store.firstLogin"
                          id="slug"
                          v-model="formData.slug"
                          placeholder="Aliasname"
                          @input="checkSlug()"
                        />
                      </div>
                      <span v-show="warnExistSlug" style="color: red"
                        >Bereits vergeben!</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title">Kontakt</h2>
                <div class="p-fluid">
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-link" />
                      <InputText
                        id="website"
                        v-model="formData.website"
                        placeholder="Webseite"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-mobile" />
                      <InputText
                        id="mobileNummer"
                        v-model="formData.mobileNummer"
                        placeholder="Mobile Nummer"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-phone" />
                      <InputText
                        id="phone"
                        v-model="formData.phone"
                        placeholder="Festnetz Nummer"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-info-circle" />
                      <InputText
                        id="rechtsForm"
                        v-model="formData.rechtsForm"
                        placeholder="Rechtsform"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        id="ownerName"
                        v-model="formData.ownerName"
                        placeholder="Inhaber/-in der Firma"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-briefcase" />
                      <InputText
                        id="registerGericht"
                        v-model="formData.registerGericht"
                        placeholder="Registergericht"
                      />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-id-card" />
                      <InputText
                        id="hrbNummer"
                        v-model="formData.hrbNummer"
                        placeholder="HRB Nummer"
                      />
                    </div>
                  </div>
                  <div v-if="store.firstLogin === false" class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <Button
                        label="Passwort ändern"
                        icon="pi pi-key"
                        id="changePassword"
                        size="small"
                        outlined
                        v-model="formData.changePassword"
                        @click="changePassword = true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { useToast } from "primevue/usetoast";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import ChangePasswordForm from "@/api/ChangePasswordForm.vue";
import { useRouter } from "vue-router";

const store = userStore();
const moreinfos = ref(false);
const changePassword = ref(false);
const warnExistSlug = ref(false);
const toast = useToast();
const router = useRouter();


async function checkSlug() {
  if (formData.slug.length > 3) {
    const response = await store.sendToEp("/company/slug/" + formData.slug);
    await nextTick();
    warnExistSlug.value = response.status == 294;
  }
}

// Validierungsschema mit yup
const validationSchema = yup.object({
  cname: yup.string().required("Firmenname ist erfoderlich"),
  streetName: yup.string().required("Straße ist erforderlich"),
  houseNumber: yup.string().required("Hausnummer ist erforderlich"),
  postCode: yup.string().required("Posteitzahl ist erforderlich"),
  city: yup.string().required("Stadt ist erforderlich"),
  phone: yup.string().required("Telefon ist erforderlich"),
  slug: yup.string().required("Aliasname ist erforderlich"),
});

// Formulardaten
let formData = reactive({
  cname: "",
  city: "",
  postCode: "",
  streetName: "",
  houseNumber: "",
  phone: "",
  adressZusatz: "",
  ownerName: "",

  ikNumber: "",
  steuerUmsatz: "",
  steuerNrArt: "",
  kontoInhaber: "",
  iban: "",
  bic: "",
  bankName: "",
  website: "",
  mobileNummer: "",

  rechtsForm: "",
  registerGericht: "",
  hrbNummer: "",
  slug: "",
});



const saveForm = async () => {
  try {
    await validationSchema.validate(formData, { abortEarly: false });
    store.sendToEp("/company/fullfill", formData, "patch");
   
    toast.add({
      severity: "success",
      summary: "Erfolg",
      detail: "Profildaten erfolgreich gespeichert",
      life: 2000,
    });
    setTimeout(() => {   store.firstLogin=false;router.push('/faststart'); }, 2000);

    
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Eingabefehler",
      detail: error.message,
      life: 3000,
    });
  }
};


function generateSlug() {
  let text = formData.cname.replace(/\s+/g, ''); // Alle Leerzeichen entfernen

  // Unicode-Normalisierung auf Form C anwenden
  text = text.normalize("NFC");

  // Umlaute und Buchstaben mit Akzenten ersetzen
  text = text
    .replace(/ü/g, "u")
    .replace(/ö/g, "o")
    .replace(/ä/g, "a")
    .replace(/_/g, "-")
    .replace(/ /g, "") // Alle Leerzeichen entfernen
    .replace(/[^\w\s]/gi, "") // Alle Sonderzeichen entfernen
    .toLowerCase(); // Text in Kleinbuchstaben umwandeln

  // Text in Kleinbuchstaben umwandeln
  text = text.toLowerCase();

  if (text.length > 3 && (!formData.slug || formData.slug == "")) {
    formData.slug = text;
    checkSlug();
  }
}

async function werteLaden() {
  const companyData = await store.sendToEp("/company/details");
  Object.assign(formData, companyData.data);
}

onMounted(() => {
  werteLaden();
});
</script>

<style scoped>
.p-card {
  text-align: left !important;
}
.p-card-title {
  font-size: large !important;
}
.form-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.form-column {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 10px;
}

button {
  margin-top: 20px;
}
</style>
