<template>
    <div class="card">
        <div class="flex-grow-1 flex justify-content-end">

            <Button class="shadow-7" label="Speichern" icon="pi pi-check" size="small" @click="saveForm()" />
        </div>
        <Toast />
        <TabView>
            <TabPanel header="AGB">
                <div class="card">
                    <Editor v-model="formData.agb" editorStyle="height: 320px" />
                </div>
            </TabPanel>
            <TabPanel header="Widerrufsbelehrung">
                <div class="card">
                    <Editor v-model="formData.widerruf" editorStyle="height: 320px" />
                </div>
            </TabPanel>
            <TabPanel header="Datenschutzerklaerung">
                <div class="card">
                    <Editor v-model="formData.datenschutz" editorStyle="height: 320px" />

                </div>
            </TabPanel>
            <TabPanel header="Versandbedingungen">
                <div class="card">
                    <Editor v-model="formData.versandBedingungen" textValue  editorStyle="height: 320px" />
                </div>
            </TabPanel>
            <TabPanel header="Impressum">
                <div class="card">
                    <InputSwitch name="showImpressum" textValue v-model="formData.showImpressum" />
                    <label for="showImpressum" class="ml-2">
                        Link Impressum anzeigen
                    </label>

                    <Editor v-model="formData.impressum" contentType="html" editorStyle="height: 320px" />
              </div>
            </TabPanel>

        </TabView>
    </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { userStore } from "@/store/userStore"
import { useToast } from "primevue/usetoast";

const value = ref('');
const store = userStore();
const toast = useToast();


const formData = reactive({
    agb: "",
    widerruf: "",
    datenschutz: "",
    versandBedingungen: "",
    impressum: "",
    showImpressum: true,
});

const saveForm = async () => {
    try {
        //await validationSchema.validate(formData, { abortEarly: false });
        store.sendToEp("/company/fullfill", formData, "patch");
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Rechtstexte erfolgreich gespeichert",
            life: 3000,
        });
    } catch (error) {
        toast.add({
            severity: "error",
            summary: "Eingabefehler",
            detail: error.message,
            life: 3000,
        });
    }
};

async function werteLaden() {
    const companyData = await store.sendToEp("/company/details");
    console.log("🚀 ~ file: LegalTextsSettingsForm.vue:91 ~ werteLaden ~ companyData:", companyData)
    Object.assign(formData, companyData.data);

}

onBeforeMount(() => {
    werteLaden();
});
</script>