<template>
    <div class="col-12 flex align-items-center">
        <div>
            <i
                :class="['pi', offerAnualItem ? 'pi-check text-green-500' : 'pi-times text-red-500', 'font-bold', 'text-xl']"></i>
        </div>
        <div class="text-xl font-semibold col">Jährliche Versendung von einem bestimmten Artikel zusätzlich anbieten</div>
    </div>

    <DataView :value="anualPosItems">
        <template #list="slotProps">
            <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
                <div class="col-12">
                    <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                        <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
                            :src="item.itemImageUrl ? item.itemImageUrl : require('@/assets/no-box.jpg')"
                            :alt="item.itemName" />
                        <div
                            class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                            <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                                <div class="text-xl font-bold text-900">{{ item.itemName }}</div>
                                <div class="flex align-items-center gap-3">
                                    <span class="flex align-items-center gap-2">
                                        <ul style="list-style-type: none;">
                                            <li>
                                                Alias: <span class="font-bold">{{ item.itemAlias }}</span>
                                            </li>
                                            <li>
                                                Positionsnummer: {{ item.pfHilfPosNum }}
                                            </li>
                                        </ul>
                                        <span class="font-semibold">{{ item.category }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                <span class="text-xl font-semibold">
                                    <span class="flex">Aktiv: </span>
                                    <InputSwitch v-model="item.anualItemActive" @change="changeActiveItem(item)">
                                    </InputSwitch>
                                </span>
                                <div class="flex mt-3">
                                    <Button icon="pi pi-minus-circle" style="color: red" text
                                        @click="decreaseQuantity(item)" :disabled="!item.anualItemActive" />
                                    <InputMask :disabled="!item.anualItemActive" v-model="item.anualQuantity"
                                        @update:model-value="item.anualQuantity = $event" mask="?999"
                                        class="text-right" style="max-width: 50px" />
                                    <Button icon="pi pi-plus-circle" text @click="increaseQuantity(item)"
                                        :disabled="!item.anualItemActive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DataView>

    <!--     <div class="pt-3 flex justify-content-end">
        <Button :disabled="!offerAnualItem" label="Übernehmen & Speichern" @click="save"></Button>
    </div> -->
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { userStore } from "@/store/userStore";
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const store = userStore();
const offerAnualItem = ref(false);
const anualPosItems = ref([]);

const increaseQuantity = (data) => {
    data.anualQuantity++;
    changeActiveItem(data);
}
const decreaseQuantity = (data) => {
    (data.anualQuantity > 1) ? data.anualQuantity-- : data.anualQuantity = 1;
    changeActiveItem(data);
}

const updateAnualPosItems = (selectedItem) => {
    anualPosItems.value = anualPosItems.value.map(item => ({
        ...item,
        anualItemActive: item === selectedItem
    }));
};

const changeActiveItem = (data) => {
    const itemId = data.item_id;
    //const { anualQuantity = 1 } = data;
    const anualItemActive = data.anualItemActive;

    if (anualItemActive) {
        updateAnualPosItems(data);
        const res = store.sendToEp("/products/items-anual/" + itemId, { anualQuantity: data?.anualQuantity ?? 1 }, "post");
        toast.removeAllGroups;
        toast.add({ severity: 'success', summary: 'Speichern', detail: 'erfolgreich', life: 1500 });
    }
}


onMounted(async () => {
    try {
        const response = await store.sendToEp("/products/items-anual");
        anualPosItems.value = response.data; // Speichern Sie das Ergebnis der Promise, damit nicht Promise sondern der Array gespeichert wird
        offerAnualItem.value = anualPosItems.value.length > 0 && anualPosItems.value.some(item => item.isAnual);
    } catch (error) {
        console.error(error);
    }
});
</script>