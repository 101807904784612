<template>
    <ProgressBar style="height: 50px;" :value="totalValueProgress" :pt="{
        value: {
            style: {
                background: dynamicBackground,
          'justify-content': labelPosition,
            }
        },
        label: {
            style: {
                'text-align': 'right',
            }
        },  
    }"><span :style="(props.wert<=1?'font-size:12px;color:#ff1717;':props.wert<=12?'color:#ff1717':'')" >{{ totalValueProgress }}% ({{ formattedValue }})</span></ProgressBar>
</template>
  
<script setup>
import { ref, computed, watchEffect } from 'vue';

const props = defineProps({
    wert: {
        required: true,
        default: 0,
    },
    maxValue: {
        required: false,
        default: 150,
    },
    optimalValue: {
        required: false,
        default: 100,
    },
});
const totalValueProgress = ref(0);

watchEffect(() => {
    totalValueProgress.value = Math.round((props.wert / props.optimalValue) * 100);

});

const formattedValue = computed(() => {
    const value = props.wert; // Beispielwert
    return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
});

const labelPosition = computed(()=>{
    return props.wert > 200 ? 'left!important' : 'center!important';
});

const dynamicBackground = computed(() => {
    if (totalValueProgress.value <= 30) {
        return 'linear-gradient(to right, #fffeee, #feff64)';
    }else if (totalValueProgress.value > 30 && totalValueProgress.value <= 70) {
        return 'linear-gradient(to right, #faedc4, #1da750)';
    }else if (totalValueProgress.value > 70 && totalValueProgress.value < 90) {
        return 'linear-gradient(to right, #faedc4, #1da750, #1da750)';
    } else if (totalValueProgress.value >= 90 && totalValueProgress.value <= 95) {
        return 'linear-gradient(to right, #faedc4, #1da750, #1da750)';
    } else if (totalValueProgress.value > 95 && totalValueProgress.value <= 100) {
        return '#1da750';
    } else if (totalValueProgress.value > 100 && totalValueProgress.value <= 130) {
        return 'linear-gradient(to right, #22c55e,#22c55e, #ff3d32,#ff3d32, #ff3d32)';
    } else if (totalValueProgress.value > 130 && totalValueProgress.value > 200) {
        return '#ff3d32';
    }
    else {
        return 'linear-gradient(to right, #22c55e, #ff3d32, #ff3d32)';
    }
});
</script>
<style>
.center-label {
  justify-content: center !important;
}

.left-label {
  justify-content: flex-start !important;
}
</style>
  