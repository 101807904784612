<template>
  <div class="card p-fluid">
    <Loading v-show="loading" />

    <DynamicDialog />

    <Toast />
    <Tip v-if="lazyLoading == false" :target="'#editPatient'" :tipId="'editPatientTip'" :placement="'right'"
      :overlay="true" :timeout="1000"/>
    <PatientenEditDialog v-model:visible="dialogVisible" :dataForDialog="dataForDialog" :initialize="initialize"
      @delete-Patient="handlePatientDelete" @closeDialog="closeDialog"/>
    <DataTable v-model:filters="filters" :value="virtualPatients" editMode="row" scrollable scrollHeight="85vh"
      filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
      selectionMode="single" dataKey="patient_pub_id" sortField="patient_pub_id" :sortOrder="-1" class="p-datatable-sm"
      @row-dblclick="handleRowDoubleClick" :virtualScrollerOptions="{
        lazy: true,
        onLazyLoad: loadPatientsLazy,
        itemSize: 46,
        delay: 300,
        showLoader: true,
        loading: lazyLoading,
        numToleratedItems: 100
      }">
      <Column headerStyle="width: 2rem; text-align: center" footer="" bodyStyle="text-align: center; overflow: visible">
        <template #header>
          <Button type="button" outlined text size="small" icon="pi pi-user-plus" rounded @click="openDialog" />
        </template>
        <template #body="{ data }">
          <Button type="button" id="editPatient" outlined text size="small" icon="pi pi-user-edit" aria-haspopup="true"
            aria-controls="overlay_menu" rounded @click="popUptoggle($event, data)" />
          <Menu ref="menu" id="overlay_menu" :model="popUpitems" :popup="true" />
        </template>
      </Column>

      <Column field="patient_pub_id" header="Patienten -Id" footer="Patienten -Id" key="patient_pub_id"
        :showFilterMenu="false" :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          <Badge :value="data.patient_pub_id" size="large" severity="success"></Badge>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]"  />
        </template>
      </Column>

      <Column v-if="!isMobile" class="" v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" style="width: 25%" sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]"  />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>

      <Column field="statusAntrag" header="Status" footer="Status" :showFilterMenu="false"
        :filterMenuStyle="{ width: '8%' }" style="min-width: 8%" sortable>
        <template #body="{ data }">
          <Tag :value="t(data.statusAntrag)" :class="t(data.statusAntrag)" />
<!--           <Tag :value="t(data.statusAntrag)" :severity="getSeverity(t(data.statusAntrag))" />
 -->        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" optionLabel="label" optionValue="value" placeholder="Auswählen"
            class="p-column-filter" style="min-width: 10%" :showClear="true">
            <template #option="slotProps">
              <Tag :value="t(slotProps.option.label)" :class="slotProps.option.label" />
            </template>
          </Dropdown>
        </template>
      </Column>

    </DataTable>

  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, computed, defineAsyncComponent, markRaw } from "vue";
import PatientenEditDialog from "./PatientenEditDialog.vue";
import { useRouter } from "vue-router";
import Tip from "@/components/Tips/Tip.vue";
import { FilterMatchMode } from "primevue/api";
import { PatientenService } from "@/service/PatientenService";
import Loading from "@/components/Loading";
import { useDialog } from "primevue/usedialog";
import { speichern } from "@/utils/invoiceUtils";
import { useI18n } from "vue-i18n";

const InvoicesEditComponent = defineAsyncComponent(() => import('@/views/InvoicesEditComponent.vue'));
const DefaultFooter = defineAsyncComponent(() => import('@/views/InvoicesEditFooter.vue'));
const dialog = useDialog();
const { t } = useI18n();

const router = useRouter();
const initialize = ref(false); //wegen neuer Kunde usw.
const isMobile = ref(false);
const dialogVisible = ref(false);
const loading = ref(true);
const lazyLoading = ref(false);
const loadLazyTimeout = ref();

const patients = ref([]);
const virtualPatients = ref([]);

let rowData = null;

const popUpLabel = reactive({ label: "" });

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = rowData.firstName + ' ' + rowData.surName;
  menu.value.toggle(event);
};
const selectedUser = ref();
const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: 'Ansehen/Bearbeiten',
        icon: 'pi pi-file-edit',
        command: () => {
          if (rowData) {
            openDialog(rowData);
          }
        },
      },
      {
        label: 'Rechnung erstellen',
        icon: 'pi pi-book',
        command: () => {
          const dialogRef = dialog.open(InvoicesEditComponent, {
            props: {
              header: 'Rechnung Erstellen',
              style: {
                width: '90vw',
              },
              breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
              },
              modal: true,
            },
            templates: {
              footer: markRaw(DefaultFooter)
            },
            onClose: async (options) => {
              if (options.data) {
                const buttonType = options.data.buttonType;
                if (buttonType == "Save") {
                  let response = speichern(dialogRef);
                  console.log(response.data);
                }
              }
            }
          });
          dialogRef.data = {
            invoice: {
              userObj: rowData,
              invoiceId: null,
              isDisabled: false,
              storno: false
            }
          };
        },
      },
    ],
  },
]);

const columns = ref([
  /*   { field: "patient_pub_id", header: "Patienten ID" },
   */ { field: "firstName", header: "Vorname" },
  { field: "surName", header: "Nachname" },
  { field: "postCode", header: "PLZ" },
  { field: "birthDate", header: "Geburtsdatum" },
  { field: "registered", header: "Registriert" },
]);

const filters = ref({
  patient_pub_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
  firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  surName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  birthDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
  registered: { value: null, matchMode: FilterMatchMode.CONTAINS },
  statusAntrag: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

function isSortable(field) {
  return field === 'birthDate' || field === 'registered';
}

const dataForDialog = ref();

const closeDialog = () => {
  dialogVisible.value = false;
};

const loadPatientsLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let virtuellePatienten = [...patients.value];
    let { first, last } = event;

    //load data of required page
    const loadedPatients = patients.value.slice(first, last);

    //populate page of virtual cars
    Array.prototype.splice.apply(virtuellePatienten, [...[first, last - first], ...loadedPatients]);

    virtualPatients.value = virtuellePatienten;
    lazyLoading.value = false;
  }, Math.random() * 1000 + 800);
};

const handleRowDoubleClick = (event) => {
  const data = event.data;
  openDialog(data);
};

const openDialog = (data) => {
  if (data) {
    dataForDialog.value = data;
    dialogVisible.value = true;
    initialize.value = true;
  } else { //neuer Patient
    dataForDialog.value = null;
    initialize.value = false;
    dialogVisible.value = true;
  }
};

const handlePatientDelete = () => {
  console.log("handlePatientDelete");
}

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const statuses = reactive([
  {label:t("REGISTERED"), value:"REGISTERED"},
  {label:t("QUALIFIED"), value:"QUALIFIED",},
  {label:t("PAUSED"), value:"PAUSED"},
  {label:t("CANCELED"), value:"CANCELED"},
  {label:t("REJECTED"), value:"REJECTED"},
  {label:t("TERMINATED"), value:"TERMINATED"},
  {label:t("DELETED"), value:"DELETED"},
]);

/* async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
} */

async function werteLaden() {
  try {
    loading.value = true;
    await PatientenService.getPatientensXLarge().then((data) => {
      patients.value = data.map((patient) => ({
        ...patient,
        registered: formatDate(patient.registered),
        birthDate: formatDate(patient.birthDate),
      }));  //optimierbar würde um 1-1,5 sekunden schneller laden wenn vom backend kommt
      if (patients.value.length == 0) {
        router.push('/nopatients');
      }      //numberOfPatients=patients.length;
    });
    loading.value = false;
  } catch (Exception) {
    loading.value = false;
    if (!patients.value.length > 0) {
      router.push('/nopatients');
    }
  }
}


const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};


onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

onBeforeMount(async () => {
  //  await zeitMessen(werteLaden);
  await werteLaden();
});
</script>

<style scoped>
.Registriert{
  background:#f2d066!important;
}
.Pausiert{
  background:#35c4dc!important;
}
.Abgebrochen{
  background:#8183f4!important;
}
.Abgelehnt{
  background:#ff6259!important;
}
.Beendet{
  background:#dab6fc!important;
}
.Gelöscht{
  background:#64748b!important;
}
.Akzeptiert{
  background:#22c55e!important;
}
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
