<template>
  <div>
    <DynamicDialog />
    <Toast group="items" />
    <div class="card flex justify-content-center">
      <!--       <Card style="width: 100%; margin: 10px;">
        <template #title>Pflegeartikel [Auswahlbereich]</template>
        <template #content> 
          <Tip :tipId="'KlickWeiterOrAbortTip'" :target="'#weiterButton'" :placement="'left'" :overlay="false" />
          <Button v-if="!props.hideSelectButton" class="mr-2" id="weiterButton" icon="pi pi-forward" label="Weiter" :outlined="!selected"
            :disabled="!selected" @click="handleSelectBox"></Button>
            -->
      <slot></slot>
      <div v-if="loading">Loading...</div>
      <div v-else>
        <Tip v-if="showSettingsInfo" tipId="itemSettingsTip" target="#itemSettings" :overlay="true"
          :visible="showSettingsInfo" />

        <PickList v-model="pickListe" @move-all-to-target="onMoveAllToTarget" @move-all-to-source="onMoveAllToSource"
          @move-to-target="onMoveToTarget" @move-to-source="onMoveToSource" stripedRows
          :listProps="{ class: 'box-normal' }" :showSourceControls="false" :showTargetControls="false"
          :list-style="listStyle" :pt="{
            sourceList: { style: { width: '32vw' } },
            targetList: { style: { width: '32vw' } },
            sourceHeader: { style: { padding: '0.33rem' } },
            moveAllToSourceButton: { style: { display: 'none' } }, moveAllToTargetButton: { style: { display: 'none' } }
          }">
          <template #sourceheader>

            <div class="flex">
              <div class="flex-none flex align-items-center justify-content-center px-5 ">
                Katalog</div>
              <div class="flex-grow-1 flex align-items-center justify-content-center">
              </div>
              <div class="flex-none flex align-items-center justify-content-center px-5 ">
                <Button text size="small" rounded icon="pi pi-list" @click="toggleSourcePopupMenu" aria-haspopup="true"
                  aria-controls="overlay_menu" />
                <Menu ref="sourcePopupMenu" id="overlay_menu" :model="items" :popup="true">
                  <template #start>
                    <button @click="showAllItemsWithoutFilter"
                      class="w-full p-link flex align-items-center  ml-1 p-2 text-color hover:surface-200 border-noround">
                      <i class="pi pi-eye" />
                      <span class="w-full ml-1">Alle Artikel laden</span>
                    </button>
                  </template>
                  <template #end>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText class="w-full" size="small" placeholder="Suchen" v-model="filterSourceText" />
                    </span>
                  </template>
                </Menu>
              </div>
            </div>
          </template>
          <template #targetheader> Ausgewählt </template>
          <template #item="data">
            <div class="flex flex-wrap p-0 justify-content-left gap-4 box">
              <span v-if="!isSource(data.item)" class="font-bold text-900">
                <Button text size="small" :label="`${data.item.quantity}x`" id="itemSettings" icon="pi pi-cog"
                  @click="showItemSelectDialog(data.item)" v-tooltip.top="'Klick für Anzahl ggfl. Variante ändern'" />
              </span>
              <img class="w-3rem h-3rem shadow-1 flex-shrink-2 border-round p-0" @error="handleImageError(data, $event)"
                :src="data.item.itemImageUrl ? data.item.itemImageUrl : require('@/assets/no-image.jpg')"
                :alt="data.item.itemName" />
              <div class="flex-1 flex flex-column gap-1">
                <span class="">{{ data.item.itemName }}</span>

                <div class="flex align-items-left gap-2">
                  <i class="pi pi-tag text-sm"></i>
                  <span v-if="data.item.hasGroup == true">
                    <Tag severity="info">EK: {{
                      formatPrice(data.item.itemGroupList[0].selectedVariant.variantInPrice) }}</Tag>
                    <Tag severity="success">VK: {{
                      formatPrice(data.item.itemGroupList[0].selectedVariant.variantOutPrice) }}</Tag>&nbsp;
                    <span v-if="isTarget(data.item)">
                      <Tag severity="warning">{{ data.item.itemGroupList[0].groupName }}: {{
                        data.item.itemGroupList[0].selectedVariant.variantValue }}</Tag>
                    </span>
                    <span v-else>
                      <Tag severity="warning">{{ data.item.itemGroupList[0].groupName }}: {{ getVariants(data.item) }}
                      </Tag>
                    </span>
                  </span>
                  <span v-else>
                    <Tag severity="info">EK: {{ formatPrice(data.item.itemInPrice) }}</Tag>
                    <Tag severity="success">VK: {{ formatPrice(data.item.itemOutPrice) }}</Tag>
                  </span>
                </div>
              </div>
              <!--                   <span class="font-bold text-900">{{ euroFormatter(data.item.itemOutPrice) }}</span>
 -->
            </div>
          </template>
        </PickList>
      </div>
      <!--         </template>
      </Card> -->
    </div>

  </div>
</template>
<script setup>
import { ref, watch, onMounted, defineAsyncComponent, inject, markRaw } from 'vue';
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import Tip from "@/components/Tips/Tip.vue";

import { useDialog } from 'primevue/usedialog';
const ItemSelectDialog = defineAsyncComponent(() =>
  import('@/components/ItemSelectDialog.vue')
);
const ItemSelectDialogFooter = defineAsyncComponent(() =>
  import('@/components/ItemSelectDialogFooter.vue')
);

const dialogRef = inject("dialogRef");
const store = userStore();
const toast = useToast();
const noMoreTip = ref(false);
const pickListe = ref(null);
const items = ref([]);
const loading = ref(true);
const selected = ref(false);
dialogRef.selected = selected;

dialogRef.pickListe = pickListe;
const disablePickListDblClick = ref(false);
const showSettingsInfo = ref(false);
const props = defineProps({
  hideSelectButton: false,
});
const dialog = useDialog();
const formatPrice = (value) => {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}


const showItemSelectDialog = (item) => {
  const SelectDialogRef = dialog.open(ItemSelectDialog, {
    props: {
      header: item.itemName,
      style: {
        width: '35vw',
      },
      breakpoints: {
        '960px': '55vw',
        '640px': '75vw',
      },
      modal: true,
    },
    templates: {
      footer: markRaw(ItemSelectDialogFooter),
    },
    onClose: (options) => {
      const data = options.data;
      if (data) {
        const summary_and_detail = {
          summary: `Auswahl für ${item.itemName}`,
          detail: `Anzahl: ${item.quantity}`,
        }
        toast.add({ severity: 'info', ...summary_and_detail, life: 2000, group: 'items', position: 'buttom-right' });
      }
      showSettingsInfo.value = true;

    },
  });
  SelectDialogRef.data = item;
};
const handleImageError = (data) => {
  data.item.itemImageUrl = require('@/assets/no-image.jpg');
};
const listStyle = {
  'height': '342px',
  'display': 'block',
  'flex-direction': 'column',
  'align-items': 'left',
  /*   'background-image': 'repeating-linear-gradient(-60deg, #71a2d6, #71a2d6 30px, transparent 30px, transparent 51px, #71a2d6 51px), repeating-linear-gradient(30deg, #71a2d6, #71a2d6 30px, transparent 30px, transparent 51px, #71a2d6 51px), repeating-linear-gradient(120deg, #71a2d6, #71a2d6 30px, transparent 30px, transparent 51px, #71a2d6 51px), repeating-linear-gradient(210deg, #71a2d6, #71a2d6 30px, transparent 30px, transparent 51px, #71a2d6 51px)',
    'background-size': '2px 100%, 100% 2px, 2px 100% , 100% 2px',
    'background-position': '0 0, 0 0, 100% 0, 0 100%',
    'background-repeat': 'no-repeat', */
};

// Emit-Events abfangen und die Standardaktionen verhindern
const onMoveToTarget = (event) => {
  const item = event.items[0];
  showItemSelectDialog(item);
  selected.value = true;
};

const onMoveAllToTarget = (event) => {
  selected.value = true;
};

const onMoveAllToSource = (event) => {
  selected.value = false;
};


const onMoveToSource = () => {
  selected.value = pickListe.value[1].length > 0;
  if (selected.value == true) {
  }
};

const handleSelectBox = ref(() => {
  if (!selected) {
    toast.add({ severity: "warn", summary: "Warnung", detail: "Bitte wähle zuerst Artikel aus." });
  } else {
    //  product_id an die Elternkomponente
    emit('items-selected', pickListe.value[1]);
  }
});

const isSource = (item) => {
  // Überprüfe, ob das Element im Source-Bereich ist
  return pickListe.value[0].includes(item);
};

const isTarget = (item) => {
  // Überprüfe, ob das Element im Target-Bereich ist
  return pickListe.value[1].includes(item);
};

const sourcePopupMenu = ref();
const toggleSourcePopupMenu = (event) => {
  sourcePopupMenu.value.toggle(event);
};

const SourceMenuItems = ref([
  { separator: true },
  {
    label: 'Ausgewählte Artikel ausblenden',
    icon: 'pi pi-eye-slash'
  },
  {
    label: 'Alle Artikel einblenden',
    icon: 'pi pi-eye'
  },
  { separator: true },
  {
    label: 'Suchen',
    icon: 'pi pi-search',
    badge: 2
  }
]);

const getVariants = (items) => {
  if (items.hasGroup) {
    const varianten = items.itemGroupList[0];
    const variantenList = varianten.groupVariantList.map(item => `${item.variantValue}`).join(', ');
    return variantenList;
  }
  return "keine Variante";
}

const euroFormatter = (value) => {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}
/* const getTooltipContent = (data) => {
  const items = data;
  return `
  <div class="flex">
    <div class="flex-1 h-4rem bg-green-400 p-1 font-bold"><div style="min-width:'20px'; text-align: left">EK-Preis:</div><div class="text-right">${euroFormatter(data.itemInPrice)}</div></div>
    <div class="flex-1 h-4rem bg-primary p-1  font-bold"><div style="min-width:'20px'; text-align: left">VK-Preis:</div><div class="text-right">${euroFormatter(data.itemOutPrice)}</div></div></div>`;
}; */
const allItems = ref([]);
const showAllItemsWithoutFilter = () => {
  console.log("showAllItems");
  pickListe.value[0] = allItems.value;
};

const filterSourceText = ref();

watch(filterSourceText, () => {
  filterSource();
});

const filterSource = () => {
  console.log("filterSource");
  const source = dialogRef.value.data.currentItems
    ? allItems.value.filter(item =>
      item.itemName.toLowerCase().includes(filterSourceText.value.toLowerCase())
    )
    : [];
  pickListe.value[0] = source;
};


onMounted(async () => {
  try {
    const response = await store.sendToEp("/products/items-nonanual");
    allItems.value = response.data;
    response.data.forEach(item => {
      item.quantity = 1;
      item.hasGroup ?
        item.itemGroupList[0].selectedVariant = item.itemGroupList[0].groupVariantList[0] : null;
    });

    const source = dialogRef.value.data.currentItems
      ? response.data.filter(item =>
        !dialogRef.value.data.currentItems.some(currentItem => currentItem.item_id === item.item_id)
      )
      : [];


    pickListe.value = [source, dialogRef.value.data.currentItems]; //pickListe.value[0]=source, pickListe.value[1]=target
    dialogRef.value.data.pickListe = pickListe;
  } catch (error) {
    console.error(error);
    toast.add({ severity: "error", summary: "Fehler", detail: "Fehler beim Laden der Daten" });
  } finally {
    loading.value = false; // Daten sind geladen, Ladezustand beenden
  }
});

</script>
<style scoped>
:deep(.columns) {
  display: flex;
  flex-wrap: wrap;
}

:deep(.column) {
  flex-basis: 50%;
  box-sizing: border-box;
}

:deep(.item) {
  margin-bottom: 10px;
}

.custom-tooltip {
  width: 400px !important;
}

:deep(.p-orderlist-item:nth-child(even)) {
  background-color: #f1ecec !important;
  color: #fff;
}
</style>