import { createRouter, createWebHistory } from "vue-router";
import { userStore } from "@/store/userStore";
import TheLogin from "@/components/auth/TheLogin.vue";
import CompanySettingsForm from "@/views/CompanySettingsForm.vue";
import Dashboard from "@/views/Dashboard.vue";
import PatientenView from "@/views/PatientenView.vue";
import MailConfirm from "@/api/mailconfirm.vue";
import ResetPasswordForm from '@/api/ResetPasswordForm.vue';
import ItemsEditView from '@/views/ItemsEditView.vue';
import BoxesEditView from '@/views/BoxesEditView.vue';
import InvoicesView from '@/views/InvoicesView.vue';
import CatalogView from "@/views/CatalogView.vue";
import LegalTextsSettingsForm from '@/views/LegalTextsSettingsForm.vue';
import QRScanner from '@/views/QRScanner.vue';
import NoPatients from '@/views/NoPatients.vue';
import Settings from '@/views/Settings.vue';
import Tip from "@/components/Tips/Tip.vue";
import Handbook from "@/components/handbook/Handbook.vue";
import FastStart from "@/components/FastStart.vue";
import EtikettenGenerator from "@/components/EtikettenGenerator.vue";
import ShippingPrepareView from '@/views/ShippingPrepareView.vue';
const routes = [
  {
    path: "/",
    name: "login",
    component: TheLogin,
  },
  {
    path: '/mail-confirm/:token', // Der Token-Parameter wird als dynamischer Teil der URL definiert
    name: 'MailConfirm',
    component: MailConfirm
  },
  {
    path: "/faststart",
    name: "faststart",
    component: FastStart,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/boxesEditView",
    name: "boxesEditView",
    component: BoxesEditView,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoicesView",
    name: "invoicesView",
    component: InvoicesView,
    meta: { requiresAuth: true },
  },  
  {
    path: "/invoicesView",
    name: "invoicesView",
    component: InvoicesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/itemsEditView",
    name: "itemsEditView",
    component: ItemsEditView,
    meta: { requiresAuth: true },
  },
  {
    path: "/companySettingsForm",
    name: "companySettingsForm",
    component: CompanySettingsForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/legalTextsSettingsForm",
    name: "legalTextsSettingsForm",
    component: LegalTextsSettingsForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/qrscanner",
    name: "qrscanner",
    component: QRScanner,
    meta: { requiresAuth: true },
  },
  {
    path: "/nopatients",
    name: "NoPatients",
    component: NoPatients,
    meta: { requiresAuth: true },
  },
  {
    path: "/patientenView",
    name: "patientenView",
    component: PatientenView,
    meta: { requiresAuth: true },
  },
  {
    path: "/shippingPrepareView",
    name: "shippingPrepareView",
    component: ShippingPrepareView,
    meta: { requiresAuth: true },
  },
  {
    path: "/catalogView",
    name: "CatalogView",
    component: CatalogView,
    meta: { requiresAuth: true },
  },
  {
    path: "/etikettenGenerator",
    name: "EtikettenGenerator",
    component: EtikettenGenerator,
    meta: { requiresAuth: true },
  },
  {
    path: "/handbook",
    name: "Handbook",
    component: Handbook,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: '/reset-password/:token', // Definiere einen Routenparameter ":token"
    name: 'reset-password',
    component: ResetPasswordForm,
  },
  {
    path: '/tip',
    name: 'tip',
    component: Tip,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store=userStore(); 
  if (to.meta.requiresAuth && !store.isAuthenticated) {
    next("/"); // Weiterleitung zur Login-Seite, wenn Authentifizierung erforderlich ist
  } else {
    next(); // Weiterleitung zu der angeforderten Seite
  }
});


export default router;
