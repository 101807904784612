<template>
    <div>
        <div v-if="isScannerEnabled">
            <qr-stream   @init="onInit" @decode="handleQRCode" style="width: 300px; height: 300px;">
                <div :style="frameStyle" class="frame"></div>
                <b>{{ scannedResult }}</b>

            </qr-stream>
        </div>
        <div v-else>
            <div class="frame noScanner" style="position=relative">
                <img src="@/assets/qrcode_www.sanitybox.de_link.png" alt="QR Code" width="195" height="195">

            </div>
        </div>
        <div>
            <ToggleButton v-model="scanner" offIcon="pi pi-check" onIcon="pi pi-times" onLabel="Scanner deaktivieren"
                offLabel="Scanner aktivieren" @change="isScannerEnabled = !isScannerEnabled">Scanner</ToggleButton>
        </div>
        <br>
        <br>
        <br>
        <span>LSM-</span>
        <InputText v-model="scannedQRCode" name="eingabe" @keydown.enter="sendDataOnEnter"
            v-tooltip="'Enter zum bestätigen'" :disabled="isScannerEnabled" :maxlength="6" @input="validateInput">
        </InputText>
        <Button @click="sendData">Absenden</Button>
        <Toast />
        <DataTable :value="deliList" :readonly="true" :paginator="true" stripedRows :rows="10">
            <Column field="eventTime" header="Zeit"></Column>
            <Column field="delidocId" header="ID Eingabe"></Column>
        </DataTable>

        <!--       <div>
        <h2>Gescannter QR-Code:</h2>
        <p>{{ scannedQRCode }}</p>
      </div> -->
    </div>
</template>
  
<script setup>
import { ref, reactive, onMounted } from 'vue';
import { QrStream } from 'vue3-qr-reader';
import { userStore } from '@/store/userStore';
import { useToast } from "primevue/usetoast";

const toast = useToast();
const store = userStore();
const scanner = ref(false);
const isScannerEnabled = ref(false);
const scannedQRCode = ref('');
const scannedResult = ref('');
let previousQRCode = '';
const scannedList = ref([]);

const deliList = ref([
    { eventTime: "", delidocId: "" }
]);


const onInit = async (promise) => {
    // show loading indicator

    try {
        const { capabilities } = await promise;

        // successfully initialized
    } catch (error) {
        if (error.name === 'NotAllowedError') {
            // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
            // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
            // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
            // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
            // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
            // browser seems to be lacking features
        }
    } finally {
        // hide loading indicator
    }
};

//qrCode an EP prüfen
const sendData = async () => {
    try {
        const response = await store.sendToEp("/company/deli-confirm/" + scannedQRCode.value)
        if (response.status === 200) {
            console.log("🚀 ~ file: QRScanner.vue:92 ~ sendData ~ response:", response)
         //   deliList.value.push({ delidocId: scannedQRCode.value, eventTime: formatDate(new Date()) });
            scannedQRCode.value = '';
            showToast("success");
            loadDeliList();
        } else {
            showToast("error");
        }
    } catch (error) {
        console.log("🚀 ~ file: QRScanner.vue:101 ~ sendData ~ error:", error)
        showToast("error");
    }
};


//Deliver liste vom Backend abrufen
async function loadDeliList() {
    try {
        const response = await store.sendToEp("/company/delidoc-sentonly");
        if (response.status === 200) {
            const data = await response.data.invoices;
            const dataArray = Object.values(data);

            deliList.value = dataArray.map((invoices) => ({
                ...invoices,
                eventTime: formatDate(invoices.eventTime),
            }));
        }
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    loadDeliList();
});

// nur nummern erlauben
const validateInput = () => {
    scannedQRCode.value = scannedQRCode.value.replace(/[^0-9]/g, '');
};
// per Enter Daten von Eingabe senden
const sendDataOnEnter = () => {
    if (scannedQRCode.value.trim() !== '') {
        sendData();
    }
};

// Toast Meldungen anzeigen
const showToast = (status) => {
    toast.add({
        severity: status,
        summary: status == 'error' ? 'Fehlerhaft' : 'Erfolgreich',
        life: 1000,
    });
};

// Datum und Zeit nach folgedem Muster formatieren dd.mm.yyyy hh:mm:ss
const formatDate = (date) => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    return new Date(date).toLocaleString('de-DE', options);
};


const frameStyle = ref({
  borderColor: 'red',
});
const beepSound = new Audio(require('@/assets/beep.mp3'));

const handleQRCode = (result) => {
    beepSound.play();
    scannedResult.value = result;
    if (result !== previousQRCode) {
        scannedQRCode.value = result;
        validateInput();
        previousQRCode = result;
        sendData();

        frameStyle.value.borderColor = 'green';
    setTimeout(() => {
      frameStyle.value.borderColor = 'red';
    }, 1500);
    };
};


</script>
  
<style scoped>
.stream {
    max-height: 500px;
    max-width: 500px;
    margin: auto;
}

.frame {
  border-style: solid;
  border-width: 2px;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transition: border-color 1s;
}

.noScanner {
    position: relative !important;
}

</style>
  