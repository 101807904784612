import iAxios from "@/store/axiosInterface";
import axios from "axios";

/* export const getPatientens = (data) => {
  return Object.values(data).map((d) => {
    d.birthDate = new Date(d.birthDate);
    return d;
  });
}; */
export const getInvoices = (data) => {
  return Object.values(data).map((d) => {
    d.birthDate = new Date(d.birthDate);
    return d;
  });
};

export const setInvoiceStatus = async (invoiceId, status) => {
  let url = process.env.VUE_APP_BASE_API_URL + "/company/invoice/" + invoiceId;
  let response = await iAxios.post(url, { invoiceStatus: status });

  console.log(response.data);

  return response;
}

export const InvoicesService = {
  async getData() {
    try {
      let url = process.env.VUE_APP_BASE_API_URL + "/company/invoice-list";
      let response = await iAxios.get(url, {});

      return response.data;
    } catch (exception) {
      console.log(exception);
    }
    return null;
  },

  getInvoicesSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getInvoicesMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async getInvoicesXLarge() {
    return Promise.resolve(this.getData());
  },

};
